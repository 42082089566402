/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Authenticated,
  Breadcrumb,
  LayoutWrapper,
  useNavigation,
} from "@pankod/refine";
import { ControlHeader } from "components/layout/header/control_header";
import { CompaniesComponent } from "control/components/companies";

const Companies: React.FC = () => {
  const { push } = useNavigation();

  return (
    <div style={{ marginTop: 10, marginRight: 10, marginLeft: 10 }}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a onClick={() => push("/control/analytics")}>Home</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a>Companies</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <CompaniesComponent height={77} />
    </div>
  );
};

export const CompaniesPage = () => {
  return (
    <Authenticated>
      <LayoutWrapper
        Sider={() => <></>}
        Header={() => (
          <>
            <ControlHeader />
          </>
        )}
      >
        <Companies />
      </LayoutWrapper>
    </Authenticated>
  );
};
