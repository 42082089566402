/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  SearchOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  MoreOutlined,
  FolderViewOutlined,
} from "@ant-design/icons";
import {
  Button,
  Drawer,
  Dropdown,
  Form,
  Grid,
  Input,
  Menu,
  Popconfirm,
  Table,
  Tag,
  useNavigation,
} from "@pankod/refine";
import { Col, message, notification, Row, Tooltip } from "antd";
import { gqlDataProvider } from "api";
import { QuotationData } from "interfaces";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import type { NotificationPlacement } from "antd/lib/notification";
import React from "react";
import { QuotationInformationComponent } from "./quotation_informations_component";

const Context = React.createContext({ name: "Default" });

interface quotationSearchFormData {
  key: string;
}

interface quotationProps {
  height?: any;
  randKey?: any;
}

export const QuotationsComponent: React.FC<quotationProps> = (
  props: quotationProps
) => {
  const [api, contextHolder] = notification.useNotification();
  const [quotations, setQuotations] = useState<QuotationData[]>([]);
  const [quotation, setQuotation] = useState<QuotationData>();
  const [quotationInfoModal, setQuotationInfoModal] = useState(false);
  const [limit, setLimit] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [total, setTotal] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [loading, setLoading] = useState(true);
  const { push } = useNavigation();
  const [searchForm] = Form.useForm<quotationSearchFormData>();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;

  const handleShowQuotationInfoModal = (quotation: QuotationData) => {
    setQuotation(quotation);
    setQuotationInfoModal(true);
  };


  const getQuotationDocument = async (id: number) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "getQuotationDocument",
        variables: {
          quotationId: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });
    if (data.success) {
      message.success(data.message);
    } else {
      message.error(data.message);
    }
  };

  const confirmDelete = async (id: number) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "deleteQuotation",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
      setQuotations(quotations.filter((quotation) => quotation.id !== id));
    } else {
      message.error(data.message);
    }
  };

  const getQuotations = async (
    start: number,
    key: string = "",
    limit: number = 20
  ) => {
    setLoading(true);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "companyQuotations",
        variables: {
          start: { value: start, type: "Int", required: true },
          limit: { value: limit, type: "Int", required: true },
          companyId: {
            value: localStorage.getItem("companyid"),
            type: "Int",
            required: true,
          },
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: [
              "id",
              "quoteNumber",
              "scanner",
              "totalScanner",
              "email",
              "phone",
              "totalReceipts",
              "totalUsers",
              "isPaid",
              "amount",
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
              {
                company: ["id"],
              },
              {
                user: [
                  "id",
                  "firstName",
                  "lastName",
                  {
                    profile: ["middleName", "fullName"],
                  },
                ],
              },
              {
                package: [
                  "id",
                  "name",
                  "code",
                  "price",
                  "maxUsers",
                  "maxReceipts",
                ],
              },
            ],
          },
        ],
      },
    });
    if (data.total !== total) {
      setTotal(data.total);
    }
    if (data.hasNext !== hasNext) {
      setHasNext(data.hasNext);
    }
    setQuotations([...data.results]);
    setLoading(false);
  };


  const columns = [
    {
      title: "Quote #",
      key: "quoteNumber",
      dataIndex: "quoteNumber",
      fixed: true,
      render: (text: any, row: any, index: any) => <span>{text}</span>,
    },
    {
      title: "Creatd By",
      key: "createdBy",
      dataIndex: "createdBy",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        (row.user)?<Tag color="green">{row.user.profile.fullName}</Tag>:<Tag color="red">System</Tag>
      ),
    },
    {
      title: "Package",
      key: "package",
      dataIndex: "package",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <span>{row.package.name}</span>
      ),
    },
    {
      title: "Total Receipts",
      key: "totalReceipts",
      dataIndex: "totalReceipts",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <span>
          {row.totalReceipts === 0 ? (
            <Tag color="green">Unlimited</Tag>
          ) : (
            row.totalReceipts
          )}
        </span>
      ),
    },
    {
      title: "Total Users",
      dataIndex: "totalUsers",
      render: (text: any, row: any, index: any) => (
        <span>
          {row.totalUsers === 0 ? (
            <Tag color="green">Unlimited</Tag>
          ) : (
            row.totalUsers
          )}
        </span>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (text: any, row: any, index: any) => <span>{row.amount}</span>,
    },
    {
      title: "Created Date",
      dataIndex: "created",
      key: "created",
      render: (text: any, row: any, index: any) => (
        <span>
          <ReactTimeAgo date={row.created} locale="en-US" tick={true} />
        </span>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text: any, row: any, index: any) => (
        <span>
          {row.isPaid ? (
            <Tag color="green">Paid</Tag>
          ) : (
            <Tag color="red">Not Paid</Tag>
          )}
        </span>
      ),
    },
    {
      title: "Actions",
      align: "right" as "right",
      dataIndex: "action",
      style: { display: "flex", justifyContent: "flex-end" },
      render: (text: any, row: any, index: any) => (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                <Menu.Item icon={<FolderViewOutlined />}>
                  <a
                    rel="noopener noreferrer"
                    onClick={() => handleShowQuotationInfoModal(row)}
                  >
                    View
                  </a>
                </Menu.Item>
                <Menu.Item icon={<DeleteOutlined />} disabled={!row?.canDelete}>
                  <Tooltip title="Delete Quotation">
                    <Popconfirm
                      title={
                        "Are you sure to delete this " +
                        row?.name +
                        " Quotation?"
                      }
                      onConfirm={() => confirmDelete(row?.id)}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                      disabled={!row?.canDelete}
                    >
                      <a
                        target="_blank"
                        href={""}
                        rel="noopener noreferrer"
                        download
                      >
                        Delete
                      </a>
                    </Popconfirm>
                  </Tooltip>
                </Menu.Item>
                <Menu.Item icon={<FolderViewOutlined />}>
                  <a
                    rel="noopener noreferrer"
                    onClick={() => getQuotationDocument(row.id)}
                  >
                    Download Quotation
                  </a>
                </Menu.Item>
              </Menu>
            }
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <MoreOutlined
                style={{
                  color: "orange",
                  fontSize: 28,
                  fontWeight: "bold",
                }}
              />
            </a>
          </Dropdown>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getQuotations(1, "", 20);
  }, [props.randKey]);

  return (
    <>
      <Context.Provider value={{ name: "Efeeder" }}>
        {contextHolder}
        <Row style={{ marginTop: 10 }}>
          <>
            <Col span={20}>
              <Form<quotationSearchFormData>
                layout="vertical"
                form={searchForm}
                onFinish={(values) => {
                  getQuotations(0, values.key, limit);
                }}
              >
                <Form.Item name="key">
                  <Input
                    size="large"
                    placeholder="Search ..."
                    prefix={<SearchOutlined />}
                    autoComplete="off"
                    allowClear
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col
              span={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                size="large"
                icon={<PlusCircleOutlined />}
                onClick={() => push("/upgrading")}
              >
                Create Quotation
              </Button>
            </Col>
          </>
        </Row>

        <Table
          columns={columns}
          dataSource={quotations}
          pagination={{
            onChange: (page, pageSize) => {
              setLimit(pageSize);
              getQuotations(page, searchKey, pageSize);
            },
            total: total,
            pageSize: limit,
            position: ["bottomCenter", "topCenter"],
            showQuickJumper: false,
          }}
          scroll={{ x: true }}
          loading={loading}
        />
      </Context.Provider>

      <Drawer
        title="Quotations Informations"
        width={"45vw"}
        placement="right"
        closable={true}
        onClose={() => setQuotationInfoModal(false)}
        visible={quotationInfoModal}
      >
        <QuotationInformationComponent quotation={quotation} />
      </Drawer>
    </>
  );
};
