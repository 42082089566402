/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { SearchOutlined, DeleteOutlined, KeyOutlined } from "@ant-design/icons";
import { Button, Form, Grid, Input, Popconfirm, Table } from "@pankod/refine";
import { Col, message, Row } from "antd";
import { gqlDataProvider } from "api";
import { SessionData } from "interfaces";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";

interface ReturnsSearchFormData {
  key: string;
}

interface ReturnsProps {
  height?: any;
  canAdd?: boolean;
}

export const ControlReturnsComponent: React.FC<ReturnsProps> = (
  props: ReturnsProps
) => {
  const [returns, setReturns] = useState<SessionData[]>([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchForm] = Form.useForm<ReturnsSearchFormData>();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;

  const confirmDelete = async (id: number) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "deleteReturn",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
      setReturns(returns.filter((session) => session.id !== id));
    } else {
      message.error(data.message);
    }
  };

  const cancelDelete = () => {
    message.info("Canceled");
  };

  const columns = [
    {
      title: "Return Session Name",
      dataIndex: "name",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <span>
          <a>{row.name}</a>
        </span>
      ),
    },
    {
      title: "Total Receipts",
      dataIndex: "totalReceipts",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      render: (text: any, row: any, index: any) => (
        <span>{row.company.name}</span>
      ),
    },
    {
      title: "Created",
      dataIndex: "created",
      render: (text: any, row: any, index: any) => (
        <>
          <span>
            <ReactTimeAgo date={new Date(row.created)} />
          </span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (text: any, row: any, index: any) => (
        <a>
          {row?.isActive ? (
            <span style={{ color: "green" }}>Active</span>
          ) : (
            <span style={{ color: "red" }}>Inactive</span>
          )}
        </a>
      ),
    },
    {
      title: "Action(s)",
      align: "right" as "right",
      dataIndex: "action",
      render: (text: any, row: any, index: any) => (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Popconfirm
            title={"Ressetting this member Credentials?"}
            onConfirm={() => confirmDelete(row?.id)}
            onCancel={() => cancelDelete}
            okText="Yes"
            cancelText="No"
            disabled={!row?.canDelete}
          >
            <Button icon={<KeyOutlined />} disabled={!row?.canDelete}></Button>
          </Popconfirm>
          <Popconfirm
            title={"Are you sure to delete this member?"}
            onConfirm={() => confirmDelete(row?.id)}
            onCancel={() => cancelDelete}
            okText="Yes"
            cancelText="No"
            disabled={!row?.canDelete}
          >
            <Button
              icon={<DeleteOutlined />}
              disabled={!row?.canDelete}
            ></Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const getReturns = async (
    start: number,
    key: string = "",
    limit: number = 20
  ) => {
    setLoading(true);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "allSessions",
        variables: {
          start: { value: start, type: "Int", required: true },
          limit: { value: limit, type: "Int", required: false },
          key: { value: key, type: "String", required: false },
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: [
              "id",
              "name",
              "totalReceipts",
              "startDate",
              "endDate",
              "created",
              "updated",
              "canEdit",
              "canDelete",
              "isActive",
              {
                company: ["id", "name"],
              },
            ],
          },
        ],
      },
    });
    setTotal(data.total);
    setReturns([...data.results]);
    setLoading(false);
  };

  useEffect(() => {
    getReturns(1, "", 20);
  }, []);

  return (
    <>
      <Row style={{ marginTop: 10 }}>
        <Col span={isMobile ? 24 : 20}>
          <Form<ReturnsSearchFormData>
            layout="vertical"
            form={searchForm}
            onFinish={(values) => {
              setSearchKey(values.key);
              returns.length = 0;
              getReturns(1, values.key, limit);
            }}
          >
            <Form.Item name="key">
              <Input
                size="large"
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                autoComplete="off"
                allowClear
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={returns}
        loading={loading}
        pagination={{
          onChange: (page, pageSize) => {
            setLimit(pageSize);
            getReturns(page, searchKey, pageSize);
          },
          total: total,
          pageSize: limit,
          position: ["bottomCenter", "topCenter"],
          showQuickJumper: false,
        }}
        scroll={{ x: true }}
      />
    </>
  );
};
