/* eslint-disable jsx-a11y/anchor-is-valid */
import { HomeOutlined } from "@ant-design/icons";
import {
  IResourceComponentsProps,
  Authenticated,
  LayoutWrapper,
  Breadcrumb,
  useNavigation,
} from "@pankod/refine";
import { ClientActivationPage } from "./client_activation";
import { QuotationsComponent } from "./components/quotations_component";




const Quotations: React.FC<IResourceComponentsProps> = () => {
  const { push } = useNavigation();

  return (
    <>
      <div style={{ paddingRight: 10, paddingLeft: 10 }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a onClick={() => push("/home")}>
              <HomeOutlined />
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Quotations</Breadcrumb.Item>
        </Breadcrumb>
        <QuotationsComponent />
      </div>
    </>
  );
};

export const QuotationsPage = () => {
  let companyIsActive = localStorage.getItem("companyIsActive");
  if (companyIsActive === "true") {
    return (
      <Authenticated>
        <LayoutWrapper>
          <Quotations />
        </LayoutWrapper>
      </Authenticated>
    );
  } else {
    return <ClientActivationPage />;
  }
};
