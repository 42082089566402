import React, { useEffect, useReducer } from "react";
import { QrReader } from "react-qr-reader";
import { ViewFinder } from "./view_finder";

interface ScannerProps {
  onFinish?: any;
  deviceId?: any;
  groupId?: any;
}

export const ScannerComponent: React.FC<ScannerProps> = (
  props: ScannerProps
) => {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    
  useEffect(() => {
    forceUpdate();
  }, [props.deviceId, props.groupId]);

  return (
    <>
      <QrReader
        ViewFinder={ViewFinder}
        constraints={{
          facingMode: "environment",
        }}
        onResult={(result, error) => {
          if (!!result) {
            props.onFinish({ number: result!.getText() });
          }
        }}
      />
    </>
  );
};
