import React from "react";
import {Layout as DLayout} from "antd";

import {ConfigProvider, Grid, LayoutProps} from "@pankod/refine";
import {ServiceExpireComponent} from "pages/components/service_expire";

export const Layout: React.FC<LayoutProps> = ({
  children,
  Sider,
  Header,
  Footer,
  OffLayoutArea,
}) => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;
  return (
    <ConfigProvider componentSize="small">
      <DLayout style={{minHeight: "100vh", flexDirection: "row"}}>
        <Header />

        <DLayout hasSider={true}>
          <DLayout.Content>
            <div
              style={{
                padding: breakpoint.sm ? 5 : 5,
                minHeight: 360,
                marginTop: breakpoint.sm ? 64 : 70,
              }}
            >
              {!isMobile ? <></> : <ServiceExpireComponent />}
              {children}
            </div>
            <OffLayoutArea />
          </DLayout.Content>
          <Footer />
        </DLayout>
      </DLayout>
    </ConfigProvider>
  );
};
