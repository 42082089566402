/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  useNavigation,
  Alert,
  message,
  ConfigProvider,
} from "@pankod/refine";
import "./styles.css";

import {gqlDataProvider} from "api";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";

const {Text, Title} = Typography;

export interface RegisterForm {
  fullName: string;
  email: string;
  password: string;
  confirmPassword: string;
  companyName: string;
  tin: string;
  phone: string;
}

export const Register: React.FC = () => {
  const [form] = Form.useForm<RegisterForm>();
  const [registered, setRegistered] = useState(false);
  const [successMessage, setSuccessMassage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [token, setToken] = useState("");
  const {push} = useNavigation();

  const CardTitle = (
    <>
      <Title level={3} className="title">
        E-feeder
      </Title>
      {registered ? (
        <Title level={5} className="title">
          Sign In to your Account
        </Title>
      ) : (
        <Title level={5} className="title">
          Create your Account
        </Title>
      )}
    </>
  );

  const register = async (values: any) => {
    setErrorMessage("");
    setSuccessMassage("");
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "register",
        variables: {
          email: {value: values.email, type: "String", required: true},
          phone: {value: values.phone, type: "String", required: true},
          fullName: {value: values.fullName, type: "String", required: true},
          companyName: {
            value: values.companyName,
            type: "String",
            required: true,
          },
          tin: {value: values.tin, type: "String", required: true},
          password: {value: values.password, type: "String", required: true},
          passwordRepeat: {
            value: values.confirmPassword,
            type: "String",
            required: true,
          },
        },
        fields: ["success", "message", "accountAvailable", "errors"],
      },
    });
    if (data.success) {
      setRegistered(true);
      setSuccessMassage(data.message);
      message.success(data.message);
      form.resetFields();
    } else {
      setErrorMessage(data.message);
      message.error(data.message);
    }
  };

  return (
    <ConfigProvider componentSize="small">
      <AntdLayout className="layout" style={{background: "white"}}>
        <Row
          justify="center"
          align="middle"
          style={{
            height: "100vh",
          }}
        >
          <Col xs={22}>
            <div className="container">
              <div className="imageContainer">
                <a onClick={() => push("/login")}>
                  <img
                    src="/images/efeeder.png"
                    alt="E-feeder"
                    style={{width: "100px"}}
                  />
                </a>
              </div>
              {!registered ? (
                <Card title={CardTitle} headStyle={{borderBottom: 0}}>
                  {errorMessage.length > 0 ? (
                    <Alert
                      message="Error"
                      description={errorMessage}
                      type="error"
                      closable
                    />
                  ) : (
                    <></>
                  )}
                  <Form<RegisterForm>
                    layout="vertical"
                    form={form}
                    onFinish={(values) => register(values)}
                    requiredMark={false}
                  >
                    <Form.Item
                      name="fullName"
                      label="Full Name"
                      rules={[{required: true}]}
                    >
                      <Input size="large" placeholder="Full Name" />
                    </Form.Item>
                    <Form.Item
                      name="companyName"
                      label="Bussiness Name"
                      rules={[{required: true}]}
                    >
                      <Input size="large" placeholder="Bussiness Name" />
                    </Form.Item>
                    <Form.Item
                      name="tin"
                      label="TIN"
                      rules={[{required: true}]}
                    >
                      <Input size="large" placeholder="TIN ..." />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[{required: true, type: "email"}]}
                    >
                      <Input size="large" placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      label="Mobile Phone Number"
                      rules={[{required: true, type: "string"}]}
                    >
                      <Input size="large" placeholder="Mobile Phone Number" />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[{required: true}]}
                      style={{marginBottom: "12px"}}
                    >
                      <Input type="password" placeholder="" size="large" />
                    </Form.Item>
                    <Form.Item
                      name="confirmPassword"
                      label="Confirm Password"
                      rules={[{required: true}]}
                      style={{marginBottom: "12px"}}
                    >
                      <Input type="password" placeholder="" size="large" />
                    </Form.Item>

                    <GoogleReCaptchaProvider reCaptchaKey="6LcskuIeAAAAAKN0mmp5WIm9RbtPHJ073x0u_Qwz">
                      <GoogleReCaptcha
                        onVerify={(token) => {
                          setToken(token);
                        }}
                      />
                    </GoogleReCaptchaProvider>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      disabled={token.length === 0 ? true : false}
                      block
                    >
                      Sign up
                    </Button>
                  </Form>
                  <div style={{marginTop: 8}}>
                    <Text style={{fontSize: 12}}>
                      Have an account?{" "}
                      <a
                        onClick={() => push("/login")}
                        style={{fontWeight: "bold"}}
                      >
                        Sign in
                      </a>
                    </Text>
                  </div>
                </Card>
              ) : (
                <>
                  <Card title={CardTitle} headStyle={{borderBottom: 0}}>
                    <Alert
                      message="Success"
                      description={successMessage}
                      type="success"
                    />
                    <Button
                      block
                      style={{marginTop: 20}}
                      onClick={() => push("/login")}
                    >
                      Sign In Now
                    </Button>
                  </Card>
                </>
              )}
            </div>
          </Col>
        </Row>
      </AntdLayout>
    </ConfigProvider>
  );
};
