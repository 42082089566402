const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: ",",
  decimalSeparator: ".",
  symbol: "Tsh",
};

export const currencyFormatter = (
  value: number|any,
  options?: {
    significantDigits: any;
    symbol: any;
    thousandsSeparator: any;
    decimalSeparator: any;
  }
) => {
  try{
    value = parseFloat(value);
  } catch (error){
    value = 0.0;
  }
  options = { ...defaultOptions, ...options };

  const [currency, decimal] = value
    .toFixed(options.significantDigits)
    .toString()
    .split(".");
  return `${options.symbol} ${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  )}${options.decimalSeparator}${decimal}`;
};
