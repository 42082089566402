/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  BranchesOutlined,
  ControlOutlined,
  HomeOutlined,
  MenuOutlined,
  MoneyCollectFilled,
  MoneyCollectOutlined,
  OrderedListOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import {Drawer, Layout, Menu} from "antd";
import {
  Space,
  Button,
  Icons,
  Dropdown,
  Avatar,
  Typography,
  useGetIdentity,
  useLogout,
  useNavigation,
  Grid,
  message,
  Card,
  Col,
  Modal,
  Row,
  Tag,
} from "@pankod/refine";
import {ServiceExpireComponent} from "pages/components/service_expire";
import {menuItemColor, menuItemDiv} from "../sider/styles";
import {useEffect, useState} from "react";
import configs from "configs";
import {gqlDataProvider} from "api";
import {ProfileComponent} from "pages/components/profile_component";

const {LogoutOutlined, UserOutlined} = Icons;
const {Text} = Typography;

export const Header: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [isAgent, setIsAgent] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const {data: user} = useGetIdentity();
  const [visible, setVisible] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const {mutate: logout} = useLogout();
  const {push} = useNavigation();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isHowToPayModalVisible, setIsHowToPayModalVisible] = useState(false);

  const showHowToPayModal = () => {
    setIsHowToPayModalVisible(true);
  };

  const handleHowToPayOk = () => {
    setIsHowToPayModalVisible(false);
  };

  const handleHowToPayCancel = () => {
    setIsHowToPayModalVisible(false);
  };

  const showSupportContactModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getUserCompany = async () => {
    setLoading(true);
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "userCompany",
        variables: {},
        fields: [
          "id",
          {
            company: ["id", "isAgent", "name"],
          },
        ],
      },
    });

    setLoading(false);
    setIsAgent(data.company.isAgent);
    setCompanyName(data.company.name);
  };

  const menu = (
    <Menu>
      {user?.isStaff || user?.isSuperuser ? (
        <Menu.Item
          key={"controlpanel"}
          onClick={() => push("/control/analytics")}
          icon={<ControlOutlined />}
        >
          Control Panel
        </Menu.Item>
      ) : (
        <></>
      )}
      <Menu.Item
        key={"profile"}
        onClick={() => setProfileOpen(true)}
        icon={<UserOutlined />}
      >
        Profile
      </Menu.Item>
      <Menu.Item
        key={"logout"}
        onClick={() => logout()}
        icon={<LogoutOutlined />}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  const appLogoTitle = (
    <div style={{display: "flex", margin: "8px 4px", padding: "4px"}}>
      <Space align="center">
        <span style={{marginRight: 10}}>
          <a onClick={() => setVisible(!visible)} style={{color: "gray"}}>
            <MenuOutlined style={{fontSize: 24, marginTop: 24}} />
          </a>
        </span>
        <a onClick={() => push("/home")}>
          <Avatar src={configs.logo} size={"large"} alt={"Olbongo Logo"} />
          {!isMobile ? (
            <Text ellipsis strong style={{fontSize: 24, paddingLeft: 5}}>
              {configs.system_name}
            </Text>
          ) : (
            <></>
          )}
        </a>
      </Space>
    </div>
  );

  useEffect(() => {
    getUserCompany();
  }, []);

  return (
    <Layout.Header
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: !isMobile ? "0px 24px" : "0px 3px 0px 24px",
        height: "64px",
        backgroundColor: "#FFF",
        zIndex: 999,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
      }}
    >
      {appLogoTitle}
      {isMobile ? <></> : <ServiceExpireComponent />}
      <Space style={{marginLeft: "8px"}}>
        {user?.profile.fullName && !isMobile && (
          <>
            <Text ellipsis strong style={{lineHeight: 2}}>
              <span style={{display: "block"}}>{user.profile.fullName}</span>
              <span
                style={{
                  display: "block",
                  fontSize: "12px",
                  float: "right",
                  marginBottom: "3px",
                }}
              >
                <Tag color="green">{companyName}</Tag>
              </span>
            </Text>
          </>
        )}
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type="link">
            {user?.profile.pic && (
              <Avatar src={user?.profile.pic} alt={user?.profile.fullName} />
            )}
          </Button>
        </Dropdown>
      </Space>
      <Drawer
        title={
          <div style={{display: "flex", margin: "8px 4px", padding: "4px"}}>
            <Space align="center">
              <span style={{marginRight: 10}}>
                <a onClick={() => setVisible(!visible)} style={{color: "gray"}}>
                  <MenuOutlined style={{fontSize: 24, marginTop: 24}} />
                </a>
                <Text
                  ellipsis
                  strong
                  style={{
                    fontSize: 24,
                    paddingLeft: 5,
                    marginBottom: 5,
                    marginLeft: 10,
                  }}
                >
                  {configs.system_name}
                </Text>
              </span>
            </Space>
          </div>
        }
        placement="left"
        size={"default"}
        width={300}
        closable={false}
        onClose={() => setVisible(!visible)}
        visible={visible}
      >
        <Menu
          selectedKeys={[]}
          mode="inline"
          onClick={({key}) => {
            setVisible(!visible);
          }}
        >
          <Menu.Item
            style={menuItemColor}
            key={"/home"}
            icon={<HomeOutlined style={{color: "orange"}} />}
            onClick={() => push("/home")}
          >
            <div style={menuItemDiv}>{"Home"}</div>
          </Menu.Item>

          {!isAgent && (
            <>
              <Menu.Item
                style={menuItemColor}
                key={"/returns"}
                icon={<OrderedListOutlined style={{color: "orange"}} />}
                onClick={() => push("/returns")}
              >
                <div style={menuItemDiv}>{"VAT Returns"}</div>
              </Menu.Item>
              <Menu.Item
                style={menuItemColor}
                key={"/receipts"}
                icon={<MoneyCollectOutlined style={{color: "orange"}} />}
                onClick={() => push("/receipts")}
              >
                <div style={menuItemDiv}>{"Receipts"}</div>
              </Menu.Item>
              <Menu.Item
                style={menuItemColor}
                key={"/projects"}
                icon={<OrderedListOutlined style={{color: "orange"}} />}
                onClick={() => push("/projects")}
              >
                <div style={menuItemDiv}>{"Projects"}</div>
              </Menu.Item>
              <Menu.Item
                style={menuItemColor}
                key={"/users"}
                icon={<UserOutlined style={{color: "orange"}} />}
                onClick={() => push("/users")}
              >
                <div style={menuItemDiv}>{"Members/Users"}</div>
              </Menu.Item>
              <Menu.Item
                style={menuItemColor}
                key={"/branches"}
                icon={<BranchesOutlined style={{color: "orange"}} />}
                onClick={() => push("/branches")}
              >
                <div style={menuItemDiv}>{"Branches"}</div>
              </Menu.Item>
              <Menu.Item
                style={menuItemColor}
                key={"how_to_pay"}
                icon={<MoneyCollectFilled style={{color: "orange"}} />}
                onClick={showHowToPayModal}
              >
                <div style={menuItemDiv}>{"How to Pay"}</div>
              </Menu.Item>
              <Menu.Item
                style={menuItemColor}
                key={"support_contacts"}
                icon={<PhoneOutlined style={{color: "orange"}} />}
                onClick={showSupportContactModal}
              >
                <div style={menuItemDiv}>{"Support Contacts"}</div>
              </Menu.Item>
            </>
          )}

          {isAgent && (
            <>
              <Menu.Item
                style={menuItemColor}
                key={"customers"}
                icon={<OrderedListOutlined style={{color: "orange"}} />}
                onClick={() => push("/agent/customers")}
              >
                <div style={menuItemDiv}>{"Customers"}</div>
              </Menu.Item>

              <Menu.Item
                style={menuItemColor}
                key={"commissions"}
                icon={<OrderedListOutlined style={{color: "orange"}} />}
                onClick={() => push("/agent/commissions")}
              >
                <div style={menuItemDiv}>{"Commissions"}</div>
              </Menu.Item>
            </>
          )}
        </Menu>

        <Modal
          title="Support Contacts"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[]}
        >
          <Card>
            <Space direction="vertical">
              <Text type="danger">Phone Numbers</Text>
              <Text type="success">0765242198</Text>
              <Text type="success">0717512739</Text>
              <Text type="success">0655520115</Text>

              <Text type="danger">Email Address</Text>
              <Text type="success">support@ericogroup.co.tz</Text>
            </Space>
          </Card>
        </Modal>

        <Modal
          title="How to Make Payments"
          visible={isHowToPayModalVisible}
          onOk={handleHowToPayOk}
          onCancel={handleHowToPayCancel}
          footer={[]}
        >
          <Card>
            <Space direction="vertical">
              <Row>
                {/* <Col span={8}>
                <img src="images/lipa_number.jpeg" width={"60%"} />
              </Col> */}
                <Col span={24}>
                  <Space direction="vertical">
                    <p>For Payment please contact us</p>
                    <Space direction="vertical">
                      <Text type="danger">Phone Numbers</Text>
                      <Text type="success">0655520115</Text>
                      <Text type="success">0765242198</Text>
                      <Text type="danger">Email Address</Text>
                      <Text type="success">support@ericogroup.co.tz</Text>
                    </Space>
                  </Space>
                </Col>

                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 30,
                  }}
                >
                  <Button onClick={() => push("/upgrading")} size="large">
                    Upgrade Now
                  </Button>
                </Col>
              </Row>
            </Space>
          </Card>
        </Modal>
      </Drawer>

      <Modal
        title="Account Profile"
        visible={profileOpen}
        onOk={() => setProfileOpen(false)}
        onCancel={() => {
          getUserCompany();
          setProfileOpen(false);
        }}
        footer={[]}
        width={"50%"}
      >
        <ProfileComponent random={Math.random()} />
      </Modal>
    </Layout.Header>
  );
};
