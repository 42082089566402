import { CSSProperties } from "react";

export const antLayoutSider: CSSProperties = {
  overflowY: 'auto',
  overflowX: 'hidden',
  position: "fixed",
  background: "#ffffff",
  color: "black",
  zIndex: 99,
  left: 0,
  top: 0,
  bottom: 0,
};
export const antLayoutSiderMobile: CSSProperties = {
  position: "fixed",
  height: "100vh",
  background: "#fff",
  zIndex: 99,
  left: 0,
  top: 0,
  bottom: 0,
};

export const menuItemColor: CSSProperties = {
  color: "black",
};

export const menuItemDiv: CSSProperties = {
  overflow: 'auto',
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
