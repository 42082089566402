import { AgentsPage } from "./pages/agents_page";
import { ControlAnalyticsPage } from "./pages/analytics_page";
import { CompaniesPage } from "./pages/companies_page";
import { ContactsPage } from "./pages/contacts_page";
import { MessagesPage } from "./pages/messages_page";
import { PaymentsPage } from "./pages/payments_page";
import { PaymentPackagesPage } from "./pages/payment_packages_page";
import { ReceiptsControlPage } from "./pages/receipts_page";
import { ReceiptsSyncedControlPage } from "./pages/receipts_synced_page";
import { ReturnsControlPage } from "./pages/returns_page";
import { ControlUsersPage } from "./pages/users_page";

export const controlRoutes = [
  {
    exact: true,
    component: ControlAnalyticsPage,
    path: "/control/analytics",
  },
  {
    exact: true,
    component: CompaniesPage,
    path: "/control/companies",
  },
  {
    exact: true,
    component: ControlUsersPage,
    path: "/control/users",
  },
  {
    exact: true,
    component: PaymentsPage,
    path: "/control/payments",
  },
  {
    exact: true,
    component: MessagesPage,
    path: "/control/messages",
  },
  {
    exact: true,
    component: ReturnsControlPage,
    path: "/control/returns",
  },
  {
    exact: true,
    component: ReceiptsControlPage,
    path: "/control/receipts",
  },
  {
    exact: true,
    component: ReceiptsSyncedControlPage,
    path: "/control/receipts/synced",
  },
  {
    exact: true,
    component: PaymentPackagesPage,
    path: "/control/payment/packages",
  },
  {
    exact: true,
    component: ContactsPage,
    path: "/control/contacts",
  },
  {
    exact: true,
    component: AgentsPage,
    path: "/control/agents",
  },
];
