/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { Descriptions, Spin } from "@pankod/refine";
import { PaymentData, QuotationData } from "interfaces";
import { useEffect, useState } from "react";
import Moment from "moment";
import { gqlDataProvider } from "api";

interface quotationProps {
  quotation?: QuotationData;
}

export const QuotationInformationComponent: React.FC<quotationProps> = (
  props: quotationProps
) => {
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState<PaymentData[]>([]);

  const getQuotationPayments = async (
    start: number,
    key: string = "",
    limit: number = 20
  ) => {
    setLoading(true);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "quotationPayments",
        variables: {
          start: { value: start, type: "Int", required: true },
          limit: { value: limit, type: "Int", required: true },
          quotationId: {
            value: parseInt(
              props.quotation?.id ? props.quotation?.id.toString() : "0"
            ),
            type: "Int",
            required: true,
          },
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: [
              "id",
              "amount",
              "created",
              "updated",
              "canEdit",
              "canDelete",
              "canManage",
              "isActive",
            ],
          },
        ],
      },
    });
    setPayments([...data.results]);
    setLoading(false);
  };

  useEffect(() => {
    getQuotationPayments(1, "", 20);
  }, [props.quotation]);

  return (
    <>
      <Descriptions size="small" column={2} title="Quotation Informations">
        <Descriptions.Item label="Quote #">
          {props.quotation?.quoteNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Package Name">
          {props.quotation?.package.name}
        </Descriptions.Item>
        <Descriptions.Item label="Total Receipts">
          {props.quotation?.totalReceipts === 0
            ? "Unlimited"
            : props.quotation?.totalReceipts}
        </Descriptions.Item>
        <Descriptions.Item label="Total Users">
          {props.quotation?.totalUsers === 0
            ? "Unlimited"
            : props.quotation?.totalUsers}
        </Descriptions.Item>
        <Descriptions.Item label="Price">
          {props.quotation?.amount}
        </Descriptions.Item>

        <Descriptions.Item label="Created">
          {Moment(props.quotation?.created).format("DD/MM/YYYY")}
        </Descriptions.Item>
      </Descriptions>

      <Spin spinning={loading}>
        {payments.length === 0 && (
          <div className="text-center">
            <h3>No Payments Found</h3>
          </div>
        )}
        {payments.map((payment) => (
          <Descriptions size="small" column={2} title="Payment Informations">
            <Descriptions.Item label="Amount">
              {payment.amount}
            </Descriptions.Item>
            <Descriptions.Item label="Created">
              {Moment(payment.created).format("DD/MM/YYYY")}
            </Descriptions.Item>
          </Descriptions>
        ))}
      </Spin>
    </>
  );
};
