import { Button, Form, Input } from "@pankod/refine";

interface creatingCompanyProps {
  form?: any;
  onFinish?: any;
}

export const CreateCompanyFormComponent: React.FC<creatingCompanyProps> = (
  props: creatingCompanyProps
) => {
  return (
    <>
      <Form
        layout="vertical"
        form={props.form}
        onFinish={(values) => {
          props.onFinish(values);
        }}
        requiredMark={false}
      >
        <Form.Item
          name="name"
          label="Company/Bussiness Name"
          rules={[{ required: true, type: "string" }]}
        >
          <Input size="large" placeholder="Enter Name ..." autoFocus={true} />
        </Form.Item>


        <Form.Item
          name="shortName"
          label="Company/Bussiness Short Name"
          rules={[{ required: true, type: "string" }]}
        >
          <Input size="large" placeholder="Enter Short Name ..." autoFocus={true} />
        </Form.Item>

        <Form.Item
          name="tin"
          label="TIN"
          rules={[{ required: true, type: "string" }]}
        >
          <Input size="large" placeholder="Enter TIN ..." autoFocus={true} />
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
