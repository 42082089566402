import {
  IResourceComponentsProps,
  Authenticated,
  LayoutWrapper,
  Spin,
} from "@pankod/refine";
import {gqlDataProvider} from "api";
import {useEffect, useState} from "react";
import {AgentDashboardComponent} from "./agent_dash";
import {ClientActivationPage} from "./client_activation";
import {SessionsComponent} from "./components/dash";
import {NoCompanyConnectedPage} from "./no_company_connected";

const ReturnSessions: React.FC<IResourceComponentsProps> = () => {
  return <SessionsComponent randKey={Math.random()} />;
};

export const HomePage = () => {
  const [loading, setLoading] = useState(true);
  const [isAgent, setIsAgent] = useState(false);
  let companyIsActive = localStorage.getItem("companyIsActive");

  const getUserCompany = async () => {
    setLoading(true);
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "userCompany",
        variables: {},
        fields: [
          "id",
          {
            company: ["id", "isAgent"],
          },
        ],
      },
    });

    setLoading(false);
    setIsAgent(data.company.isAgent);
  };

  useEffect(() => {
    getUserCompany();
  }, []);

  if (
    companyIsActive === "true" &&
    localStorage.getItem("companyid") !== null
  ) {
    return (
      <Authenticated>
        <LayoutWrapper>
          <Spin spinning={loading}>
            {!isAgent ? <ReturnSessions /> : <AgentDashboardComponent />}
          </Spin>
        </LayoutWrapper>
      </Authenticated>
    );
  } else if (localStorage.getItem("companyid") === null) {
    return (
      <Authenticated>
        <NoCompanyConnectedPage />
      </Authenticated>
    );
  } else {
    return (
      <Authenticated>
        <ClientActivationPage />
      </Authenticated>
    );
  }
};
