import {
  CheckCircleFilled,
  ClearOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Descriptions,
  Form,
  Input,
  message,
  Spin,
  Tooltip,
} from "@pankod/refine";
import { gqlDataProvider } from "api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InputNumber, notification, Result } from "antd";
import React from "react";

const Context = React.createContext({ name: "Default" });

const { TextArea } = Input;
interface addingReceiptsProps {
  onFinish?: any;
}

export interface CreateReceiptForm {
  number: string;
  description: string;
}

export const AddingReceiptsFormComponent: React.FC<addingReceiptsProps> = (
  props: addingReceiptsProps
) => {
  const [code, setCode] = useState("");
  const [syncedData, setSyncedData] = useState<any>();
  const [syncing, setSynching] = useState(false);
  const [synced, setSynced] = useState(false);
  const [resync, setResync] = useState(false);
  const [form] = Form.useForm<CreateReceiptForm>();
  const [loading, setLoading] = useState(false);
  const [validateDescription, setValidateDescription] = useState(true);
  const [autoAdd, setAutoAdd] = useState(true);
  const [validateUniquness, setValidateUniquness] = useState(false);
  const [vat, setVat] = useState(true);
  const params: any = useParams();

  const openNotification = (data: any) => {
    const args = {
      message: "VFD Validity Confirmaion",
      style: {
        width: 630,
      },
      description: (
        <>
          <Result
            status={data.customer_id.toString().replace(" ", "").toLowerCase() === JSON.parse(localStorage.user).companyuser[0].company.tin.toString().replace(" ", "").toLowerCase() ? "success" : "error"}
            title={
              data.customer_id.toString().replace(" ", "").toLowerCase() === JSON.parse(localStorage.user).companyuser[0].company.tin.toString().replace(" ", "").toLowerCase()
                ? "Successfully Verified!"
                : "Verification Failed!"
            }
            style={{ padding: 0 }}
            extra={[]}
          />
          <div style={{ overflowY: "auto", maxHeight: "75vh" }}>
            <Descriptions
              title="Customer Informations"
              column={1}
              labelStyle={{ fontWeight: "bold" }}
            >
              <Descriptions.Item label="Customer Name">
                {data.customer_name}
              </Descriptions.Item>
              <Descriptions.Item label="Customer Tax Identification Number">
                {data.customer_id}
              </Descriptions.Item>
              <Descriptions.Item label="Customer Mobile Phone">
                {data.customer_mobile}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions
              title="Supplier Informations"
              column={1}
              labelStyle={{ fontWeight: "bold" }}
            >
              <Descriptions.Item label="Supplier Name">
                {data.supplier_name}
              </Descriptions.Item>
              <Descriptions.Item label="VRN">{data.vrn}</Descriptions.Item>
              <Descriptions.Item label="TIN">{data.tin}</Descriptions.Item>
              <Descriptions.Item label="Address">
                {data.address}
              </Descriptions.Item>
              <Descriptions.Item label="Serial No:">
                {data.serial_no}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile">
                {data.mobile}
              </Descriptions.Item>
              <Descriptions.Item label="Tax Office">
                {data.tax_office}
              </Descriptions.Item>
              <Descriptions.Item label="UIN">{data.uin}</Descriptions.Item>
            </Descriptions>
            <Descriptions
              title="Extra Informations"
              column={1}
              labelStyle={{ fontWeight: "bold" }}
            >
              <Descriptions.Item label="Description">
                {data.description}
              </Descriptions.Item>
              <Descriptions.Item label="Amount Inc. Tax">
                {data.total_incl_of_tax}
              </Descriptions.Item>
              <Descriptions.Item label="Amount Exc. Tax">
                {data.total_excl_of_tax}
              </Descriptions.Item>
              <Descriptions.Item label="Tax Amount">
                {data.total_tax}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </>
      ),
      duration: 0,
      onClose: () => {
        form.resetFields();
        message.info("Form Successfully Cleared, you can Scan another Receipt to Proceed");
      }
    };
    notification.open(args);
  };

  const syncDirect = async (url: any) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "syncTraDirect",
        variables: {
          url: {
            value: url,
            type: "String",
            required: true,
          },
        },
        fields: ["success", "message", "data"],
      },
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    if (data && data.success) {
      setResync(false);
      setSynching(false);
      if (data.data.synced) {
        setSynced(true);
        setSyncedData(data.data);
        form.setFieldsValue({
          description: data.data.description,
        });
        console.log(autoAdd);
        if (!autoAdd) {
          console.log(JSON.parse(localStorage.user).companyuser[0].company.tin);
          openNotification(data.data);
        }
        if (autoAdd) {
          form.submit();
        }
      } else {
        setSynced(false);
      }
    } else {
      message.error("Failed to Retrieve Data From TRA");
      setResync(true);
      setSynching(false);
      setSynced(true);
      setLoading(false);
    }
  };

  const sync = async (value: any) => {
    if (value.split("_").length > 1) {
      let timeStamp = value.split("_")[1];
      if (timeStamp.length === 6) {
        setSynching(true);
        syncDirect(value);
      } else {
        setSynching(false);
        setSynced(true);
      }
    } else {
      setSynching(false);
      setSynced(true);
    }
  };

  const onSubmit = async (values: any) => {
    setLoading(true);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "createReceipt",
        variables: {
          unique: {
            value: validateUniquness,
            type: "Boolean",
            required: false,
          },
          sessionId: {
            value: params.id,
            type: "Int",
            required: true,
          },
          number: {
            value: values.number.split("/").pop().split("_")[0],
            type: "String",
            required: true,
          },
          scannedContent: {
            value: values.number,
            type: "String",
            required: true,
          },
          description: {
            value: values.description,
            type: "String",
            required: false,
          },
          amount: {
            value: values.amount,
            type: "Float",
            required: false,
          },
          supplier: {
            value: values.supplier,
            type: "String",
            required: false,
          },
          vat: {
            value: vat,
            type: "Boolean",
            required: false,
          },
          vatAmount: {
            value: values.vatAmount,
            type: "Float",
            required: false,
          },
          data: {
            value: syncedData,
            type: "JSON",
            required: false,
          },
        },
        fields: [
          "success",
          "message",
          {
            receipt: [
              "id",
              "number",
              "description",
              "totalInclOfTax",
              "supplierName",
              "customerName",
              "tin",
              "vrn",
              "synced",
              {
                author: ["id", "fullName"],
              },
              "created",
              "updated",
              "canEdit",
              "canDelete",
              "canManage",
              "isActive",
              {
                session: ["id"],
              },
            ],
          },
        ],
      },
    });
    if (data.success) {
      message.success(data.message);
      props.onFinish(data.receipt);
    } else {
      message.error(data.message);
    }
    form.resetFields();
    setSynching(false);
    setLoading(false);
    setSynced(false);
    setSyncedData(undefined);
  };

  useEffect(() => {
    console.log(autoAdd);
  }, []);

  return (
    <>
      <Context.Provider value={{ name: "VFD Informations" }}>
        <Form
          layout="vertical"
          form={form}
          onFinish={(values) => {
            onSubmit(values);
            notification.destroy();
          }}
          requiredMark={false}
        >
          <div>
            <Checkbox
              onChange={() => {
                setAutoAdd(!autoAdd);
              }}
              defaultChecked
            >
              <span style={{ fontSize: 14 }}>
                <Tooltip title={autoAdd ? "" : ""}>Auto Add</Tooltip>
              </span>
            </Checkbox>
          </div>
          <div>
            <Checkbox
              onChange={() => {
                setValidateUniquness(!validateUniquness);
              }}
            >
              <span style={{ fontSize: 14 }}>
                <Tooltip
                  title={
                    validateDescription
                      ? "Click to remove the mandate of Validate Receipt Uniqueness Over all other Months"
                      : "Click to add the mandate of providing Validation of Receipt Uniqueness Over all other Months"
                  }
                >
                  Validating Uniqueness Of Receipt Over All Months
                </Tooltip>
              </span>
            </Checkbox>
          </div>
          <div style={{ marginTop: 10 }}>
            <Checkbox
              onChange={() => {
                setValidateDescription(!validateDescription);
              }}
              defaultChecked
            >
              <span style={{ fontSize: 14 }}>
                <Tooltip
                  title={
                    validateDescription
                      ? "Click to remove the mandate of providing Receipt Description"
                      : "Click to add the mandate of providing Receipt Description"
                  }
                >
                  Validating Receipt Description
                </Tooltip>
              </span>
            </Checkbox>
          </div>
          <Form.Item
            name="number"
            label="Receipt Number"
            rules={[
              {
                required: true,
                type: "string",
                message: "Please input Receipt Verification Code ...",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Scan EFD Receipt ..."
              autoFocus={true}
              disabled={loading || syncing}
              onChange={(e) => {
                try {
                  //check if  '{' is present in the string
                  if (e.target.value.indexOf("{") > -1) {
                    let data = JSON.parse(e.target.value);
                    form.setFieldsValue({
                      number: data.billReference,
                    });
                  } else {
                    if (e.target.value.length > 0) {
                      let val = e.target.value;
                      let sp = val.split("/");
                      if (sp.length > 0) {
                        let p = sp[sp.length - 1];
                        if (p?.split("_").length > 1) {
                          setCode(e.target.value);
                          sync(e.target.value);
                        } else {
                          setSynced(true);
                        }
                      }
                    }
                  }
                  setSynced(true);
                } catch (error) {
                  if (e.target.value.length > 0) {
                    let val = e.target.value;
                    let sp = val.split("/");
                    if (sp.length > 0) {
                      let p = sp[sp.length - 1];
                      if (p?.split("_").length > 1) {
                        sync(e.target.value);
                      } else {
                        setSynced(true);
                      }
                    }
                  }
                }
              }}
              ref={(input) => input && input.focus()}
            />
          </Form.Item>

          <Form.Item
            name="description"
            label={
              <>
                <span>
                  {"Description" +
                    (validateDescription && !synced
                      ? "(Required)"
                      : " (Optional)")}
                </span>
                {synced ? (
                  <CheckCircleFilled style={{ color: "green" }} />
                ) : (
                  <CloseOutlined style={{ color: "red" }} />
                )}
              </>
            }
            rules={[{ required: validateDescription, type: "string" }]}
          >
            <TextArea rows={2} placeholder="Enter Receipt Description ..." />
          </Form.Item>

          <Form.Item
            name="amount"
            label="Receipt Amount (Optional)"
            rules={[
              {
                required: false,
                type: "number",
                message: "Please input Receipt Amount ...",
              },
            ]}
          >
            <InputNumber size="large" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="vatAmount"
            label="Receipt VAT Amount (Optional)"
            rules={[
              {
                required: false,
                type: "number",
                message: "Please input Receipt Amount ...",
              },
            ]}
          >
            <InputNumber size="large" style={{ width: "100%" }} />
          </Form.Item>

          <div style={{ marginTop: 5, marginBottom: 10 }}>
            <Checkbox
              onChange={() => {
                setVat(!vat);
              }}
              defaultChecked={vat}
            >
              <span style={{ fontSize: 16 }}>VAT (18%)</span>
            </Checkbox>
          </div>

          <Form.Item
            name="supplier"
            label="Supplier/Client Name (Optional)"
            rules={[{ required: false, type: "string" }]}
          >
            <TextArea rows={2} placeholder="Enter Supplier/Client Name ..." />
          </Form.Item>

          {resync && !syncing && (
            <>
              <Button onClick={() => sync(code)} type="default" color="red">
                Get TRA Data
              </Button>
            </>
          )}

          {syncing && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 30,
                }}
              >
                <Spin spinning={syncing}></Spin>Synchronizing TRA Receipt Data
                <span style={{ marginLeft: 30 }}>
                  <Tooltip
                    title="Cancel Sync of Informations from TRA"
                    color={"red"}
                  >
                    <Button
                      shape="circle"
                      onClick={() => {
                        setSynching(false);
                        setSynced(true);
                      }}
                    >
                      <CloseOutlined style={{ color: "red" }} />
                    </Button>
                  </Tooltip>
                </span>
              </div>
            </>
          )}

          <div style={{ display: "flex" }}>
            <Button
              type="default"
              size="large"
              icon={<ClearOutlined />}
              loading={loading}
              disabled={!synced || syncing}
              onClick={() => {
                form.resetFields();
                message.info("Form Successfully Cleared");
                notification.destroy();
              }}
              danger
            >
              Clear
            </Button>
            <Button
              style={{ marginLeft: "auto" }}
              type="primary"
              size="large"
              htmlType="submit"
              icon={<PlusOutlined />}
              loading={loading}
              disabled={!synced || syncing}
            >
              Add
            </Button>
          </div>
        </Form>
      </Context.Provider>
    </>
  );
};
