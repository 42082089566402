/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Form, Input, message} from "@pankod/refine";
import {gqlDataProvider} from "api";
import {CompanyData} from "interfaces";
import {useEffect} from "react";

interface editProfileCompanyProps {
  form?: any;
  onFinish?: any;
  company?: CompanyData;
  random?: any;
}

export const EditProfileCompanyFormComponent: React.FC<
  editProfileCompanyProps
> = (props: editProfileCompanyProps) => {
  const updateCompany = async (values: any) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "updateCompany",
        variables: {
          id: {
            value: localStorage.getItem("companyid"),
            type: "Int",
            required: true,
          },
          name: {value: values.name, type: "String", required: true},
          shortName: {
            value: values.short_name,
            type: "String",
            required: false,
          },
          tin: {
            value: values.tin,
            type: "String",
            required: true,
          },
          vrn: {
            value: values.vrn,
            type: "String",
            required: false,
          },
        },
        fields: [
          "success",
          "message",
          {
            company: [
              "id",
              "name",
              "shortName",
              "tin",
              "vrn",
              "code",
              "trial",
              "totalReceipts",
              "expire",
              "expired",
              "canEdit",
              "canDelete",
              "canManage",
              {
                district: [
                  "id",
                  "name",
                  {
                    region: ["id", "name"],
                  },
                ],
              },
              {
                ward: ["id", "name"],
              },
            ],
          },
        ],
      },
    });
    if (data.success) {
      message.success(data.message);
      props.onFinish(data.company);
    } else {
      message.error(data.message);
    }
  };

  useEffect(() => {
    props.form.setFieldsValue({
      name: props.company?.name,
      short_name: props.company?.shortName,
      tin: props.company?.tin,
      vrn: props.company?.vrn,
    });
  }, [props.company, props.random]);

  return (
    <>
      <Form
        layout="vertical"
        form={props.form}
        onFinish={(values) => {
          values.id = props.company?.id;
          updateCompany(values);
        }}
        requiredMark={false}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{required: true, type: "string"}]}
        >
          <Input size="large" placeholder="Enter Name ..." />
        </Form.Item>
        <Form.Item
          name="short_name"
          label="Short Name"
          rules={[{required: false, type: "string"}]}
        >
          <Input size="large" placeholder="Enter Short Name ..." />
        </Form.Item>

        <Form.Item
          name="tin"
          label="TIN"
          rules={[{required: true, type: "string"}]}
        >
          <Input size="large" placeholder="Enter TIN ..." />
        </Form.Item>

        <Form.Item
          name="vrn"
          label="VRN"
          rules={[{required: false, type: "string"}]}
        >
          <Input size="large" placeholder="Enter VRN ..." />
        </Form.Item>

        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Button type="primary" size="large" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
