/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  SearchOutlined,
  DeleteOutlined,
  PlusOutlined,
  SendOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Table,
} from "@pankod/refine";
import { Col, Grid, message, Row, Tag } from "antd";
import { gqlDataProvider } from "api";
import { MessageData } from "interfaces";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { ComposeMessageFormComponent } from "./forms/compose_message_form";
import { ComposeMessageSpecificFormComponent } from "./forms/compose_message_specific";

interface MessagesSearchFormData {
  key: string;
}

interface ComposeMessageFormData {
  content: string;
}

interface ComposeMessageSpecificFormData {
  content: string;
  phones: string;
}

interface MessagesProps {
  height?: any;
  canAdd?: boolean;
}

export const MessagesComponent: React.FC<MessagesProps> = (
  props: MessagesProps
) => {
  const [messages, setMessages] = useState<MessageData[]>([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchForm] = Form.useForm<MessagesSearchFormData>();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;
  const [composeMessageForm] = Form.useForm<ComposeMessageFormData>();
  const [composeMessageSpecificForm] =
    Form.useForm<ComposeMessageSpecificFormData>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMessageContactsVisible, setIsMessageContactsVisible] =
    useState(false);

  const onFinishComposeMessageContacts = async (
    content: string,
    phones: string
  ) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "composeMessageContacts",
        variables: {
          content: {
            value: content,
            type: "String",
            required: true,
          },
          phones: {
            value: phones,
            type: "String",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
      setIsMessageContactsVisible(false);
      getMessages(1);
    } else {
      message.error(data.message);
    }
  };

  const onFinishComposeMessage = async (content: string) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "composeMessage",
        variables: {
          content: {
            value: content,
            type: "String",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
      setIsModalVisible(false);
      getMessages(1);
    } else {
      message.error(data.message);
    }
  };

  const confirmDelete = async (id: number) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "deleteMessage",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
      setMessages(messages.filter((message) => message.id !== id));
    } else {
      message.error(data.message);
    }
  };

  const cancelDelete = () => {
    message.info("Canceled");
  };

  const cancelResend = () => {
    message.info("Canceled");
  };

  const confirmResend = async (id: number, index: number) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "resendMessage",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          {
            sentMessage: [
              "id",
              "message",
              "phone",
              "messageContent",
              "resultCode",
              "result",
              "reference",
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
            ],
          },
        ],
      },
    });

    if (data.success) {
      message.success(data.message);
      let msgs = [...messages];
      msgs[index] = { ...msgs[index], ...data.sentMessage };
      setMessages(msgs);
    } else {
      message.error(data.message);
    }
  };

  const columns = [
    {
      title: "Message",
      dataIndex: "message",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <span>
          <p>{row.messageContent}</p>
        </span>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      render: (text: any, row: any, index: any) => <span>{row.phone}</span>,
    },
    {
      title: "Result Code",
      dataIndex: "resultCode",
    },
    {
      title: "Result",
      dataIndex: "result",
    },
    {
      title: "Status",
      dataIndex: "message",
      render: (text: any, row: any, index: any) => (
        <Tag color={row.resultCode === "201" ? "green" : "red"}>
          {row.message}
        </Tag>
      ),
    },
    {
      title: "Reference",
      dataIndex: "reference",
    },
    {
      title: "Created",
      dataIndex: "created",
      render: (text: any, row: any, index: any) => (
        <ReactTimeAgo date={new Date(text)}></ReactTimeAgo>
      ),
    },
    {
      title: "Action(s)",
      align: "right" as "right",
      dataIndex: "action",
      render: (text: any, row: any, index: any) => (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Popconfirm
            title={"Are you sure to delete this Message"}
            onConfirm={() => confirmDelete(row?.id)}
            onCancel={() => cancelDelete}
            okText="Yes"
            cancelText="No"
            disabled={!row?.canDelete}
          >
            <Button
              icon={<DeleteOutlined />}
              disabled={!row?.canDelete}
            ></Button>
          </Popconfirm>

          <Popconfirm
            title={"Are you sure to resend this Message"}
            onConfirm={() => confirmResend(row?.id, index)}
            onCancel={() => cancelResend}
            okText="Yes"
            cancelText="No"
            disabled={!row?.canManage}
          >
            <Button icon={<SendOutlined />} disabled={!row?.canManage}></Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const getMessages = async (
    start: number,
    key: string = "",
    limit: number = 20
  ) => {
    setLoading(true);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "messages",
        variables: {
          start: { value: start, type: "Int", required: true },
          limit: { value: limit, type: "Int", required: false },
          key: { value: key, type: "String", required: false },
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: [
              "id",
              "message",
              "phone",
              "messageContent",
              "resultCode",
              "result",
              "reference",
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
            ],
          },
        ],
      },
    });
    setTotal(data.total);
    setMessages([...data.results]);
    setLoading(false);
  };

  useEffect(() => {
    getMessages(1, "", 20);
  }, []);

  return (
    <>
      <Row style={{ marginTop: 10 }}>
        <Col span={isMobile ? 24 : 16}>
          <Form<MessagesSearchFormData>
            layout="vertical"
            form={searchForm}
            onFinish={(values) => {
              setSearchKey(values.key);
              getMessages(0, values.key, limit);
            }}
          >
            <Form.Item name="key">
              <Input
                size="large"
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                autoComplete="off"
                allowClear
              />
            </Form.Item>
          </Form>
        </Col>

        <Col
          span={isMobile ? 24 : 4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            size="large"
            icon={<PlusOutlined />}
            onClick={() => setIsModalVisible(!isModalVisible)}
          >
            Compose (All) Message
          </Button>
        </Col>
        <Col
          span={isMobile ? 24 : 4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            size="large"
            icon={<PlusOutlined />}
            onClick={() =>
              setIsMessageContactsVisible(!isMessageContactsVisible)
            }
          >
            Compose (Specific) Message
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={messages}
        pagination={{
          onChange: (page, pageSize) => {
            setLimit(pageSize);
            getMessages(page, searchKey, pageSize);
          },
          total: total,
          pageSize: limit,
          position: ["bottomCenter", "topCenter"],
          showQuickJumper: false,
        }}
        scroll={{ x: true }}
        loading={loading}
      />
      <Modal
        title="Compose Message to All Contacts"
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(!isModalVisible);
        }}
        onCancel={() => {
          setIsModalVisible(!isModalVisible);
        }}
        footer={[]}
        width={"30vw"}
      >
        <ComposeMessageFormComponent
          onFinish={onFinishComposeMessage}
          form={composeMessageForm}
        />
      </Modal>

      <Modal
        title="Compose Message to Specific Contacts"
        visible={isMessageContactsVisible}
        onOk={() => {
          setIsMessageContactsVisible(!isMessageContactsVisible);
        }}
        onCancel={() => {
          setIsMessageContactsVisible(!isMessageContactsVisible);
        }}
        footer={[]}
        width={"30vw"}
      >
        <ComposeMessageSpecificFormComponent
          onFinish={onFinishComposeMessageContacts}
          form={composeMessageSpecificForm}
        />
      </Modal>
    </>
  );
};
