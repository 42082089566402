import { Button, Form, Input } from "@pankod/refine";


interface createProjectProps {
  form?: any;
  onFinish?: any;
}

export const CreateProjectFormComponent: React.FC<createProjectProps> = (
  props: createProjectProps
) => {
  return (
    <>
      <Form
        layout="vertical"
        form={props.form}
        onFinish={(values) => {
          props.onFinish(values);
        }}
        requiredMark={false}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, type: "string" }]}
        >
          <Input size="large" placeholder="Enter Project Name ..." />
        </Form.Item>
        
        <Form.Item
          name="description"
          label="description"
          rules={[{ required: true, type: "string" }]}
        >
          <Input size="large" placeholder="Project Description ..." />
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
