/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  SearchOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Table,
  Tooltip,
  useNavigation,
} from "@pankod/refine";
import { Col, Grid, message, Row, Spin } from "antd";
import { gqlDataProvider } from "api";
import { ProjectData } from "interfaces";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactTimeAgo from "react-time-ago";
import { CreateProjectFormComponent } from "./forms/create_project_form";
import { EditProjectFormComponent } from "./forms/edit_project_form";

interface ProjectsSearchFormData {
  key: string;
}

interface ProjectsProps {
  height?: any;
}

export interface CreateProjectForm {
  name: string;
  description: string;
}

export const ProjectsComponent: React.FC<ProjectsProps> = (
  props: ProjectsProps
) => {
  const [projects, setProjects] = useState<ProjectData[]>([]);
  const [project, setProject] = useState<ProjectData>();
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [hasNext, setHasNext] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchForm] = Form.useForm<ProjectsSearchFormData>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [edittingModal, setEdittingModal] = useState(false);
  const [form] = Form.useForm<CreateProjectForm>();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;

  const showEdittingModal = (project: ProjectData) => {
    setProject(project);
    setEdittingModal(true);
  };

  const showCreateProjectModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values: any) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "createProject",
        variables: {
          id: {
            value: localStorage.getItem("companyid"),
            type: "Int",
            required: true,
          },
          name: { value: values.name, type: "String", required: true },
          description: {
            value: values.description,
            type: "String",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          {
            project: [
              "id",
              "name",
              "code",
              "description",
              {
                company: ["id"],
              },
              {
                author: [
                  "id",
                  "firstName",
                  "lastName",
                  "email",
                  {
                    profile: ["id", "fullName", "pic"],
                  },
                ],
              },
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
            ],
          },
        ],
      },
    });
    if (data.success) {
      message.success(data.message);
      setIsModalVisible(false);
      setProjects([data.project, ...projects]);
      form.resetFields();
    } else {
      message.error(data.message);
    }
  };

  const onFinishEdit = async (values: any) => {
    console.log(values);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "updateProject",
        variables: {
          id: {
            value: values.id,
            type: "Int",
            required: true,
          },
          name: { value: values.name, type: "String", required: true },
          description: {
            value: values.description,
            type: "String",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          {
            project: [
              "id",
              "name",
              "code",
              "description",
              {
                company: ["id"],
              },
              {
                author: [
                  "id",
                  "firstName",
                  "lastName",
                  "email",
                  {
                    profile: ["id", "fullName", "pic"],
                  },
                ],
              },
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
            ],
          },
        ],
      },
    });
    if (data.success) {
      message.success(data.message);
      setEdittingModal(false);
      setProjects(projects.map((project) => {
        if (project.id === data.project.id) {
          return data.project;
        }
        return project;
      }));
    } else {
      message.error(data.message);
    }
  };

  const confirmDelete = async (id: number) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "deleteProject",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
      setProjects(projects.filter((project) => project.id !== id));
    } else {
      message.error(data.message);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text: any, row: any, index: any) => (
        <a onClick={() => {}}>{row.name}</a>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      render: (text: any, row: any, index: any) => (
        <a onClick={() => {}}>{row.code}</a>
      ),
    },
    {
      title: "Created",
      dataIndex: "created",
      render: (text: any, row: any, index: any) => (
        <ReactTimeAgo date={new Date(row.created)} />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: any, row: any, index: any) =>
        row.isActive ? "Active" : "Inactive",
    },
    {
      title: "Action(s)",
      dataIndex: "action",
      align: "right" as "right",
      render: (text: any, row: any, index: any) => (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                <Menu.Item icon={<EditOutlined />}>
                  <a
                    rel="noopener noreferrer"
                    onClick={() => showEdittingModal(row)}
                  >
                    Edit
                  </a>
                </Menu.Item>
                <Menu.Item icon={<DeleteOutlined />}>
                  <Popconfirm
                    title={
                      "Are you sure to delete this " + row?.name + " Project?"
                    }
                    onConfirm={() => confirmDelete(row?.id)}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                    disabled={!row?.canDelete}
                  >
                    <a
                      target="_blank"
                      href={""}
                      rel="noopener noreferrer"
                      download
                    >
                      Delete
                    </a>
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            }
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <MoreOutlined
                style={{
                  color: "orange",
                  fontSize: 28,
                  fontWeight: "bold",
                }}
              />
            </a>
          </Dropdown>
        </div>
      ),
    },
  ];

  const getProjects = async (
    start: number,
    key: string = "",
    limit: number = 20
  ) => {
    setLoading(true);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "companyProjects",
        variables: {
          start: { value: start, type: "Int", required: true },
          key: { value: key, type: "String", required: false },
          id: {
            value: localStorage.getItem("companyid"),
            type: "Int",
            required: true,
          },
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: [
              "id",
              "name",
              "code",
              "description",
              {
                company: ["id"],
              },
              {
                author: [
                  "id",
                  "firstName",
                  "lastName",
                  "email",
                  {
                    profile: ["id", "fullName", "pic", "efeederCode"],
                  },
                ],
              },
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
            ],
          },
        ],
      },
    });
    if (data.total !== total) {
      setTotal(data.total);
    }
    if (data.hasNext !== hasNext) {
      setHasNext(data.hasNext);
    }
    setProjects([...data.results]);
    setLoading(false);
  };

  useEffect(() => {
    getProjects(1, "", 20);
  }, []);

  return (
    <>
      <Row style={{ marginTop: 10 }}>
        {isMobile ? (
          <>
            <Col
              span={isMobile ? 24 : 4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                size="large"
                icon={<PlusCircleOutlined />}
                onClick={showCreateProjectModal}
              >
                Add Project
              </Button>
            </Col>
            <Col span={isMobile ? 24 : 20} style={{ marginTop: 10 }}>
              <Form<ProjectsSearchFormData>
                layout="vertical"
                form={searchForm}
                onFinish={(values) => {
                  setSearchKey(values.key);
                  projects.length = 0;
                  getProjects(1, values.key, limit);
                }}
              >
                <Form.Item name="key">
                  <Input
                    size="large"
                    placeholder="Search ..."
                    prefix={<SearchOutlined />}
                    autoComplete="off"
                    allowClear
                  />
                </Form.Item>
              </Form>
            </Col>
          </>
        ) : (
          <>
            <Col span={isMobile ? 24 : 20}>
              <Form<ProjectsSearchFormData>
                layout="vertical"
                form={searchForm}
                onFinish={(values) => {
                  projects.length = 0;
                  getProjects(1, values.key, limit);
                }}
              >
                <Form.Item name="key">
                  <Input
                    size="large"
                    placeholder="Search ..."
                    prefix={<SearchOutlined />}
                    autoComplete="off"
                    allowClear
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col
              span={isMobile ? 24 : 4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                size="large"
                icon={<PlusCircleOutlined />}
                onClick={showCreateProjectModal}
              >
                Add Project
              </Button>
            </Col>
          </>
        )}
      </Row>

      <Table
        columns={columns}
        dataSource={projects}
        pagination={{
          onChange: (page, pageSize) => {
            setLimit(pageSize);
            getProjects(page, searchKey, pageSize);
          },
          total: total,
          pageSize: limit,
          position: ["bottomCenter", "topCenter"],
          showQuickJumper: false,
        }}
        scroll={{ x: true }}
        loading={loading}
      />

      <Modal
        title="Creating Project"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        width={"40vw"}
      >
        <CreateProjectFormComponent onFinish={onFinish} form={form} />
      </Modal>

      <Modal
        title="Editting Project Informations"
        visible={edittingModal}
        onOk={() => setEdittingModal(false)}
        onCancel={() => setEdittingModal(false)}
        footer={[]}
        width={"40vw"}
      >
        <EditProjectFormComponent
          onFinish={onFinishEdit}
          form={form}
          project={project}
        />
      </Modal>
    </>
  );
};
