/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";

import {
  AntdLayout,
  Menu,
  Grid,
  useTitle,
  useNavigation,
  Modal,
  Card,
  Space,
  Typography,
  Button,
} from "@pankod/refine";
import {
  antLayoutSider,
  antLayoutSiderMobile,
  menuItemColor,
  menuItemDiv,
} from "components/layout/sider/styles";
import {
  HomeOutlined,
  MoneyCollectFilled,
  MoneyCollectOutlined,
  OrderedListOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";
const { Text } = Typography;

export const Sider: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const Title = useTitle();
  const { push } = useNavigation();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isHowToPayModalVisible, setIsHowToPayModalVisible] = useState(false);

  const showHowToPayModal = () => {
    setIsHowToPayModalVisible(true);
  };

  const handleHowToPayOk = () => {
    setIsHowToPayModalVisible(false);
  };

  const handleHowToPayCancel = () => {
    setIsHowToPayModalVisible(false);
  };

  const showSupportContactModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <AntdLayout.Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
        collapsedWidth={isMobile ? 0 : 80}
        breakpoint="lg"
        style={isMobile ? antLayoutSiderMobile : antLayoutSider}
      >
        {Title && <Title collapsed={collapsed} />}
        <Menu
          selectedKeys={[]}
          mode="inline"
          onClick={({ key }) => {
            if (!breakpoint.lg) {
              setCollapsed(true);
            }
          }}
        >
          <Menu.Item
            style={menuItemColor}
            key={"/home"}
            icon={<HomeOutlined style={{ color: "orange" }} />}
            onClick={() => push("/home")}
          >
            <div style={menuItemDiv}>
              {"Home"}
              {collapsed && <HomeOutlined />}
            </div>
          </Menu.Item>
          <Menu.Item
            style={menuItemColor}
            key={"/returns"}
            icon={<OrderedListOutlined style={{ color: "orange" }} />}
            onClick={() => push("/returns")}
          >
            <div style={menuItemDiv}>
              {"VAT Returns"}
              {collapsed && <OrderedListOutlined />}
            </div>
          </Menu.Item>
          <Menu.Item
            style={menuItemColor}
            key={"/receipts"}
            icon={<MoneyCollectOutlined style={{ color: "orange" }} />}
            onClick={() => push("/receipts")}
          >
            <div style={menuItemDiv}>
              {"Receipts"}
              {collapsed && <MoneyCollectOutlined />}
            </div>
          </Menu.Item>
          <Menu.Item
            style={menuItemColor}
            key={"/users"}
            icon={<UserOutlined style={{ color: "orange" }} />}
            onClick={() => push("/users")}
          >
            <div style={menuItemDiv}>
              {"Members/Users"}
              {collapsed && <UserOutlined />}
            </div>
          </Menu.Item>
          <Menu.Item
            style={menuItemColor}
            key={"how_to_pay"}
            icon={<MoneyCollectFilled style={{ color: "orange" }} />}
            onClick={showHowToPayModal}
          >
            <div style={menuItemDiv}>
              {"How to Pay"}
              {collapsed && <PhoneOutlined />}
            </div>
          </Menu.Item>
          <Menu.Item
            style={menuItemColor}
            key={"support_contacts"}
            icon={<PhoneOutlined style={{ color: "orange" }} />}
            onClick={showSupportContactModal}
          >
            <div style={menuItemDiv}>
              {"Support Contacts"}
              {collapsed && <PhoneOutlined />}
            </div>
          </Menu.Item>
        </Menu>
      </AntdLayout.Sider>
      <Modal
        title="Support Contacts"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Card>
          <Space direction="vertical">
            <Text type="danger">Phone Numbers</Text>
            <Text type="success">0765242198</Text>
            <Text type="success">0717512739</Text>
            <Text type="success">0655520115</Text>

            <Text type="danger">Email Address</Text>
            <Text type="success">support@ericogroup.co.tz</Text>
          </Space>
        </Card>
      </Modal>

      <Modal
        title="How to Make Payments"
        visible={isHowToPayModalVisible}
        onOk={handleHowToPayOk}
        onCancel={handleHowToPayCancel}
        footer={[]}
        width={"50%"}
      >
        <Card>
          <Space direction="vertical">
            <Row>
              {/* <Col span={8}>
                <img src="images/lipa_number.jpeg" width={"60%"} />
              </Col> */}
              <Col span={24}>
                <Space direction="vertical">
                  {/* <Text style={{fontSize: 26}}>LIPA NUMBER: <span style={{fontWeight: "bolder"}}>5300864</span></Text>
                <Text style={{fontSize: 20}}>COMPANY NAME: <span style={{fontWeight: "bolder"}}>ERICO GROUP CO. LIMITED</span></Text> */}

                  <p>For Payment please contact us</p>

                  <Space direction="vertical">
                    <Text type="danger">Phone Numbers</Text>
                    <Text type="success">0765242198</Text>
                    <Text type="success">0717512739</Text>
                    <Text type="success">0655520115</Text>

                    <Text type="danger">Email Address</Text>
                    <Text type="success">support@ericogroup.co.tz</Text>
                  </Space>
                </Space>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button onClick={() => push("/upgrading")}>Upgrade Now</Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Modal>
    </>
  );
};
