import {
    Authenticated,
    LayoutWrapper
} from "@pankod/refine";
import { ControlHeader } from "components/layout/header/control_header";
import { CompaniesComponent } from "control/components/companies";
import { Analytics } from "../components/analytics";

const Applications: React.FC = () => {

    return (
        <div style={{marginTop: 10, marginLeft: 10, marginRight: 10}}>
            <Analytics />

            <CompaniesComponent height={"20vh"} />
        </div>
    );
};



export const ControlAnalyticsPage = () => {
    return (
      <Authenticated>
        <LayoutWrapper Sider={()=><></>} Header={()=><><ControlHeader /></>}>
            <Applications />
        </LayoutWrapper>
      </Authenticated>
    );
  };