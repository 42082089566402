/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  SearchOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  SyncOutlined,
  InfoOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Drawer,
  Form,
  Grid,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Tag,
  useNavigation,
} from "@pankod/refine";
import {Col, message, Row, Skeleton, Spin, Tooltip} from "antd";
import {gqlDataProvider} from "api";
import {CompanyOfficeData, ReceiptData, SessionData} from "interfaces";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {AddingReceiptsFormComponent} from "./forms/adding_receipt_form";
import {ScannerComponent} from "./scanner_component";
import ReactTimeAgo from "react-time-ago";
import {ReceiptInfoComponent} from "./receipt_info_component";
const {Option} = Select;

interface ReceiptsSearchFormData {
  key: string;
}

interface ReceiptsProps {
  height?: any;
  canAdd?: boolean;
}

export interface CreateReceiptForm {
  number: string;
}

export const ReturnReceiptsComponent: React.FC<ReceiptsProps> = (
  props: ReceiptsProps
) => {
  const [offices, setOffices] = useState<CompanyOfficeData[]>([]);
  const [selectedOffices, setSelectedOffices] = useState<any[]>([]);
  const [session, setSession] = useState<SessionData>();
  const [receipts, setReceipts] = useState<ReceiptData[]>([]);
  const [receipt, setReceipt] = useState<ReceiptData | null>();
  const [devices, setDevices] = useState<any[]>([]);
  const [deviceIndex, setDeviceIndex] = useState(0);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [hasNext, setHasNext] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sessionLoading, setSessionLoading] = useState(true);
  const [webScann, setWebScann] = useState(false);
  const {push} = useNavigation();
  const [searchForm] = Form.useForm<ReceiptsSearchFormData>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [receiptInfoModal, setReceiptInfoModal] = useState(false);
  const [form] = Form.useForm<CreateReceiptForm>();
  const params: any = useParams();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;

  const showReceiptInfo = (receipt: ReceiptData) => {
    setReceipt(receipt);
    setReceiptInfoModal(true);
  };

  const showCreateVATReturnModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const scanByWebCam = () => {
    setWebScann(true);
  };

  const desktopScanner = () => {
    setWebScann(false);
  };

  const onFinish = async (receipt: any) => {
    setReceipts([receipt, ...receipts]);
  };

  const syncTraReceipt = async (id: number) => {
    message.destroy();
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "syncTraReceipt",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          "data",
          {
            receipt: [
              "id",
              "number",
              "description",
              "totalInclOfTax",
              "supplierName",
              "customerName",
              "customerId",
              "customerMobile",
              "mobile",
              "zNumber",
              "receiptTime",
              "receiptDate",
              "taxRateA",
              "totalExclOfTax",
              "totalTax",
              "tin",
              "vrn",
              "uin",
              "synced",
              {
                author: ["id", "fullName"],
              },
              {
                office: ["id", "name"],
              },
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
              {
                session: ["id"],
              },
            ],
          },
        ],
      },
    });
    if (data.success) {
      message.success(data.message);
      setReceipts(
        receipts.map((receipt) => {
          if (receipt.id === id) {
            return data.receipt;
          }
          return receipt;
        })
      );
    } else {
      message.error(data.message);
    }
  };

  const confirmDelete = async (id: number) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "deleteReceipt",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
      setReceipts(receipts.filter((receipt) => receipt.id !== id));
    } else {
      message.error(data.message);
    }
  };

  const columns = [
    {
      title: "Receipt#",
      dataIndex: "number",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <a onClick={() => showReceiptInfo(row)}>{text}</a>
      ),
    },
    {
      title: "Supplier",
      dataIndex: "supplierName",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <span>
          {row.supplierName ? (
            <Tooltip title={text}>
              {text && text.length > 75 ? text.substring(0, 75) + "..." : text}
            </Tooltip>
          ) : (
            "----"
          )}
        </span>
      ),
    },
    {
      title: "TIN/VRN",
      dataIndex: "tin",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <span>
          {text}/{row.vrn}
        </span>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text: any, row: any, index: any) => (
        <span>
          <span style={{display: "block"}}>
            <Tooltip title={text}>
              {text && text.length > 75 ? text.substring(0, 75) + "..." : text}
            </Tooltip>
          </span>
          <Tag color="green">Scanned By: {row.author.fullName}</Tag>
          {row?.office && <Tag color="orange">{row?.office?.name}</Tag>}
        </span>
      ),
    },
    {
      title: (
        <>
          <span style={{float: "right"}}>Amount</span>
        </>
      ),
      dataIndex: "totalInclOfTax",
      fixed: true,
      render: (text: any, row: any, index: any) => {
        return <span style={{float: "right"}}>{text}</span>;
      },
    },
    {
      title: "Synced",
      dataIndex: "synced",
      fixed: true,
      render: (text: any, row: any, index: any) => {
        if (row.synced) {
          return <Tag color={"green"}>Synced</Tag>;
        }
        return <Tag color={"red"}>Not Synced</Tag>;
      },
    },
    {
      title: "Scanned At",
      dataIndex: "created",
      render: (text: any, row: any, index: any) => (
        <ReactTimeAgo date={new Date(text)} />
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      align: "right" as "right",
      render: (text: any, row: any, index: any) => (
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Popconfirm
            title={"Are you sure to delete this " + row?.number + " Receipt?"}
            onConfirm={() => confirmDelete(row?.id)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
            disabled={!row?.canDelete}
          >
            <Button
              icon={<DeleteOutlined />}
              disabled={!row?.canDelete}
            ></Button>
          </Popconfirm>
          <Tooltip title="Sync TRA Receipt Data" color="green">
            <Button
              icon={<SyncOutlined />}
              disabled={!row?.canManage}
              onClick={() => syncTraReceipt(row?.id)}
            ></Button>
          </Tooltip>
          <Tooltip title="Click to View Receipt Informations" color="green">
            <Button
              icon={<InfoCircleFilled />}
              disabled={!row?.canManage}
              onClick={() => showReceiptInfo(row)}
            ></Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const getReturnSession = async (id: number) => {
    message.destroy();
    setSessionLoading(true);
    const {data} = await gqlDataProvider.custom!<SessionData | any>({
      url: "",
      method: "get",
      metaData: {
        operation: "session",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: [
          "id",
          "name",
          "returnSessionType",
          "returnSessionTypeId",
          "totalReceipts",
          "startDate",
          "endDate",
          "created",
          "updated",
          "isActive",
          "canEdit",
          "canDelete",
          "canManage",
          "canScan",
          "locked",
          {
            company: ["id"],
          },
          {
            author: ["id", "fullName"],
          },
        ],
      },
    });
    setSessionLoading(false);
    setSession(data);
  };

  const syncSessionReceipts = async () => {
    message.destroy();
    await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "syncSessionReceipts",
        variables: {
          sessionId: {
            value: session?.id,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    })
      .catch((error) => {
        message.error("Something Went Wrong .....");
      })
      .then((data) => {
        if (data?.data?.success) {
          message.success(data?.data?.message);
        } else {
          message.error(data?.data?.message);
        }
      });
  };

  const getReceipts = async (
    start: number,
    key: string = "",
    limit: number = 20
  ) => {
    message.destroy();
    setLoading(true);
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "sessionReceipts",
        variables: {
          start: {value: start, type: "Int", required: true},
          limit: {value: limit, type: "Int", required: true},
          sessionId: {
            value: params.id,
            type: "Int",
            required: true,
          },
          offices: {
            value: selectedOffices,
            type: "[String]",
            required: false,
          },
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: [
              "id",
              "number",
              "description",
              "totalInclOfTax",
              "supplierName",
              "customerName",
              "tin",
              "vrn",
              "synced",
              {
                author: ["id", "fullName"],
              },
              {
                office: ["id", "name"],
              },
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
              {
                session: ["id"],
              },
            ],
          },
        ],
      },
    });
    if (data.pages !== pages) {
      setPages(data.pages);
    }
    if (data.total !== total) {
      setTotal(data.total);
    }
    if (data.hasNext !== hasNext) {
      setHasNext(data.hasNext);
    }
    setReceipts([...data.results]);
    setLoading(false);
  };

  const getOffices = async (
    start: number,
    key: string = "",
    limit: number = 20
  ) => {
    message.destroy();
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "companyOffices",
        variables: {
          start: {value: start, type: "Int", required: true},
          limit: {value: limit, type: "Int", required: true},
          companyId: {
            value: localStorage.getItem("companyid"),
            type: "Int",
            required: true,
          },
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: ["id", "name"],
          },
        ],
      },
    });
    setOffices([...data.results]);
  };

  useEffect(() => {
    getReturnSession(params.id);
    getOffices(1);
    getReceipts(1);
    try {
      navigator.mediaDevices.enumerateDevices().then(gotDevices);
    } catch (error) {
      console.log("Browser not supported");
    }
  }, []);

  function gotDevices(mediaDevices: any[]) {
    var dv: any = [];
    mediaDevices.forEach(function (mediaDevice) {
      if (mediaDevice.kind === "videoinput") {
        dv.push({
          deviceId: mediaDevice.deviceId,
          label: mediaDevice.label,
          kind: mediaDevice.kind,
          groupId: mediaDevice.groupId,
        });
      }
    });
    setDevices(dv);
  }

  const handleChange = (value: string[]) => {
    setSelectedOffices(value);
  };

  function filterComponent() {
    return (
      <>
        <Select
          mode="multiple"
          size="large"
          allowClear
          style={{width: "100%"}}
          placeholder="Please Branches/Offices to Filter"
          defaultValue={[]}
          onChange={handleChange}
        >
          {offices.map((office) => (
            <Option key={office.id.toString(36)}>{office.name}</Option>
          ))}
        </Select>
      </>
    );
  }

  return (
    <>
      <Row style={{marginTop: 10}}>
        {isMobile ? (
          <>
            {props.canAdd ? (
              <Col
                span={24}
                style={{display: "flex", justifyContent: "flex-end"}}
              >
                <Button
                  size="large"
                  icon={<PlusCircleOutlined />}
                  onClick={showCreateVATReturnModal}
                  loading={sessionLoading}
                >
                  Add Receipt
                </Button>
              </Col>
            ) : (
              <></>
            )}
            <Col span={24} style={{marginTop: 10}}>
              <Form<ReceiptsSearchFormData>
                layout="vertical"
                form={searchForm}
                onFinish={(values) => {
                  setSearchKey(values.key);
                  getReceipts(0, values.key);
                }}
              >
                <Form.Item name="key">
                  <Input
                    size="large"
                    placeholder="Search ..."
                    prefix={<SearchOutlined />}
                    autoComplete="off"
                    allowClear
                  />
                </Form.Item>
              </Form>
            </Col>
          </>
        ) : (
          <>
            <Col span={10}>
              <Form<ReceiptsSearchFormData>
                layout="vertical"
                form={searchForm}
                onFinish={(values) => {
                  getReceipts(1, values.key);
                }}
              >
                <Form.Item name="key">
                  <Input
                    size="large"
                    placeholder="Search ..."
                    prefix={<SearchOutlined />}
                    autoComplete="off"
                    allowClear
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col span={6}>{filterComponent()}</Col>
            <Col span="2">
              <Button
                icon={<SearchOutlined />}
                size="large"
                onClick={() => searchForm.submit()}
              ></Button>
            </Col>
            {props.canAdd ? (
              <Col
                span={6}
                style={{display: "flex", justifyContent: "flex-end"}}
              >
                <Button
                  size="large"
                  icon={<PlusCircleOutlined />}
                  onClick={showCreateVATReturnModal}
                  loading={sessionLoading}
                  disabled={sessionLoading || session?.locked}
                >
                  Add Receipt
                </Button>
                <Tooltip title="Perform Global Synchronization of Data From TRA for Receipts that didn't Successfully Fetched the Information Successfully">
                  <Popconfirm
                    title={
                      <>
                        <div style={{width: "350px", fontSize: "18px"}}>
                          Synchronization of Data From TRA over all failed
                          receipts will be performed
                        </div>
                      </>
                    }
                    onConfirm={syncSessionReceipts}
                    onCancel={() => {}}
                    okText="Start Synchronization"
                    cancelText="No"
                  >
                    <Button
                      size="large"
                      icon={<SyncOutlined />}
                      loading={sessionLoading}
                      disabled={sessionLoading || session?.locked}
                    >
                      Sync
                    </Button>
                  </Popconfirm>
                </Tooltip>
              </Col>
            ) : (
              <></>
            )}
          </>
        )}
      </Row>

      <Table
        columns={columns}
        dataSource={receipts}
        pagination={{
          onChange: (page, pageSize) => {
            setLimit(pageSize);
            getReceipts(page, searchKey, pageSize);
          },
          total: total,
          pageSize: limit,
          position: ["bottomCenter"],
          showQuickJumper: false,
        }}
        scroll={{x: true, y: "69.6vh"}}
        loading={loading}
      />

      <Modal
        title="Add Receipts"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        width={isMobile ? "100vw" : "30vw"}
      >
        {webScann && devices.length > 0 ? (
          <ScannerComponent
            deviceId={devices[deviceIndex].deviceId}
            groupId={devices[deviceIndex].groupId}
            onFinish={onFinish}
          />
        ) : (
          <AddingReceiptsFormComponent onFinish={onFinish} />
        )}

        {!isMobile ? (
          <></>
        ) : webScann ? (
          <div style={{marginTop: 20}}>
            <Button onClick={desktopScanner}>Back To Desktop Scanner</Button>
          </div>
        ) : (
          <Button onClick={scanByWebCam}>Cammera Scanner</Button>
        )}
      </Modal>

      <Drawer
        title={"Receipt Informations #" + receipt?.number}
        placement="right"
        width={"40vw"}
        onClose={() => {
          setReceiptInfoModal(false);
          setReceipt(null);
        }}
        visible={receiptInfoModal}
      >
        <ReceiptInfoComponent id={receipt?.id} receipt={receipt} />
      </Drawer>
    </>
  );
};
