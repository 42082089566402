/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  SearchOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  ExportOutlined,
  EditOutlined,
  MoreOutlined,
  FolderViewOutlined,
  LockOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Table,
  Tag,
  useNavigation,
} from "@pankod/refine";
import {Col, message, notification, Row, Tooltip} from "antd";
import {gqlDataProvider} from "api";
import {SessionData} from "interfaces";
import {useEffect, useState} from "react";
import ReactTimeAgo from "react-time-ago";
import {CreateVATReturnFormComponent} from "./forms/create_vat_return_form";
import type {NotificationPlacement} from "antd/lib/notification";
import React from "react";
import {EditVATReturnFormComponent} from "./forms/edit_vat_return__form";

const Context = React.createContext({name: "Default"});

interface ReturnsSearchFormData {
  key: string;
}

interface ReturnsProps {
  height?: any;
  randKey?: any;
}

export interface CreateVATReturnForm {
  name: string;
  session_type: string;
  start_date: string;
  end_date: string;
}

export const ReturnsComponent: React.FC<ReturnsProps> = (
  props: ReturnsProps
) => {
  const [api, contextHolder] = notification.useNotification();
  const [vatReturn, setVatReturn] = useState<SessionData>();
  const [returns, setReturns] = useState<SessionData[]>([]);
  const [limit, setLimit] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [total, setTotal] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [loading, setLoading] = useState(true);
  const {push} = useNavigation();
  const [searchForm] = Form.useForm<ReturnsSearchFormData>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [form] = Form.useForm<CreateVATReturnForm>();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;

  const showEditVATReturnMoal = (vatReturn: SessionData) => {
    setVatReturn(vatReturn);
    setIsEditModalVisible(true);
  };

  const showCreateVATReturnModal = () => {
    setIsModalVisible(true);
  };

  const handleEditOk = () => {
    setIsEditModalVisible(false);
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values: any) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "createReturnSession",
        variables: {
          companyId: {
            value: localStorage.getItem("companyid"),
            type: "Int",
            required: true,
          },
          name: {value: values.name, type: "String", required: true},
          sessionType: {
            value:
              values.session_type.length > 0 ? values.session_type : "local",
            type: "String",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          {
            returnSession: [
              "id",
              "name",
              "returnSessionType",
              "totalReceipts",
              "startDate",
              "endDate",
              {
                author: ["id", "fullName"],
              },
              "created",
              "updated",
              "isActive",
              "locked",
              "canEdit",
              "canDelete",
              "canManage",
              "canScan",
              {
                company: ["id"],
              },
            ],
          },
        ],
      },
    });
    if (data.success) {
      message.success(data.message);
      setIsModalVisible(false);
      setReturns([data.returnSession, ...returns]);
      form.resetFields();
    } else {
      message.error(data.message);
    }
  };

  const onEditFinish = async (values: any) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "updateReturnSession",
        variables: {
          id: {
            value: values.id,
            type: "Int",
            required: true,
          },
          name: {value: values.name, type: "String", required: true},
          sessionType: {
            value:
              values.session_type.length > 0 ? values.session_type : "local",
            type: "String",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          {
            returnSession: [
              "id",
              "name",
              "returnSessionType",
              "returnSessionTypeId",
              "totalReceipts",
              "startDate",
              "endDate",
              "created",
              "updated",
              "isActive",
              "locked",
              {
                author: ["id", "fullName"],
              },
              "canEdit",
              "canDelete",
              "canManage",
              "canScan",
              {
                company: ["id"],
              },
            ],
          },
        ],
      },
    });
    if (data.success) {
      message.success(data.message);
      setIsEditModalVisible(false);
      setReturns(
        returns.map((returnSession: SessionData) => {
          if (returnSession.id.toString() === values.id.toString()) {
            return data.returnSession;
          }
          return returnSession;
        })
      );
      form.resetFields();
    } else {
      message.error(data.message);
    }
  };

  const confirmDelete = async (id: number) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "deleteReturnSession",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
      setReturns(returns.filter((returnSession) => returnSession.id !== id));
    } else {
      message.error(data.message);
    }
  };

  const confirmDownload = async (id: number) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "createReturnDocument",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          {
            session: [
              "id",
              "name",
              "startDate",
              "endDate",
              "created",
              "updated",
              "canEdit",
              "canDelete",
              "isActive",
              "locked",
              {
                author: ["id", "fullName"],
              },
              "docFile",
              "doc",
            ],
          },
        ],
      },
    });

    if (data.success) {
      message.success(data.message);
      window.open(data.session.doc, "_blank");
    } else {
      message.error(data.message);
    }
  };

  const confirmDownloadVerified = async (id: number) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "createReturnDocumentVerified",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          {
            session: [
              "id",
              "name",
              "startDate",
              "endDate",
              "created",
              "updated",
              "canEdit",
              "canDelete",
              "isActive",
              "docFile",
              "locked",
              {
                author: ["id", "fullName"],
              },
              "doc",
            ],
          },
        ],
      },
    });

    if (data.success) {
      message.success(data.message);
      window.open(data.session.doc, "_blank");
    } else {
      message.error(data.message);
    }
  };

  const confirmDownloadAll = async (id: number) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "createReturnAllDocument",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          {
            session: [
              "id",
              "name",
              "startDate",
              "endDate",
              "created",
              "updated",
              "canEdit",
              "canDelete",
              "isActive",
              "docFile",
              "doc",
              "locked",
              {
                author: ["id", "fullName"],
              },
            ],
          },
        ],
      },
    });

    if (data.success) {
      message.success(data.message);
      window.open(data.session.doc, "_blank");
    } else {
      message.error(data.message);
    }
  };

  const lockUnlockReturnSession = async (id: number, lock: boolean) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "lockUnlockReturnSession",
        variables: {
          sessionId: {
            value: id,
            type: "Int",
            required: true,
          },
          lock: {
            value: lock,
            type: "Boolean",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          {
            session: [
              "id",
              "name",
              "returnSessionType",
              "returnSessionTypeId",
              "totalReceipts",
              "startDate",
              "endDate",
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
              "canScan",
              "locked",
              {
                company: ["id"],
              },
              {
                author: ["id", "fullName"],
              },
            ],
          },
        ],
      },
    });

    if (data.success) {
      setReturns(
        returns.map((returnSession: SessionData) => {
          if (returnSession.id === id) {
            return data.session;
          }
          return returnSession;
        })
      );
      message.success(data.message);
    } else {
      message.error(data.message);
    }
  };

  const getReturns = async (
    start: number,
    key: string = "",
    limit: number = 20
  ) => {
    setLoading(true);
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "sessions",
        variables: {
          start: {value: start, type: "Int", required: true},
          limit: {value: limit, type: "Int", required: true},
          companyId: {
            value: localStorage.getItem("companyid"),
            type: "Int",
            required: true,
          },
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: [
              "id",
              "name",
              "returnSessionType",
              "returnSessionTypeId",
              "totalReceipts",
              "startDate",
              "endDate",
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
              "canScan",
              "locked",
              {
                company: ["id"],
              },
              {
                author: ["id", "fullName"],
              },
            ],
          },
        ],
      },
    });
    if (data.total !== total) {
      setTotal(data.total);
    }
    if (data.hasNext !== hasNext) {
      setHasNext(data.hasNext);
    }
    setReturns([...data.results]);
    setLoading(false);
  };

  const openNotification = (msg: String, placement: NotificationPlacement) => {
    api.info({
      message: `Notification`,
      description: msg,
      placement,
    });
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <a
          onClick={() => {
            if (row.canScan) {
              push("/returns/" + row?.id + "/receipts");
            } else {
              openNotification(
                "Receipt Scanning on This Return Category is not supported yet",
                "bottomRight"
              );
            }
          }}
          style={{color: row.canScan ? "green" : "red"}}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Return Type",
      key: "return_session_type",
      dataIndex: "return_session_type",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <span style={{color: row.canScan ? "green" : "red"}}>
          {row.returnSessionType}
        </span>
      ),
    },
    {
      title: "Created By",
      key: "createdby",
      dataIndex: "createdby",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <Tag color="green">{row.author.fullName}</Tag>
      ),
    },
    {
      title: "Total Scanned Receipts",
      dataIndex: "totalReceipts",
    },
    {
      title: "Locked",
      dataIndex: "locked",
      render: (text: any, row: any, index: any) => (
        <span>
          {row.locked ? (
            <>
              <Popconfirm
                disabled={!row.canManage}
                title="Click to Unlock this Return Session, which it will allow scanning of Receipt in this Return Session?"
                onConfirm={() => lockUnlockReturnSession(row.id, false)}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <Tag color="red">Locked</Tag>
                </a>
              </Popconfirm>
            </>
          ) : (
            <>
              <Popconfirm
                disabled={!row.canManage}
                title="Click to Lock this Return Session, which will disable scanning of receipts in this session?"
                onConfirm={() => lockUnlockReturnSession(row.id, true)}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <Tag color="green">Not Locked</Tag>
                </a>
              </Popconfirm>
            </>
          )}
        </span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "created",
      key: "created",
      render: (text: any, row: any, index: any) => (
        <span>
          <ReactTimeAgo date={row.created} locale="en-US" tick={true} />
        </span>
      ),
    },
    {
      title: "Actions",
      align: "right" as "right",
      dataIndex: "action",
      style: {display: "flex", justifyContent: "flex-end"},
      render: (text: any, row: any, index: any) => (
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                <Menu.Item
                  icon={<FolderViewOutlined style={{color: "green"}} />}
                >
                  <a
                    rel="noopener noreferrer"
                    onClick={() => {
                      if (row.canScan) {
                        push("/returns/" + row?.id + "/receipts");
                      } else {
                        openNotification(
                          "Receipt Scanning on This Return Category is not supported yet",
                          "bottomRight"
                        );
                      }
                    }}
                  >
                    View Receipts
                  </a>
                </Menu.Item>
                {row.locked ? (
                  <>
                    <Menu.Item
                      icon={<LockOutlined style={{color: "green"}} />}
                      disabled={!row.canManage}
                    >
                      <a
                        rel="noopener noreferrer"
                        onClick={() => lockUnlockReturnSession(row.id, false)}
                      >
                        Unlock Return Session
                      </a>
                    </Menu.Item>
                  </>
                ) : (
                  <>
                    <Menu.Item
                      icon={<LockOutlined style={{color: "green"}} />}
                      disabled={!row.canManage}
                    >
                      <a
                        rel="noopener noreferrer"
                        onClick={() => lockUnlockReturnSession(row.id, true)}
                      >
                        Lock Return Session
                      </a>
                    </Menu.Item>
                  </>
                )}
                <Menu.Item icon={<EditOutlined style={{color: "green"}} />}>
                  <a
                    rel="noopener noreferrer"
                    onClick={() => showEditVATReturnMoal(row)}
                  >
                    Edit
                  </a>
                </Menu.Item>
                <Menu.Item icon={<DeleteOutlined style={{color: "green"}} />}>
                  <Tooltip title="Delete Return Session">
                    <Popconfirm
                      title={
                        "Are you sure to delete this " +
                        row?.name +
                        " VAT Return?"
                      }
                      onConfirm={() => confirmDelete(row?.id)}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                      disabled={!row?.canDelete}
                    >
                      <a
                        target="_blank"
                        href={""}
                        rel="noopener noreferrer"
                        download
                      >
                        Delete
                      </a>
                    </Popconfirm>
                  </Tooltip>
                </Menu.Item>
                <Menu.Item icon={<ExportOutlined style={{color: "green"}} />}>
                  <Tooltip title="Export Data Compatible with TRA Excel Format (Minimum Data)">
                    <a
                      rel="noopener noreferrer"
                      onClick={() => confirmDownload(row?.id)}
                    >
                      Export Excel
                    </a>
                  </Tooltip>
                </Menu.Item>

                <Menu.Item icon={<ExportOutlined style={{color: "green"}} />}>
                  <Tooltip title="Export Verified Data Compatible with TRA Excel Format (Minimum Data)">
                    <a
                      rel="noopener noreferrer"
                      onClick={() => confirmDownloadVerified(row?.id)}
                    >
                      Export Excel (Verified)
                    </a>
                  </Tooltip>
                </Menu.Item>

                <Menu.Item icon={<ExportOutlined style={{color: "green"}} />}>
                  <Tooltip title="Export All Receipt Data (Plus All synced from TRA)">
                    <a
                      rel="noopener noreferrer"
                      onClick={() => confirmDownloadAll(row?.id)}
                    >
                      Export Full Data Excel
                    </a>
                  </Tooltip>
                </Menu.Item>
              </Menu>
            }
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <MoreOutlined
                style={{
                  color: "orange",
                  fontSize: 28,
                  fontWeight: "bold",
                }}
              />
            </a>
          </Dropdown>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getReturns(1, "", 20);
  }, [props.randKey]);

  return (
    <>
      <Context.Provider value={{name: "Efeeder"}}>
        {contextHolder}
        <Row style={{marginTop: 10}}>
          {isMobile ? (
            <>
              <Col
                span={24}
                style={{display: "flex", justifyContent: "flex-end"}}
              >
                <Button
                  size="large"
                  icon={<PlusCircleOutlined />}
                  onClick={showCreateVATReturnModal}
                >
                  Create VAT Return
                </Button>
              </Col>
              <Col span={24} style={{marginTop: 10}}>
                <Form<ReturnsSearchFormData>
                  layout="vertical"
                  form={searchForm}
                  onFinish={(values) => {
                    setSearchKey(values.key);
                    getReturns(0, values.key, limit);
                  }}
                >
                  <Form.Item name="key">
                    <Input
                      size="large"
                      placeholder="Search ..."
                      prefix={<SearchOutlined />}
                      autoComplete="off"
                      allowClear
                    />
                  </Form.Item>
                </Form>
              </Col>
            </>
          ) : (
            <>
              <Col span={20}>
                <Form<ReturnsSearchFormData>
                  layout="vertical"
                  form={searchForm}
                  onFinish={(values) => {
                    getReturns(0, values.key, limit);
                  }}
                >
                  <Form.Item name="key">
                    <Input
                      size="large"
                      placeholder="Search ..."
                      prefix={<SearchOutlined />}
                      autoComplete="off"
                      allowClear
                    />
                  </Form.Item>
                </Form>
              </Col>
              <Col
                span={4}
                style={{display: "flex", justifyContent: "flex-end"}}
              >
                <Button
                  size="large"
                  icon={<PlusCircleOutlined />}
                  onClick={showCreateVATReturnModal}
                >
                  Create VAT Return
                </Button>
              </Col>
            </>
          )}
        </Row>

        <Table
          columns={columns}
          dataSource={returns}
          pagination={{
            onChange: (page, pageSize) => {
              setLimit(pageSize);
              getReturns(page, searchKey, pageSize);
            },
            total: total,
            pageSize: limit,
            position: ["bottomCenter"],
            showQuickJumper: false,
          }}
          scroll={{x: true}}
          loading={loading}
        />

        <Modal
          title="Create VAT Return"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[]}
          width={isMobile ? "100vw" : "40vw"}
        >
          <CreateVATReturnFormComponent onFinish={onFinish} form={form} />
        </Modal>

        <Modal
          title="Edit VAT Return"
          visible={isEditModalVisible}
          onOk={() => handleEditOk()}
          onCancel={() => handleEditCancel()}
          footer={[]}
          width={isMobile ? "100vw" : "40vw"}
        >
          <EditVATReturnFormComponent
            randKey={Math.random()}
            onFinish={onEditFinish}
            form={form}
            vatReturn={vatReturn}
          />
        </Modal>
      </Context.Provider>
    </>
  );
};
