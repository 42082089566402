import { Button, Form, Select } from "@pankod/refine";
import { Col, Row, InputNumber } from "antd";
import { gqlDataProvider } from "api";
import { PaymentPackageData } from "interfaces";
import { useEffect, useState } from "react";

const { Option } = Select;
interface createCompanyBillingProps {
  form?: any;
  onFinish?: any;
}

export const CreateCompanyBillingFormComponent: React.FC<
  createCompanyBillingProps
> = (props: createCompanyBillingProps) => {
  const [packages, setPackages] = useState<PaymentPackageData[]>([]);
  const [loading, setLoading] = useState(false);
  const [durationType, setDurationType] = useState("years");

  const getPackages = async (
    start: number = 1,
    key: string = "",
    limit: number = 20
  ) => {
    setLoading(true);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "packages",
        variables: {
          start: { value: start, type: "Int", required: true },
          limit: { value: limit, type: "Int", required: false },
          key: { value: key, type: "String", required: false },
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: [
              "id",
              "name",
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
            ],
          },
        ],
      },
    });
    setPackages(data.results);
    setLoading(false);
  };

  useEffect(() => {
    getPackages(1, "", 20);
  }, []);

  return (
    <>
      <Form
        layout="vertical"
        form={props.form}
        onFinish={(values) => {
          values.durationType = durationType;
          props.onFinish(values);
        }}
        requiredMark={false}
      >
        <Form.Item
          name="packageId"
          label="Payment Package"
          rules={[
            { required: true, message: "Please Select Payment Package ..." },
          ]}
        >
          <Select size="large" defaultValue="" onChange={() => {}} loading={loading}>
            <Option value="">Please Choose Package ...</Option>
            {packages.map((item) => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="amount"
          label="Amount"
          rules={[{ required: true, message: "Please input amount" }]}
        >
          <InputNumber size="large" min={1} onChange={() => {}} style={{ width: "100%" }} />
        </Form.Item>

        <Row>
          <Col span={24}>
            <Form.Item
              name="duration"
              label="Duration"
              rules={[
                {
                  required: true,
                  type: "number",
                  message: "Please input Duration",
                },
              ]}
            >
              <InputNumber
                min={1}
                size="large"
                onChange={() => {}}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>



        <Row>
          <Col span={24}>
            <Form.Item
              name="totalUsers"
              label="Maximum Users"
              initialValue={2}
              rules={[
                {
                  required: true,
                  type: "number",
                  message: "Please input Maximum Users",
                },
              ]}
            >
              <InputNumber
                min={1}
                size="large"
                onChange={() => {}}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              name="durationType"
              label="Duration Type"
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "Please input Duration",
                },
              ]}
              initialValue={"years"}
            >
              <Select
              size="large"
                defaultValue="years"
                style={{ width: "100%" }}
                onChange={setDurationType}
              >
                <Option value="years">Year(s)</Option>
                <Option value="months">Month(s)</Option>
                <Option value="days">Day(s)</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
