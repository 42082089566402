/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Authenticated,
    Breadcrumb,
    LayoutWrapper,
    useNavigation,
  } from "@pankod/refine";
  import { ControlHeader } from "components/layout/header/control_header";
  import { CompaniesComponent } from "control/components/companies";
import { ContactsComponent } from "control/components/contacts";
  
  const Contacts: React.FC = () => {
    const { push } = useNavigation();
  
    return (
      <div style={{ marginTop: 10, marginRight: 10, marginLeft: 10 }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a onClick={() => push("/control/analytics")}>Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a>Contacts</a>
          </Breadcrumb.Item>
        </Breadcrumb>
        <ContactsComponent height={77} />
      </div>
    );
  };
  
  export const ContactsPage = () => {
    return (
      <Authenticated>
        <LayoutWrapper
          Sider={() => <></>}
          Header={() => (
            <>
              <ControlHeader />
            </>
          )}
        >
          <Contacts />
        </LayoutWrapper>
      </Authenticated>
    );
  };
  