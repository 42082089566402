/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Authenticated,
  Breadcrumb,
  LayoutWrapper,
  useNavigation,
} from "@pankod/refine";
import { ControlHeader } from "components/layout/header/control_header";
import { ControlReceiptsSyncedComponent } from "control/components/receipts_synced_component";

const ReceiptsSynced: React.FC = () => {
  const { push } = useNavigation();

  return (
    <div style={{ marginTop: 10, marginRight: 10, marginLeft: 10 }}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a onClick={() => push("/control/analytics")}>Home</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a>Receipts</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <ControlReceiptsSyncedComponent height={77} />
    </div>
  );
};

export const ReceiptsSyncedControlPage = () => {
  return (
    <Authenticated>
      <LayoutWrapper
        Sider={() => <></>}
        Header={() => (
          <>
            <ControlHeader />
          </>
        )}
      >
        <ReceiptsSynced />
      </LayoutWrapper>
    </Authenticated>
  );
};
