/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, useNavigation } from "@pankod/refine";
import { Button, Spin, Tag } from "antd";
import { gqlDataProvider } from "api";
import { CompanyData } from "interfaces";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";

export interface CreateReceiptForm {
  number: string;
}

export const ServiceExpireComponent: React.FC = () => {
  const [company, setCompany] = useState<CompanyData>();
  const [loading, setLoading] = useState(true);
  const breakpoint = Grid.useBreakpoint();

  const isMobile = !breakpoint.lg;
  const { push } = useNavigation();

  const getCompany = async () => {
    const { data } = await gqlDataProvider.custom!<CompanyData | any>({
      url: "",
      method: "get",
      metaData: {
        operation: "company",
        variables: {
          id: {
            value: localStorage.getItem("companyid"),
            type: "Int",
            required: true,
          },
        },
        fields: [
          "id",
          "name",
          "shortName",
          "tin",
          "vrn",
          "trial",
          "expire",
          "expired",
          "isActive",
          "created",
          "updated",
        ],
      },
    });
    setCompany(data);
    setLoading(false);
  };

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <>
      <Spin spinning={loading}>
        <span style={{ fontSize: isMobile ? 16 : 22 }}>
          {company?.trial ? (
            <span>Your are using Trial Version, Trial Will Expire </span>
          ) : (
            <span>Service Will Expire </span>
          )}
          <ReactTimeAgo date={company?.expire ? company?.expire : new Date()} />
          <Tag color={"green"} style={{ marginLeft: 20 }}>
            {company?.expire}
          </Tag>
        </span>

        {(company?.expired || company?.trial) && <Button onClick={()=>push("/upgrading")}>Upgrade Now</Button>}
      </Spin>
    </>
  );
};
