/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input } from "@pankod/refine";
import { Select } from "antd";
import { useEffect } from "react";
const { Option } = Select;

interface editOfficeProps {
  randKey?: any;
  form?: any;
  onFinish?: any;
  office?: any;
}

export const EditOfficeFormComponent: React.FC<editOfficeProps> = (
  props: editOfficeProps
) => {
  useEffect(() => {
    props.form.setFieldsValue({
        name: props.office.name,
    });
  }, [props.randKey]);

  return (
    <>
     <Form
        layout="vertical"
        form={props.form}
        onFinish={(values) => {
          props.onFinish(values);
        }}
        requiredMark={false}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, type: "string" }]}
        >
          <Input size="large" placeholder="Enter Name ..." />
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Update
          </Button>
        </div>
      </Form>
    </>
  );
};
