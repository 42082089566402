/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input } from "@pankod/refine";
import { message, Select } from "antd";
import { gqlDataProvider } from "api";
import { SessionType } from "interfaces";
import { useEffect, useState } from "react";
const { Option } = Select;
interface createVATReturnProps {
  form?: any;
  onFinish?: any;
}

export const CreateVATReturnFormComponent: React.FC<createVATReturnProps> = (
  props: createVATReturnProps
) => {
  const [sessionType, setSessionType] = useState("");
  const [sessionTypes, setSessionTypes] = useState<SessionType[]>([]);

  
  const getSessionTypes = async () => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "sessionChoices",
        variables: {},
        fields: [
          "choices",
        ],
      },
    });
    setSessionTypes(data.choices);
  };


  const onSessionTypeChange = async (value: string) => {
    setSessionType(value);
  }

  useEffect(() => {
    getSessionTypes();
  }, []);

  
  return (
    <>
      <Form
        layout="vertical"
        form={props.form}
        onFinish={(values) => {
          values.session_type = sessionType;
          props.onFinish(values);
        }}
        requiredMark={false}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, type: "string" }]}
        >
          <Input size="large" placeholder="Enter Name ..." />
        </Form.Item>

        <Form.Item
        name="session_type"
        label="Session Type">
          <Select 
          size="large"
          defaultValue={"local"} style={{ width: "100%" }} 
          placeholder="Select Session Type"
          onChange={onSessionTypeChange}>
            {sessionTypes.map((sessionType: SessionType) => (<Option value={sessionType.id}>{sessionType.name}</Option>))}
          </Select>
        </Form.Item>
        

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
