import {AuthProvider, message, Refine, useNavigation} from "@pankod/refine";
import routerProvider from "@pankod/refine-react-router";
import "@pankod/refine/dist/styles.min.css";

import {Header, Layout, OffLayoutArea} from "components/layout";
import {Register} from "auth/register";
import {ForgotPassword} from "auth/forgot_password";
import {RecoverPassword} from "auth/recover_password";
import {Login} from "auth/login";
import {HomePage} from "pages/home";
import {client, gqlDataProvider} from "api";
import {ReturnsPage} from "pages/returns";
import {ReturnsReceiptsPage} from "pages/returns_receipts";
import {ReceiptsPage} from "pages/receipts";
import {UsersPage} from "pages/users";
import {controlRoutes} from "control/routes";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import {Privacy} from "auth/privacy";
import {Upgrading} from "pages/upgrading";
import {ProjectsPage} from "pages/projects";
import {RegisterAgent} from "auth/register_agent";
import {QuotationsPage} from "pages/quotations";
import {BranchesPage} from "pages/branches";
import {CompaniesReturnsPage} from "control/pages/company_returns_page";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

const App: React.FC = () => {
  const {push} = useNavigation();

  const authProvider: AuthProvider = {
    login: async ({username, password}) => {
      try {
        const {data} = await gqlDataProvider.custom!({
          url: "",
          method: "post",
          metaData: {
            operation: "tokenAuth",
            variables: {
              username: {
                value: username.trim(),
                type: "String",
                required: true,
              },
              password: {value: password, type: "String", required: true},
            },
            fields: [
              "token",
              {
                user: [
                  "id",
                  "firstName",
                  "lastName",
                  "isStaff",
                  "isSuperuser",
                  {
                    profile: [
                      "id",
                      "middleName",
                      "pic",
                      "fullName",
                      "efeederCode",
                    ],
                  },
                  {
                    companyuser: [
                      "id",
                      {
                        company: [
                          "id",
                          "name",
                          "tin",
                          "code",
                          "trial",
                          "expire",
                          "expired",
                          "isActive",
                          "isAgent",
                          "created",
                          "updated",
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        })
          .catch((error) => {
            message.error("Something Went Wrong, Authentication Failed");
            return {data: null};
          })
          .then((data) => {
            return data;
          });
        if (data) {
          client.setHeader("Authorization", `Bearer ${data.token}`);
          localStorage.setItem("token", data.token);
          if (data.user.companyuser.length > 0) {
            localStorage.setItem(
              "companyid",
              data.user.companyuser[0].company.id
            );
            localStorage.setItem(
              "companyName",
              data.user.companyuser[0].company.name
            );
            localStorage.setItem(
              "companyIsActive",
              data.user.companyuser[0].company.isActive
            );
          }

          localStorage.setItem("user", JSON.stringify(data.user));

          if (data.user.isSuperuser || data.user.isStaff) {
            return Promise.resolve("/control");
          } else {
            return Promise.resolve("/home");
          }
        }
      } catch (error) {
        return Promise.reject();
      }
    },
    logout: () => {
      localStorage.clear();
      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: async () => {
      const token = localStorage.getItem("token");
      if (token) {
        const {data} = await gqlDataProvider.custom!({
          url: "",
          method: "post",
          metaData: {
            operation: "verifyToken",
            variables: {
              token: {value: token, type: "String", required: true},
            },
            fields: ["payload"],
          },
        })
          .catch((error) => {
            return {data: null};
          })
          .then((data) => {
            return data;
          });
        if (data) {
          if (data.payload) {
            if (window.location.pathname === "/login") {
              window.location.href = "/home";
            }
            return Promise.resolve();
          }
          return Promise.reject();
        }
        return Promise.reject();
      }
      return Promise.reject();
    },
    getPermissions: () => Promise.resolve(["admin"]),
    getUserIdentity: async () => {
      const {data} = await gqlDataProvider.custom!({
        url: "",
        method: "get",
        metaData: {
          operation: "viewer",
          variables: {},
          fields: [
            "id",
            "firstName",
            "lastName",
            "isStaff",
            "isSuperuser",
            {
              profile: ["id", "middleName", "pic", "fullName", "efeederCode"],
            },
            {
              companyuser: [
                "id",
                {
                  company: [
                    "id",
                    "name",
                    "tin",
                    "code",
                    "trial",
                    "expire",
                    "expired",
                    "isActive",
                    "isAgent",
                    "created",
                    "updated",
                  ],
                },
              ],
            },
          ],
        },
      })
        .catch((error) => {
          localStorage.setItem("need_auth", "1");
          return {data: null};
        })
        .then((data) => {
          return data;
        });

      if (data) {
        return Promise.resolve({
          id: data.id,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          isStaff: data.isStaff,
          isSuperuser: data.isSuperuser,
          profile: data.profile,
          companyuser: data.companyuser,
        });
      }
      console.log(localStorage);
      return Promise.reject();
    },
  };

  return (
    <Refine
      Layout={Layout}
      Header={Header}
      LoginPage={Login}
      OffLayoutArea={OffLayoutArea}
      dataProvider={gqlDataProvider}
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            exact: true,
            component: ReceiptsPage,
            path: "/receipts",
          },
          {
            exact: true,
            component: ReturnsPage,
            path: "/returns",
          },
          {
            exact: true,
            component: ReturnsReceiptsPage,
            path: "/returns/:id/receipts",
          },
          {
            exact: true,
            component: HomePage,
            path: "/home",
          },
          {
            exact: true,
            component: UsersPage,
            path: "/users",
          },
          {
            exact: true,
            component: BranchesPage,
            path: "/branches",
          },
          {
            exact: true,
            component: Register,
            path: "/register",
          },
          {
            exact: true,
            component: ForgotPassword,
            path: "/forgot_password",
          },
          {
            exact: true,
            component: RecoverPassword,
            path: "/account_recovering/:code/:uid",
          },
          {
            exact: true,
            component: CompaniesReturnsPage,
            path: "/control/company/:id/returns",
          },
          {
            exact: true,
            component: Upgrading,
            path: "/upgrading",
          },
          {
            exact: true,
            component: Privacy,
            path: "/privacy",
          },
          {
            exact: true,
            component: ProjectsPage,
            path: "/projects",
          },
          {
            exact: true,
            component: RegisterAgent,
            path: "/agent/register",
          },
          {
            exact: true,
            component: QuotationsPage,
            path: "/quotations",
          },
          ...controlRoutes,
        ] as typeof routerProvider.routes,
      }}
      authProvider={authProvider}
      resources={[
        {
          name: "home",
          list: HomePage,
        },
      ]}
    />
  );
};

export default App;
