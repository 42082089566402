import {gqlDataProvider} from "api";
import {useEffect, useState} from "react";
import {CompanyData} from "interfaces";
import React from "react";
import {Button, Descriptions, Drawer, Form} from "@pankod/refine";
import {EditOutlined} from "@ant-design/icons";
import {EditProfileCompanyFormComponent} from "./edit_profile_company_component";

interface profileProps {
  onFinish?: any;
  random?: any;
}

export interface EditProfileCompanyForm {
  name: string;
  short_name: string;
  tin: string;
  vrn: string;
}

export const ProfileComponent: React.FC<profileProps> = (
  props: profileProps
) => {
  const [company, setCompany] = useState<CompanyData>();
  const [edittingCompany, setEdittingCompany] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<EditProfileCompanyForm>();

  const getMyCompany = async () => {
    setLoading(true);
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "myCompany",
        variables: {},
        fields: [
          "id",
          "name",
          "shortName",
          "tin",
          "vrn",
          "code",
          "trial",
          "totalReceipts",
          "expire",
          "expired",
          "canEdit",
          "canDelete",
          "canManage",
          {
            district: [
              "id",
              "name",
              {
                region: ["id", "name"],
              },
            ],
          },
          {
            ward: ["id", "name"],
          },
        ],
      },
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    if (data) {
      setCompany(data);
      setLoading(false);
    }
  };

  const onFinish = async (company: CompanyData) => {
    setCompany(company);
    setEdittingCompany(false);
    form.resetFields();
  };

  useEffect(() => {
    getMyCompany();
    console.log("Profile");
  }, [props.random]);

  return (
    <>
      <Descriptions
        title={
          <>
            <span>Company Informations</span>
            <span style={{marginLeft: "10px"}}>
              <Button
                disabled={!company?.canEdit}
                onClick={() => setEdittingCompany(true)}
                icon={<EditOutlined />}
              >
                Edit
              </Button>
            </span>
          </>
        }
        column={1}
      >
        <Descriptions.Item label="Name" labelStyle={{fontWeight: "bolder"}}>
          {company?.name}
        </Descriptions.Item>
        <Descriptions.Item
          label="Short Name"
          labelStyle={{fontWeight: "bolder"}}
        >
          {company?.shortName}
        </Descriptions.Item>
        <Descriptions.Item label="TIN" labelStyle={{fontWeight: "bolder"}}>
          {company?.tin}
        </Descriptions.Item>
        <Descriptions.Item label="VRN" labelStyle={{fontWeight: "bolder"}}>
          {company?.vrn}
        </Descriptions.Item>
      </Descriptions>

      <Drawer
        title="Editting Company Informations"
        placement="right"
        onClose={() => setEdittingCompany(false)}
        visible={edittingCompany}
        width={"30vw"}
      >
        <EditProfileCompanyFormComponent
          form={form}
          company={company}
          onFinish={onFinish}
          random={Math.random()}
        />
      </Drawer>
    </>
  );
};
