import { Button, Form } from "@pankod/refine";
import { InputNumber, Input, message, Checkbox } from "antd";
import { gqlDataProvider } from "api";
import { PaymentPackageData } from "interfaces";
import { useEffect, useState } from "react";

interface editPaymentPackageProps {
  onFinish?: any;
  pack?: PaymentPackageData;
}

interface PaymentPackageForm {
  name: string;
  code: string;
  price: number;
  maxReceipts: number;
  maxUsers: number;
  isCustom: boolean;
}

export const EditPaymentPackageFormComponent: React.FC<
  editPaymentPackageProps
> = (props: editPaymentPackageProps) => {
  const [form] = Form.useForm<PaymentPackageForm>();
  const [isCustom, setIsCustom] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      name: props.pack?.name,
      code: props.pack?.code,
      price: props.pack?.price,
      maxReceipts: props.pack?.maxReceipts,
      maxUsers: props.pack?.maxUsers,
      isCustom: props.pack?.isCustom,
    });
  }, [props.pack, form]);

  const onFinish = async (values: any) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "updatePaymentPackage",
        variables: {
          id: {
            value: props.pack?.id,
            type: "Int",
            required: true,
          },
          name: {
            value: values.name,
            type: "String",
            required: true,
          },
          code: {
            value: values.code,
            type: "String",
            required: true,
          },
          price: {
            value: values.price,
            type: "Float",
            required: true,
          },
          maxReceipts: {
            value: values.maxReceipts,
            type: "Int",
            required: true,
          },
          maxUsers: {
            value: values.maxUsers,
            type: "Int",
            required: true,
          },
          isCustom: {
            value: isCustom,
            type: "Boolean",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          {
            package: [
              "id",
              "name",
              "code",
              "maxUsers",
              "maxReceipts",
              "isCustom",
              "price",
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
            ],
          },
        ],
      },
    });

    if (data.success) {
      message.success(data.message);
      form.resetFields();
      props.onFinish(data.package);
    } else {
      message.error(data.message);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => {
          onFinish(values);
        }}
        requiredMark={false}
      >
        <Form.Item
          name="name"
          label="Package Name"
          rules={[{ required: true, message: "Please input Package Name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="code"
          label="Package Code"
          rules={[{ required: true, message: "Please input Package Code" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="price"
          label="price"
          rules={[{ required: true, message: "Please input Price" }]}
        >
          <InputNumber min={1} onChange={() => {}} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          name="maxUsers"
          label="Maximum Users"
          rules={[
            {
              required: true,
              type: "number",
              message: "Please input Maximum Users",
            },
          ]}
        >
          <InputNumber
            min={0}
            onChange={() => setIsCustom}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          name="maxReceipts"
          label="Maximum Receipts"
          rules={[
            {
              required: true,
              type: "number",
              message: "Please input Maximum Receipts",
            },
          ]}
        >
          <InputNumber
            name="isCustom"
            min={0}
            onChange={() => {}}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          name="isCustom"
          valuePropName="checked"
          wrapperCol={{ offset: 0, span: 16 }}
        >
          <Checkbox
            onChange={() => {
              setIsCustom(!props.pack?.isCustom);
            }}
          >
            is Custom
          </Checkbox>
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
