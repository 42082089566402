/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  SearchOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  KeyOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
} from "@pankod/refine";
import {Col, Grid, message, Row} from "antd";
import {gqlDataProvider} from "api";
import {CompanyUserData} from "interfaces";
import {useEffect, useState} from "react";
import ReactTimeAgo from "react-time-ago";
import {CreateUserFormComponent} from "./forms/create_user_form";

interface UsersSearchFormData {
  key: string;
}

interface UsersProps {
  height?: any;
}

export interface CreateUserForm {
  fullName: string;
  phone: string;
  email: string;
}

export const UsersComponent: React.FC<UsersProps> = (props: UsersProps) => {
  const [users, setUsers] = useState<CompanyUserData[]>([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [hasNext, setHasNext] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchForm] = Form.useForm<UsersSearchFormData>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm<CreateUserForm>();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;

  const showCreateUserModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values: any) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "registerCompanyUser",
        variables: {
          companyId: {
            value: localStorage.getItem("companyid"),
            type: "Int",
            required: true,
          },
          fullName: {value: values.fullName, type: "String", required: true},
          email: {value: values.email, type: "String", required: true},
          phone: {value: values.phone, type: "String", required: true},
          officeId: {value: values.officeId, type: "Int", required: true},
        },
        fields: [
          "success",
          "message",
          {
            companyUser: [
              "id",
              {
                company: ["id"],
              },
              {
                user: [
                  "id",
                  "firstName",
                  "lastName",
                  "email",
                  {
                    profile: ["id", "fullName", "pic"],
                  },
                ],
              },
              {
                office: ["id", "name"],
              },
              "created",
              "updated",
              "isActive",
              "isAdmin",
              "canEdit",
              "canDelete",
              "canManage",
              {
                company: ["id"],
              },
            ],
          },
        ],
      },
    });
    if (data.success) {
      message.success(data.message);
      setIsModalVisible(false);
      setUsers([data.companyUser, ...users]);
      form.resetFields();
    } else {
      message.error(data.message);
    }
  };

  const resetCredentials = async (id: number) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "recreateUserCredentials",
        variables: {
          userId: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
    } else {
      message.error(data.message);
    }
  };

  const grantUngrantAdmin = async (id: number, grant: boolean) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "grantUngrantCompanyUserAdmin",
        variables: {
          companyUserId: {
            value: id,
            type: "Int",
            required: true,
          },
          grant: {
            value: grant,
            type: "Boolean",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          {
            companyUser: [
              "id",
              {
                company: ["id"],
              },
              {
                user: [
                  "id",
                  "firstName",
                  "lastName",
                  "email",
                  {
                    profile: ["id", "fullName", "pic", "phone", "efeederCode"],
                  },
                ],
              },
              {
                office: ["id", "name"],
              },
              "created",
              "updated",
              "isActive",
              "isAdmin",
              "canEdit",
              "canDelete",
              "canManage",
              {
                company: ["id"],
              },
            ],
          },
        ],
      },
    });

    if (data.success) {
      message.success(data.message);
      setUsers(users.map((user) => (user.id === id ? data.companyUser : user)));
    } else {
      message.error(data.message);
    }
  };

  const confirmDelete = async (id: number) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "deleteCompanyUser",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
      setUsers(users.filter((user) => user.id !== id));
    } else {
      message.error(data.message);
    }
  };

  const columns = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      render: (text: any, row: any, index: any) => (
        <a onClick={() => {}}>{row.user.profile.fullName}</a>
      ),
    },
    {
      title: "Office/Branch",
      dataIndex: "office",
      render: (text: any, row: any, index: any) => (
        <Tag color="green">{row?.office?.name}</Tag>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text: any, row: any, index: any) => (
        <>
          <a onClick={() => {}}>{row.user.email}</a>
          {row.user.profile.phone && (
            <span style={{marginLeft: 10}}>
              <Tag color="green">{row.user.profile.phone}</Tag>
            </span>
          )}
        </>
      ),
    },
    {
      title: "Admin",
      dataIndex: "admin",
      render: (text: any, row: any, index: any) => (
        <>
          {row.isAdmin ? (
            <Tooltip
              title="Click to Remove Admin Previeledge"
              placement="bottom"
            >
              <Popconfirm
                title="Are you sure to Remove Admin Previeledge?"
                onConfirm={() => grantUngrantAdmin(row.id, false)}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
                disabled={!row.canManage}
              >
                <a>
                  <Tag color="green">Admin</Tag>
                </a>
              </Popconfirm>
            </Tooltip>
          ) : (
            <Tooltip
              title="Click to Grant Admin Previeledge"
              placement="bottom"
            >
              <Popconfirm
                title="Are you sure to Grant Admin Previeledge?"
                onConfirm={() => grantUngrantAdmin(row.id, true)}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
                disabled={!row.canManage}
              >
                <a>
                  <Tag color="red">Not Admin</Tag>
                </a>
              </Popconfirm>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: "Registered On",
      dataIndex: "created",
      render: (text: any, row: any, index: any) => (
        <ReactTimeAgo date={new Date(row.created)} />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: any, row: any, index: any) =>
        row.isActive ? "Active" : "Inactive",
    },
    {
      title: "Action(s)",
      dataIndex: "action",
      align: "right" as "right",
      render: (text: any, row: any, index: any) => (
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                <Menu.Item icon={<DeleteOutlined />}>
                  <Popconfirm
                    title={"Are you sure to delete this User?"}
                    onConfirm={() => confirmDelete(row?.id)}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                    disabled={!row?.canDelete}
                  >
                    <a>
                      Delete
                    </a>
                  </Popconfirm>
                </Menu.Item>

                <Menu.Item icon={<KeyOutlined />}>
                  <Popconfirm
                    title={
                      "Are you sure to Recreate Credentials for this User?"
                    }
                    onConfirm={() => resetCredentials(row?.user.id)}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                    disabled={!row?.canDelete}
                  >
                    <a>
                      Recreate-Credentials
                    </a>
                  </Popconfirm>
                </Menu.Item>

                <Menu.Item icon={<KeyOutlined />}>
                  {row?.isAdmin ? (
                    <>
                      <Popconfirm
                        title={"Are you sure to Remove Admin Previeledge?"}
                        onConfirm={() => grantUngrantAdmin(row?.id, false)}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                        disabled={!row?.canManage}
                      >
                        <a>
                          Remove Admin Previeledge
                        </a>
                      </Popconfirm>
                    </>
                  ) : (
                    <>
                      <Popconfirm
                        title={"Are you sure to Grant Admin Previeledge?"}
                        onConfirm={() => grantUngrantAdmin(row?.id, true)}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                        disabled={!row?.canManage}
                      >
                        <a>
                          Grant Admin Previeledge
                        </a>
                      </Popconfirm>
                    </>
                  )}
                </Menu.Item>
              </Menu>
            }
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <MoreOutlined
                style={{
                  color: "orange",
                  fontSize: 28,
                  fontWeight: "bold",
                }}
              />
            </a>
          </Dropdown>
        </div>
      ),
    },
  ];

  const getUsers = async (
    start: number,
    key: string = "",
    limit: number = 20
  ) => {
    setLoading(true);
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "companyUsers",
        variables: {
          start: {value: start, type: "Int", required: true},
          limit: {value: limit, type: "Int", required: true},
          key: {value: key, type: "String", required: false},
          companyId: {
            value: localStorage.getItem("companyid"),
            type: "Int",
            required: true,
          },
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: [
              "id",
              {
                company: ["id"],
              },
              {
                user: [
                  "id",
                  "firstName",
                  "lastName",
                  "email",
                  {
                    profile: ["id", "fullName", "pic", "phone", "efeederCode"],
                  },
                ],
              },
              {
                office: ["id", "name"],
              },
              "created",
              "updated",
              "isActive",
              "isAdmin",
              "canEdit",
              "canDelete",
              "canManage",
              {
                company: ["id"],
              },
            ],
          },
        ],
      },
    });
    if (data.total !== total) {
      setTotal(data.total);
    }
    if (data.hasNext !== hasNext) {
      setHasNext(data.hasNext);
    }
    setUsers([...data.results]);
    setLoading(false);
  };

  useEffect(() => {
    getUsers(1, "", 20);
  }, []);

  return (
    <>
      <Row style={{marginTop: 10}}>
        {isMobile ? (
          <>
            <Col
              span={isMobile ? 24 : 12}
              style={{display: "flex", justifyContent: "flex-end"}}
            >
              <Button
                size="large"
                icon={<PlusCircleOutlined />}
                onClick={showCreateUserModal}
              >
                Add User
              </Button>
            </Col>
            <Col span={isMobile ? 24 : 12} style={{marginTop: 10}}>
              <Form<UsersSearchFormData>
                layout="vertical"
                form={searchForm}
                onFinish={(values) => {
                  setSearchKey(values.key);
                  users.length = 0;
                  getUsers(1, values.key, limit);
                }}
              >
                <Form.Item name="key">
                  <Input
                    size="large"
                    placeholder="Search ..."
                    prefix={<SearchOutlined />}
                    autoComplete="off"
                    allowClear
                  />
                </Form.Item>
              </Form>
            </Col>
          </>
        ) : (
          <>
            <Col span={isMobile ? 24 : 12}>
              <Form<UsersSearchFormData>
                layout="vertical"
                form={searchForm}
                onFinish={(values) => {
                  users.length = 0;
                  getUsers(1, values.key, limit);
                }}
              >
                <Form.Item name="key">
                  <Input
                    size="large"
                    placeholder="Search ..."
                    prefix={<SearchOutlined />}
                    autoComplete="off"
                    allowClear
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col
              span={isMobile ? 24 : 12}
              style={{display: "flex", justifyContent: "flex-end"}}
            >
              <Button
                size="large"
                icon={<PlusCircleOutlined />}
                onClick={showCreateUserModal}
              >
                Add User
              </Button>
            </Col>
          </>
        )}
      </Row>

      <Table
        columns={columns}
        dataSource={users}
        pagination={{
          onChange: (page, pageSize) => {
            setLimit(pageSize);
            getUsers(page, searchKey, pageSize);
          },
          total: total,
          pageSize: limit,
          position: ["bottomCenter"],
          showQuickJumper: false,
        }}
        scroll={{x: true}}
        loading={loading}
      />

      <Modal
        title="Adding User"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        width={"40vw"}
      >
        <CreateUserFormComponent onFinish={onFinish} form={form} randKey={Math.random()} />
      </Modal>
    </>
  );
};
