/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  message,
  Alert,
  ConfigProvider,
} from "@pankod/refine";
import "./styles.css";
import {useNavigation} from "@pankod/refine";
import {useParams} from "react-router-dom";
import {gqlDataProvider} from "api";

const {Text, Title} = Typography;

export interface RecoverPasswordForm {
  password: string;
  confirmPassword: string;
}

export const RecoverPassword: React.FC = () => {
  const [form] = Form.useForm<RecoverPasswordForm>();
  const [recovered, setRecovered] = useState(false);
  const [canRecover, setCanRecover] = useState(false);
  const [successMessage, setSuccessMassage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const params: any = useParams();
  const {push} = useNavigation();

  const CardTitle = (
    <>
      <Title level={3} className="title">
        E-feeder
      </Title>
      <Title level={5} className="title">
        Recover your account
      </Title>
    </>
  );

  const changePassword = async (values: any) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "changePassword",
        variables: {
          userId: {value: params.uid, type: "Int", required: true},
          password: {value: values.password, type: "String", required: true},
          confirmPassword: {
            value: values.confirmPassword,
            type: "String",
            required: true,
          },
          recoveryToken: {value: params.code, type: "String", required: true},
        },
        fields: ["success", "message"],
      },
    });
    if (data.success) {
      setRecovered(true);
      setSuccessMassage(data.message);
      form.resetFields();
    } else {
      setRecovered(false);
      setErrorMessage(data.message);
    }
  };

  const verify = async () => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "verifyRecoveryCode",
        variables: {
          uid: {value: params.uid, type: "Int", required: true},
          code: {value: params.code, type: "String", required: true},
        },
        fields: ["success", "message"],
      },
    });
    if (data.success) {
      setCanRecover(true);
      message.success(data.message);
    } else {
      setCanRecover(false);
      message.error(data.message);
    }
  };

  useEffect(() => {
    verify();
  }, []);

  return (
    <ConfigProvider componentSize="small">
      <AntdLayout className="layout" style={{background: "#f0f2f5"}}>
        <Row
          justify="center"
          align="middle"
          style={{
            height: "100vh",
          }}
        >
          <Col xs={22}>
            <div className="container">
              <div className="imageContainer">
                <img
                  src="/images/efeeder.png"
                  alt="E-feeder"
                  style={{width: "100px"}}
                />
              </div>
              <Card title={CardTitle} headStyle={{borderBottom: 0}}>
                {!recovered && errorMessage.length > 0 ? (
                  <>
                    <Alert
                      message="Error"
                      description={errorMessage}
                      type="error"
                    />
                  </>
                ) : (
                  <></>
                )}
                {recovered ? (
                  <>
                    <Alert
                      message="Success"
                      description={successMessage}
                      type="success"
                    />
                    <Button
                      onClick={() => push("/login")}
                      style={{marginTop: 20}}
                      block
                    >
                      Sign In
                    </Button>
                  </>
                ) : (
                  <>
                    <Form<RecoverPasswordForm>
                      layout="vertical"
                      form={form}
                      onFinish={(values) => changePassword(values)}
                      requiredMark={false}
                    >
                      <Form.Item
                        name="password"
                        label="Password"
                        rules={[{required: true, type: "string"}]}
                      >
                        <Input
                          size="large"
                          placeholder="Password"
                          type={"password"}
                        />
                      </Form.Item>
                      <Form.Item
                        name="confirmPassword"
                        label="Confirm Password"
                        rules={[{required: true, type: "string"}]}
                      >
                        <Input
                          size="large"
                          type={"password"}
                          placeholder="Confirm Password"
                        />
                      </Form.Item>
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        disabled={!canRecover}
                        block
                      >
                        Recover
                      </Button>
                    </Form>
                    <div style={{marginTop: 8}}>
                      <Text style={{fontSize: 12}}>
                        Have an account?{" "}
                        <a
                          onClick={() => push("/login")}
                          style={{fontWeight: "bold"}}
                        >
                          Sign in
                        </a>
                      </Text>
                    </div>
                  </>
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </AntdLayout>
    </ConfigProvider>
  );
};
