import {Grid, Typography, useGetIdentity} from "@pankod/refine";
import {IResourceComponentsProps, useNavigation} from "@pankod/refine";
import {Card, Space, Col, Row, Skeleton} from "antd";
import {gqlDataProvider} from "api";
import {useEffect, useState} from "react";
import NumberFormat from "react-number-format";
import {Link} from "react-router-dom";
const {Title, Text} = Typography;

export const UserAnalytics: React.FC<IResourceComponentsProps> = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const [totalBlockedUsers, setTotalBlockedUsers] = useState(0);
  const [totalStaffs, setTotalStaffs] = useState(0);
  const [totalAdminstrators, setTotalAdminstrators] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalReceiptsAmount, setTotalReceiptsAmount] = useState(0);
  const [totalReceipts, setTotalReceipts] = useState(0);
  const [totalSyncedReceipts, setTotalSyncedReceipts] = useState(0);
  const [totalReturnSessions, setTotalReturnSessions] = useState(0);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [totalMessages, setTotalMessages] = useState(0);
  const [totalPaymentPackages, setTotalPaymentPackages] = useState(0);
  const [totalQuotations, setTotalQuotations] = useState(0);
  const [totalProjects, setTotalProjects] = useState(0);
  const [totalContacts, setTotalContacts] = useState(0);
  const [totalExpired, setTotalExpired] = useState(0);
  const [totalAgents, setTotalAgents] = useState(0);
  const [totalCommissions, setTotalCommissions] = useState(0);
  const [loading, setLoading] = useState(true);
  const {push} = useNavigation();
  const {data: user} = useGetIdentity();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;

  const getUserAnalytics = async () => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "userAnalytic",
        fields: [
          "totalUsers",
          "totalActiveUsers",
          "totalBlockedUsers",
          "totalStaffs",
          "totalAdminstrators",
          "totalCompanies",
          "totalReturnSessions",
          "totalReceipts",
          "totalSyncedReceipts",
          "totalMessages",
          "totalAmount",
          "totalPaymentPackages",
          "totalQuotations",
          "totalExpired",
          "totalProjects",
          "totalContacts",
          "totalAgents",
          "totalCommissions",
          "totalReceiptsAmount",
        ],
      },
    });
    setTotalUsers(data.totalUsers);
    setTotalActiveUsers(data.totalActiveUsers);
    setTotalBlockedUsers(data.totalBlockedUsers);
    setTotalStaffs(data.totalStaffs);
    setTotalAdminstrators(data.totalAdminstrators);
    setTotalReturnSessions(data.totalReturnSessions);
    setTotalReceipts(data.totalReceipts);
    setTotalSyncedReceipts(data.totalSyncedReceipts);
    setTotalCompanies(data.totalCompanies);
    setTotalMessages(data.totalMessages);
    setTotalAmount(data.totalAmount);
    setTotalPaymentPackages(data.totalPaymentPackages);
    setTotalQuotations(data.totalQuotations);
    setTotalExpired(data.totalExpired);
    setTotalProjects(data.totalProjects);
    setTotalContacts(data.totalContacts);
    setTotalAgents(data.totalAgents);
    setTotalCommissions(data.totalCommissions);
    setTotalReceiptsAmount(data.totalReceiptsAmount);
    setLoading(false);
  };

  useEffect(() => {
    getUserAnalytics();
  }, []);

  return (
    <>
      <Row align="top">
        <Col span={24}>
          <Title level={5}>Users/Members Analytics</Title>
        </Col>
        <Col span={isMobile ? 8 : 4}>
          <Skeleton loading={loading} active>
            <Card hoverable={true} style={{marginRight: 10}}>
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{totalUsers}</Title>
                <Text>Users</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>

        <Col span={isMobile ? 8 : 4}>
          <Skeleton loading={loading} active>
            <Card hoverable={true} style={{marginRight: 10}}>
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{totalActiveUsers}</Title>
                <Text>Active Users</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>

        <Col span={isMobile ? 8 : 4}>
          <Skeleton loading={loading} active>
            <Card hoverable={true} style={{marginRight: 10}}>
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{totalAdminstrators}</Title>
                <Text>Adminstrators</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>

        <Col span={isMobile ? 8 : 4}>
          <Skeleton loading={loading} active>
            <Card hoverable={true} style={{marginRight: 10}}>
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{totalStaffs}</Title>
                <Text>Staffs</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>

        <Col span={isMobile ? 8 : 4}>
          <Skeleton loading={loading} active>
            <Card hoverable={true} style={{marginRight: 10}}>
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{totalBlockedUsers}</Title>
                <Text>Blocked Users</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>
        <Col span={isMobile ? 8 : 4}>
          <Skeleton loading={loading} active>
            <Card
              hoverable={true}
              style={{marginRight: 10}}
              onClick={() => push("/control/receipts")}
            >
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{totalReceipts}</Title>
                <Text>Receipts</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>

        <Col span={isMobile ? 8 : 4}>
          <Skeleton loading={loading} active>
            <Card
              hoverable={true}
              style={{marginRight: 10}}
              onClick={() => push("/control/receipts/synced")}
            >
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{totalSyncedReceipts}</Title>
                <Text>Synced Receipts</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>

        <Col span={isMobile ? 8 : 4}>
          <Skeleton loading={loading} active>
            <Card
              hoverable={true}
              style={{marginRight: 10}}
              onClick={() => push("/control/returns")}
            >
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{totalReturnSessions}</Title>
                <Text>Returns</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>

        <Col span={isMobile ? 8 : 4}>
          <Skeleton loading={loading} active>
            <Card
              hoverable={true}
              style={{marginRight: 10}}
              onClick={() => push("/control/companies")}
            >
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{totalCompanies}</Title>
                <Text>Companies</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>

        <Col span={isMobile ? 8 : 4}>
          <Skeleton loading={loading} active>
            <Card
              hoverable={true}
              style={{marginRight: 10}}
              onClick={() => push("/control/contacts")}
            >
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{totalContacts}</Title>
                <Text>Contacts</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>

        <Col span={isMobile ? 8 : 4}>
          <Skeleton loading={loading} active>
            <Card
              hoverable={true}
              style={{marginRight: 10}}
              onClick={() => push("/control/messages")}
            >
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{totalMessages}</Title>
                <Text>Messages</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>

        <Col span={isMobile ? 8 : 4}>
          <Skeleton loading={loading} active>
            <Card
              hoverable={true}
              style={{marginRight: 10}}
              onClick={() => push("/control/payment/packages")}
            >
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{totalPaymentPackages}</Title>
                <Text>Paymemt Packages</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>

        <Col span={isMobile ? 8 : 4}>
          <Skeleton loading={loading} active>
            <Card
              hoverable={true}
              style={{marginRight: 10}}
              onClick={() => push("/control/quotations")}
            >
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{totalQuotations}</Title>
                <Text>Quotations</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>

        <Col span={isMobile ? 8 : 4}>
          <Skeleton loading={loading} active>
            <Card hoverable={true} style={{marginRight: 10}}>
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{totalExpired}</Title>
                <Text>Expired</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>

        <Col span={isMobile ? 8 : 4}>
          <Skeleton loading={loading} active>
            <Card hoverable={true} style={{marginRight: 10}}>
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{totalProjects}</Title>
                <Text>Projects</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>

        <Col span={isMobile ? 8 : 4}>
          <Skeleton loading={loading} active>
            <Link to="/control/agents">
              <Card hoverable={true} style={{marginRight: 10}}>
                <Space
                  direction="vertical"
                  align="center"
                  style={{width: "100%", justifyContent: "center"}}
                >
                  <Title level={2}>{totalAgents}</Title>
                  <Text>Agents</Text>
                </Space>
              </Card>
            </Link>
          </Skeleton>
        </Col>

        <Col span={isMobile ? 8 : 8}>
          <Skeleton loading={loading} active>
            <Card hoverable={true} style={{marginRight: 10}}>
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>
                  <NumberFormat
                    style={{fontSize: 18}}
                    value={totalReceiptsAmount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Tsh "}
                  />
                </Title>
                <Text>Total Receipts Amount</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>

        {user?.isSuperuser ? (
          <>
            <Col span={isMobile ? 24 : 12}>
              <Skeleton loading={loading} active>
                <Card
                  hoverable={true}
                  style={{marginRight: 10}}
                  onClick={() => push("/control/payments")}
                >
                  <Space
                    direction="vertical"
                    align="center"
                    style={{width: "100%", justifyContent: "center"}}
                  >
                    <Title level={2}>
                      <NumberFormat
                        value={totalAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Tsh "}
                      />
                    </Title>
                    <Text>Total Amount/Income</Text>
                  </Space>
                </Card>
              </Skeleton>
            </Col>
            <Col span={isMobile ? 24 : 12}>
              <Skeleton loading={loading} active>
                <Link to="/control/agents/commissions">
                  <Card hoverable={true} style={{marginRight: 10}}>
                    <Space
                      direction="vertical"
                      align="center"
                      style={{width: "100%", justifyContent: "center"}}
                    >
                      <Title level={2}>
                        <NumberFormat
                          value={totalCommissions}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Tsh "}
                        />
                      </Title>
                      <Text>Total Agents Commission</Text>
                    </Space>
                  </Card>
                </Link>
              </Skeleton>
            </Col>
          </>
        ) : (
          <></>
        )}
      </Row>
    </>
  );
};
