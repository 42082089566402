import {gqlDataProvider} from "api";
import {useEffect, useState} from "react";
import {ReceiptData} from "interfaces";
import React from "react";
import {Descriptions, message, Spin} from "@pankod/refine";
import { currencyFormatter } from "utils/currency_formatter";

interface Props {
  id?: any;
  receipt?: ReceiptData | any;
}

export const ReceiptInfoComponent: React.FC<Props> = (props: Props) => {
  const [receipt, setReceipt] = useState<ReceiptData>();
  const [loading, setLoading] = useState(false);

  const getReceipt = async () => {
    setLoading(true);
    const {data} = await gqlDataProvider.custom!<ReceiptData | any>({
      url: "",
      method: "get",
      metaData: {
        operation: "receipt",
        variables: {
          id: {
            value: props.id,
            type: "Int",
            required: true,
          },
        },
        fields: [
          "id",
          "number",
          "description",
          "totalInclOfTax",
          "supplierName",
          "customerName",
          "customerId",
          "customerMobile",
          "mobile",
          "zNumber",
          "receiptTime",
          "receiptDate",
          "taxRateA",
          "totalExclOfTax",
          "totalTax",
          "tin",
          "vrn",
          "uin",
          "synced",
          {
            author: ["id", "fullName"],
          },
          {
            office: ["id", "name"],
          },
          "created",
          "updated",
          "isActive",
          "canEdit",
          "canDelete",
          "canManage",
          {
            session: ["id"],
          },
        ],
      },
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return {data: null};
      });

    if (data) {
      setReceipt(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getReceipt();
    setReceipt(props.receipt);
  }, [props.id]);

  return (
    <>
      <Spin spinning={loading}>
        <Descriptions column={1}>
          <Descriptions.Item label="Name" labelStyle={{fontWeight: "bolder"}}>
            {receipt?.number}
          </Descriptions.Item>
          <Descriptions.Item
            label="Supplier"
            labelStyle={{fontWeight: "bolder"}}
          >
            {receipt?.supplierName}
          </Descriptions.Item>
          <Descriptions.Item
            label="Customer"
            labelStyle={{fontWeight: "bolder"}}
          >
            {receipt?.customerName}
          </Descriptions.Item>
          <Descriptions.Item
            label="Customer Identity"
            labelStyle={{fontWeight: "bolder"}}
          >
            {receipt?.customerId}
          </Descriptions.Item>
          <Descriptions.Item
            label="Customer Mobile"
            labelStyle={{fontWeight: "bolder"}}
          >
            {receipt?.customerMobile}
          </Descriptions.Item>
          <Descriptions.Item
            label="Receipt Date"
            labelStyle={{fontWeight: "bolder"}}
          >
            {receipt?.receiptDate}
          </Descriptions.Item>
          <Descriptions.Item
            label="Receipt #"
            labelStyle={{fontWeight: "bolder"}}
          >
            {receipt?.receiptNo}
          </Descriptions.Item>
          <Descriptions.Item
            label="Receipt Time"
            labelStyle={{fontWeight: "bolder"}}
          >
            {receipt?.receiptTime}
          </Descriptions.Item>
          <Descriptions.Item
            label="Z Number"
            labelStyle={{fontWeight: "bolder"}}
          >
            {receipt?.zNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Mobile" labelStyle={{fontWeight: "bolder"}}>
            {receipt?.mobile}
          </Descriptions.Item>
          <Descriptions.Item
            label="Serial #"
            labelStyle={{fontWeight: "bolder"}}
          >
            {receipt?.serialNo}
          </Descriptions.Item>
          <Descriptions.Item
            label="Tax Office"
            labelStyle={{fontWeight: "bolder"}}
          >
            {receipt?.taxOffice}
          </Descriptions.Item>
          <Descriptions.Item label="TIN" labelStyle={{fontWeight: "bolder"}}>
            {receipt?.tin}
          </Descriptions.Item>
          <Descriptions.Item label="UIN" labelStyle={{fontWeight: "bolder"}}>
            {receipt?.uin}
          </Descriptions.Item>
          <Descriptions.Item label="VRN" labelStyle={{fontWeight: "bolder"}}>
            {receipt?.vrn}
          </Descriptions.Item>
          <Descriptions.Item
            label="Tax Rate"
            labelStyle={{fontWeight: "bolder"}}
          >
            {currencyFormatter(receipt?.taxRateA)}
          </Descriptions.Item>
          <Descriptions.Item
            label="Total Exc Of Tax"
            labelStyle={{fontWeight: "bolder"}}
          >
            {currencyFormatter(receipt?.totalExclOfTax)}
          </Descriptions.Item>
          <Descriptions.Item
            label="Total Tax"
            labelStyle={{fontWeight: "bolder"}}
          >
            {currencyFormatter(receipt?.totalTax)}
          </Descriptions.Item>
        </Descriptions>
      </Spin>
    </>
  );
};
