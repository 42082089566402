import { Button, Form, Input } from "@pankod/refine";
import { useEffect } from "react";

interface editProjectProps {
  form?: any;
  onFinish?: any;
  project?: any;
}

export const EditProjectFormComponent: React.FC<editProjectProps> = (
  props: editProjectProps
) => {
  useEffect(() => {
    props.form.setFieldsValue({
      name: props.project?.name,
      description: props.project?.description,
    });
  }, [props]);

  return (
    <>
      <Form
        layout="vertical"
        form={props.form}
        onFinish={(values) => {
          values.id = props.project?.id;
          props.onFinish(values);
        }}
        requiredMark={false}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, type: "string" }]}
        >
          <Input size="large" placeholder="Enter Project Name ..." />
        </Form.Item>

        <Form.Item
          name="description"
          label="description"
          rules={[{ required: true, type: "string" }]}
        >
          <Input size="large" placeholder="Project Description ..." />
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
