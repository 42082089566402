import { Button, Form, Input } from "@pankod/refine";
const { TextArea } = Input;

interface composeMessageProps {
  form?: any;
  onFinish?: any;
  user?: any;
}

export const UserComposeMessageFormComponent: React.FC<composeMessageProps> = (
  props: composeMessageProps
) => {
  return (
    <>
      <Form
        layout="vertical"
        form={props.form}
        onFinish={(values) => {
          values["phones"] = props.user.profile.phone;
          props.onFinish(values);
        }}
        requiredMark={false}
      >
        <Form.Item
          name="content"
          label="Message Content"
          rules={[{ required: true, message: "Please input message content" }]}
        >
          <TextArea rows={4} placeholder="Enter Message Content ..." />
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
