/* eslint-disable jsx-a11y/anchor-is-valid */
import { HomeOutlined } from "@ant-design/icons";
import {
  Authenticated,
  Breadcrumb,
  LayoutWrapper,
  useNavigation,
} from "@pankod/refine";
import { ClientActivationPage } from "./client_activation";
import { UsersComponent } from "./components/users";

const Returns: React.FC = () => {
  const { push } = useNavigation();

  return (
    <>
      <div style={{ paddingRight: 10, paddingLeft: 10 }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a onClick={() => push("/home")}>
              <HomeOutlined />
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Users</Breadcrumb.Item>
        </Breadcrumb>
        <UsersComponent />
      </div>
    </>
  );
};

export const UsersPage = () => {
  let companyIsActive = localStorage.getItem("companyIsActive");
  if (companyIsActive === "true") {
    return (
      <Authenticated>
        <LayoutWrapper>
          <Returns />
        </LayoutWrapper>
      </Authenticated>
    );
  } else {
    return <ClientActivationPage />;
  }
};
