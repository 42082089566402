import {Button, Form, Input, Alert} from "@pankod/refine";
import {message, Select} from "antd";
import {gqlDataProvider} from "api";
import {CompanyOfficeData} from "interfaces";
import {useEffect, useState} from "react";
const {Option} = Select;

interface createUserProps {
  form?: any;
  onFinish?: any;
  randKey?: any;
}

export const CreateUserFormComponent: React.FC<createUserProps> = (
  props: createUserProps
) => {
  const [loading, setLoading] = useState(false);
  const [office, setOffice] = useState("");
  const [offices, setOffices] = useState<CompanyOfficeData[]>([]);
  const [canAdd, setCanAdd] = useState(false);
  const [checkingCanAdd, setCheckingCanAdd] = useState(false);
  const [canAddMessage, setCanAddMessage] = useState("");

  const checkCanAdd = async () => {
    setCheckingCanAdd(true);
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "canAddUser",
        variables: {
          companyId: {
            value: localStorage.getItem("companyid"),
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    })
      .catch((error) => {
        message.error("Error Occured");
        return {data: null};
      })
      .then((data) => {
        return data;
      });

    setCheckingCanAdd(false);
    message.destroy();

    if (data) {
      setCanAddMessage(data.message);
      if (data.success) {
        message.success(data.message);
      } else {
        message.error(data.message);
      }
      setCanAdd(data.success);
    }
  };

  const getOffices = async (
    start: number,
    key: string = "",
    limit: number = 20
  ) => {
    setLoading(true);
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "companyOffices",
        variables: {
          start: {value: start, type: "Int", required: true},
          limit: {value: limit, type: "Int", required: true},
          companyId: {
            value: localStorage.getItem("companyid"),
            type: "Int",
            required: true,
          },
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: [
              "id",
              "name",
              "totalStaffs",
              "totalReceipts",
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
              {
                company: ["id"],
              },
            ],
          },
        ],
      },
    });
    setOffices([...data.results]);
    setLoading(false);
  };

  const onCompanyOfficeChange = async (value: string) => {
    setOffice(value);
  };

  useEffect(() => {
    checkCanAdd();
    getOffices(1, "", 20);
  }, [props.randKey]);

  return (
    <>
      {!checkingCanAdd && (
        <Alert
          message={canAddMessage}
          style={{fontSize: 17, marginBottom: 10, marginTop: 10}}
          type={canAdd ? "success" : "error"}
        ></Alert>
      )}
      <Form
        layout="vertical"
        form={props.form}
        onFinish={(values) => {
          values.officeId = parseInt(office);
          props.onFinish(values);
        }}
        requiredMark={false}
      >
        <Form.Item
          name="fullName"
          label="Full Name"
          rules={[{required: true, type: "string"}]}
        >
          <Input size="large" placeholder="Enter Full Name ..." />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Mobile Phone Number"
          rules={[{required: true, type: "string"}]}
        >
          <Input size="large" placeholder="Mobile Phone Number ..." />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email Address"
          rules={[{required: true, type: "email"}]}
        >
          <Input size="large" placeholder="Enter Email Address ..." />
        </Form.Item>

        <Form.Item name="session_type" label="Session Type">
          <Select
            size="large"
            defaultValue={" "}
            style={{width: "100%"}}
            placeholder="Select office"
            onChange={onCompanyOfficeChange}
          >
            {offices.map((office: CompanyOfficeData) => (
              <Option value={office.id}>{office.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            disabled={!canAdd}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
