/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  useNavigation,
  Button,
} from "@pankod/refine";

import { List, Modal } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { ClientQuotationFormComponent } from "./components/forms/client_quotation_creation_form";
const { Text, Title } = Typography;

export interface RegisterForm {
  fullName: string;
  email: string;
  password: string;
  confirmPassword: string;
  companyName: string;
  tin: string;
  phone: string;
}

export const Upgrading: React.FC = () => {
  const [code, setCode] = useState("");
  const { push } = useNavigation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <AntdLayout className="layout" style={{ background: "white" }}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={24}>
          <div className="container">
            <div className="imageContainer">
              <a onClick={() => push("/login")}>
                <img
                  src="/images/efeeder.png"
                  alt="E-feeder"
                  style={{ width: "100px" }}
                />
              </a>
            </div>
            <div>
              <Title level={4} className="title">
                E-feeder Pricing
              </Title>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Text style={{ fontSize: 28 }}>Ready to start with Efeeder?</Text>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Choose the package that suits you</p>
            </div>
          </div>
          <div style={{ marginLeft: "10vw", marginRight: "10vw" }}>
            <Row>
              <Col xs={8}>
                <Card
                  headStyle={{ borderBottom: 0 }}
                  style={{ marginTop: 30, marginRight: 5 }}
                >
                  <div style={{ marginTop: 8 }}>
                    <Title level={4} className="title">
                      Small
                    </Title>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Text>1 Week</Text>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Text>Free Trial Period</Text>
                    </div>
                  </div>

                  <div>
                    <List
                      dataSource={[
                        "1 User",
                        "10 Max Receipts Scanning Per Week",
                      ]}
                      renderItem={(item) => (
                        <List.Item>
                          <span style={{ padding: 5 }}>
                            <CheckOutlined
                              style={{ color: "green", fontWeight: "bolder" }}
                              size={70}
                            />
                          </span>
                          {item}
                        </List.Item>
                      )}
                    />
                  </div>
                </Card>
              </Col>

              <Col xs={8}>
                <Card
                  headStyle={{ borderBottom: 0 }}
                  style={{ borderColor: "orange" }}
                >
                  <div style={{ marginTop: 8 }}>
                    <Title level={4} className="title">
                      Medium
                    </Title>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Text>@Annually</Text>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Text>Form Small Bussinesses/Companies</Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 30,
                      }}
                    >
                      <Button
                        onClick={() => {
                          setCode("md");
                          showModal();
                        }}
                        type="dashed"
                        size="large"
                      >
                        Request Quotation
                      </Button>
                    </div>

                    <div>
                      <List
                        dataSource={[
                          "5 Users",
                          "600 Max Receipts Scanning Per Year",
                          "Free Online Scheduled Training",
                          "24/7 Support"
                        ]}
                        renderItem={(item) => (
                          <List.Item>
                            <span style={{ padding: 5 }}>
                              <CheckOutlined
                                style={{ color: "green", fontWeight: "bolder" }}
                                size={70}
                              />
                            </span>
                            {item}
                          </List.Item>
                        )}
                      />
                    </div>
                  </div>
                </Card>
              </Col>

              <Col xs={8}>
                <Card
                  headStyle={{ borderBottom: 0 }}
                  style={{ marginTop: 30, marginLeft: 5 }}
                >
                  <div style={{ marginTop: 8 }}>
                    <Title level={4} className="title">
                      Large
                    </Title>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Text>@Annually</Text>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Text>For large Bussiness/Companies</Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 30,
                      }}
                    >
                      <Button
                        onClick={() => {
                          setCode("lg");
                          showModal();
                        }}
                        type="dashed"
                        size="large"
                      >
                        Request Quotation
                      </Button>
                    </div>

                    <div>
                      <List
                        dataSource={[
                          "Unlimited Users",
                          "Unlimited Max Receipts Scanning Per Year",
                          "Free Online Scheduled Training",
                          "24/7 Support"
                        ]}
                        renderItem={(item) => (
                          <List.Item>
                            <span style={{ padding: 5 }}>
                              <CheckOutlined
                                style={{ color: "green", fontWeight: "bolder" }}
                                size={70}
                              />
                            </span>
                            {item}
                          </List.Item>
                        )}
                      />
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>

          <div
            style={{
              marginLeft: "10vw",
              marginRight: "10vw",
              display: "flex",
              justifyContent: "center",
              marginTop: 40,
            }}
          >
            <Button
              onClick={() => push("/login")}
              size="large"
              type="dashed"
              danger
            >
              Upgrade Later
            </Button>
          </div>
        </Col>
      </Row>
      <Modal
        title="Creating a Quotation"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <ClientQuotationFormComponent code={code} />
      </Modal>
    </AntdLayout>
  );
};
