import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import {
  IResourceComponentsProps,
  Authenticated,
  LayoutWrapper,
  Breadcrumb,
  useNavigation,
} from "@pankod/refine";
import { ClientActivationPage } from "./client_activation";
import { BranchesComponent } from "./components/branches_component";
import { ReturnsComponent } from "./components/returns";

const Branches: React.FC<IResourceComponentsProps> = () => {
  const { push } = useNavigation();

  return (
    <>
      <div style={{paddingRight: 10, paddingLeft: 10}}>
      <Breadcrumb>
        <Breadcrumb.Item onClick={()=>push("/home")}>
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Branches</Breadcrumb.Item>
      </Breadcrumb>
      <BranchesComponent />
      </div>
    </>
  );
};

export const BranchesPage = () => {
  let companyIsActive = localStorage.getItem("companyIsActive");
  if (companyIsActive === "true") {
    return (
      <Authenticated>
        <LayoutWrapper>
          <Branches />
        </LayoutWrapper>
      </Authenticated>
    );
  } else {
    return <ClientActivationPage />;
  }
};
