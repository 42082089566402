import dataProvider from "@pankod/refine-graphql";
import { GraphQLClient } from "graphql-request";

const production = true;
let API_URL = "http://localhost:8000/graphql/";
if(production){
  API_URL = "https://core.efeeder.co.tz/graphql/";
}

export const client = new GraphQLClient(API_URL);
export const gqlDataProvider = dataProvider(client);

if (localStorage.getItem("token")) {
  client.setHeader("Authorization", `Bearer ${localStorage.getItem("token")}`);
}
