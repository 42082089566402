/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  SearchOutlined,
  DeleteOutlined,
  KeyOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Grid,
  Input,
  Popconfirm,
  Table,
  Tooltip,
} from "@pankod/refine";
import { Col, message, Row, Tag } from "antd";
import { gqlDataProvider } from "api";
import { ReceiptData } from "interfaces";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";

interface ReceiptsSearchFormData {
  key: string;
}

interface ReceiptsProps {
  height?: any;
  canAdd?: boolean;
}

export const ControlReceiptsSyncedComponent: React.FC<ReceiptsProps> = (
  props: ReceiptsProps
) => {
  const [receipts, setReceipts] = useState<ReceiptData[]>([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchForm] = Form.useForm<ReceiptsSearchFormData>();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;

  const syncAll = async () => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "syncAllReceipts",
        variables: {},
        fields: ["success", "message"],
      },
    });
    if (data.success) {
      message.success(data.message);
    } else {
      message.error(data.message);
    }
  };

  const syncTraReceipt = async (id: number) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "syncTraReceipt",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          "data",
          {
            receipt: [
              "id",
              "number",
              "scannedContent",
              "description",
              "totalInclOfTax",
              "synced",
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
              {
                session: ["id", "name", { company: ["id", "name"] }],
              },
            ],
          },
        ],
      },
    });
    if (data.success) {
      message.success(data.message);
      setReceipts(
        receipts.map((receipt) => {
          if (receipt.id === id) {
            return data.receipt;
          }
          return receipt;
        })
      );
    } else {
      message.error(data.message);
    }
  };

  const confirmDelete = async (id: number) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "deleteReceipt",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
      setReceipts(receipts.filter((receipt) => receipt.id !== id));
    } else {
      message.error(data.message);
    }
  };

  const cancelDelete = () => {
    message.info("Canceled");
  };

  const columns = [
    {
      title: "Receipt Number",
      dataIndex: "receiptNumber",
      fixed: true,
      render: (text: any, row: any, inde: any) => (
        <span>
          <a>{row.number}</a>
        </span>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      fixed: true,
      render: (text: any, row: any, inde: any) => (
        <span>
          <a>{row.description}</a>
        </span>
      ),
    },
    {
      title: "Scanned Content",
      dataIndex: "scannedContent",
      fixed: true,
      render: (text: any, row: any, inde: any) => (
        <span>
          <Tag color={"magenta"}>{row.scannedContent}</Tag>
        </span>
      ),
    },
    {
      title: "Return Session Name",
      dataIndex: "sessionName",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <span>{row.session.name}</span>
      ),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      render: (text: any, row: any, index: any) => (
        <span>{row.session.company.name}</span>
      ),
    },
    {
      title: "Synced",
      dataIndex: "synced",
      fixed: true,
      render: (text: any, row: any, index: any) => {
        if (row.synced) {
          return <Tag color={"green"}>Synced</Tag>;
        }
        return <Tag color={"red"}>Not Synced</Tag>;
      },
    },
    {
      title: "Created",
      dataIndex: "created",
      render: (text: any, row: any, index: any) => (
        <>
          <span>
            <ReactTimeAgo date={new Date(row.created)} />
          </span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (text: any, row: any, index: any) => (
        <a>
          {row?.isActive ? (
            <span style={{ color: "green" }}>Active</span>
          ) : (
            <span style={{ color: "red" }}>Inactive</span>
          )}
        </a>
      ),
    },
    {
      title: "Action(s)",
      align: "right" as "right",
      dataIndex: "action",
      render: (text: any, row: any, index: any) => (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Popconfirm
            title={"Ressetting this member Credentials?"}
            onConfirm={() => confirmDelete(row?.id)}
            onCancel={() => cancelDelete}
            okText="Yes"
            cancelText="No"
            disabled={!row?.canDelete}
          >
            <Button icon={<KeyOutlined />} disabled={!row?.canDelete}></Button>
          </Popconfirm>
          <Popconfirm
            title={"Are you sure to delete this member?"}
            onConfirm={() => confirmDelete(row?.id)}
            onCancel={() => cancelDelete}
            okText="Yes"
            cancelText="No"
            disabled={!row?.canDelete}
          >
            <Button
              icon={<DeleteOutlined />}
              disabled={!row?.canDelete}
            ></Button>
          </Popconfirm>
          <Tooltip title="Sync TRA Receipt Data">
            <Button
              icon={<SyncOutlined />}
              disabled={!row?.canManage}
              onClick={() => syncTraReceipt(row?.id)}
            ></Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const getReceipts = async (
    start: number,
    key: string = "",
    limit: number = 20
  ) => {
    setLoading(true);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "allSyncedReceipts",
        variables: {
          start: { value: start, type: "Int", required: true },
          limit: { value: limit, type: "Int", required: false },
          key: { value: key, type: "String", required: false },
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: [
              "id",
              "number",
              "scannedContent",
              "description",
              "totalInclOfTax",
              "synced",
              "created",
              "updated",
              "canEdit",
              "canDelete",
              "canManage",
              "isActive",
              {
                session: ["id", "name", { company: ["id", "name"] }],
              },
            ],
          },
        ],
      },
    });
    setTotal(data.total);
    setReceipts([...data.results]);
    setLoading(false);
  };

  useEffect(() => {
    getReceipts(1, "", 20);
  }, []);

  return (
    <>
      <Row style={{ marginTop: 8 }}>
        <Col span={isMobile ? 24 : 20}>
          <Form<ReceiptsSearchFormData>
            layout="vertical"
            form={searchForm}
            onFinish={(values) => {
              setSearchKey(values.key);
              receipts.length = 0;
              getReceipts(1, values.key, limit);
            }}
          >
            <Form.Item name="key">
              <Input
                size="large"
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                autoComplete="off"
                allowClear
              />
            </Form.Item>
          </Form>
        </Col>

        <Col
          span={isMobile ? 24 : 4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            size="large"
            onClick={() => syncAll()}
            icon={<SyncOutlined />}
          >
            Sync All
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={receipts}
        loading={loading}
        pagination={{
          onChange: (page, pageSize) => {
            setLimit(pageSize);
            getReceipts(page, searchKey, pageSize);
          },
          total: total,
          pageSize: limit,
          position: ["bottomCenter", "topCenter"],
          showQuickJumper: false,
        }}
        scroll={{ x: true }}
      />
    </>
  );
};
