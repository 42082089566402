/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    SearchOutlined,
    ExportOutlined,
    MoreOutlined,
  } from "@ant-design/icons";
  import {
    Dropdown,
    Form,
    Grid,
    Input,
    Menu,
    Popconfirm,
    Table,
    Tag,
    useNavigation,
  } from "@pankod/refine";
  import {Col, message, notification, Row, Tooltip} from "antd";
  import {gqlDataProvider} from "api";
  import {SessionData} from "interfaces";
  import {useEffect, useState} from "react";
  import ReactTimeAgo from "react-time-ago";
  import type {NotificationPlacement} from "antd/lib/notification";
  import React from "react";
import { useParams } from "react-router-dom";
  
  const Context = React.createContext({name: "Default"});
  
  interface CompanyReturnsSearchFormData {
    key: string;
  }
  
  interface CompanyReturnsProps {
    height?: any;
    randKey?: any;
  }
  
  
  export const CompanyReturnsComponent: React.FC<CompanyReturnsProps> = (
    props: CompanyReturnsProps
  ) => {
    const [api, contextHolder] = notification.useNotification();
    const [returns, setReturns] = useState<SessionData[]>([]);
    const [limit, setLimit] = useState(20);
    const [searchKey, setSearchKey] = useState("");
    const [total, setTotal] = useState(0);
    const [hasNext, setHasNext] = useState(false);
    const [loading, setLoading] = useState(true);
    const {push} = useNavigation();
    const params: any = useParams();
    const [searchForm] = Form.useForm<CompanyReturnsSearchFormData>();
    const breakpoint = Grid.useBreakpoint();
    const isMobile = !breakpoint.lg;
  

    const confirmDownload = async (id: number) => {
      const {data} = await gqlDataProvider.custom!({
        url: "",
        method: "post",
        metaData: {
          operation: "createReturnDocument",
          variables: {
            id: {
              value: id,
              type: "Int",
              required: true,
            },
          },
          fields: [
            "success",
            "message",
            {
              session: [
                "id",
                "name",
                "startDate",
                "endDate",
                "created",
                "updated",
                "canEdit",
                "canDelete",
                "isActive",
                "locked",
                {
                  author: ["id", "fullName"],
                },
                "docFile",
                "doc",
              ],
            },
          ],
        },
      });
  
      if (data.success) {
        message.success(data.message);
        window.open(data.session.doc, "_blank");
      } else {
        message.error(data.message);
      }
    };
  
    const confirmDownloadVerified = async (id: number) => {
      const {data} = await gqlDataProvider.custom!({
        url: "",
        method: "post",
        metaData: {
          operation: "createReturnDocumentVerified",
          variables: {
            id: {
              value: id,
              type: "Int",
              required: true,
            },
          },
          fields: [
            "success",
            "message",
            {
              session: [
                "id",
                "name",
                "startDate",
                "endDate",
                "created",
                "updated",
                "canEdit",
                "canDelete",
                "isActive",
                "docFile",
                "locked",
                {
                  author: ["id", "fullName"],
                },
                "doc",
              ],
            },
          ],
        },
      });
  
      if (data.success) {
        message.success(data.message);
        window.open(data.session.doc, "_blank");
      } else {
        message.error(data.message);
      }
    };
  
    const confirmDownloadAll = async (id: number) => {
      const {data} = await gqlDataProvider.custom!({
        url: "",
        method: "post",
        metaData: {
          operation: "createReturnAllDocument",
          variables: {
            id: {
              value: id,
              type: "Int",
              required: true,
            },
          },
          fields: [
            "success",
            "message",
            {
              session: [
                "id",
                "name",
                "startDate",
                "endDate",
                "created",
                "updated",
                "canEdit",
                "canDelete",
                "isActive",
                "docFile",
                "doc",
                "locked",
                {
                  author: ["id", "fullName"],
                },
              ],
            },
          ],
        },
      });
  
      if (data.success) {
        console.log(data);
        message.success(data.message);
        window.open(data.session.doc, "_blank");
      } else {
        message.error(data.message);
      }
    };

  
    const getReturns = async (
      start: number,
      key: string = "",
      limit: number = 20
    ) => {
      setLoading(true);
      const {data} = await gqlDataProvider.custom!({
        url: "",
        method: "get",
        metaData: {
          operation: "sessions",
          variables: {
            start: {value: start, type: "Int", required: true},
            limit: {value: limit, type: "Int", required: true},
            companyId: {
              value: params.id,
              type: "Int",
              required: true,
            },
          },
          fields: [
            "total",
            "page",
            "pages",
            "hasNext",
            "hasPrev",
            {
              results: [
                "id",
                "name",
                "returnSessionType",
                "returnSessionTypeId",
                "totalReceipts",
                "startDate",
                "endDate",
                "created",
                "updated",
                "isActive",
                "canEdit",
                "canDelete",
                "canManage",
                "canScan",
                "locked",
                {
                  company: ["id"],
                },
                {
                  author: ["id", "fullName"],
                },
              ],
            },
          ],
        },
      });
      if (data.total !== total) {
        setTotal(data.total);
      }
      if (data.hasNext !== hasNext) {
        setHasNext(data.hasNext);
      }
      setReturns([...data.results]);
      setLoading(false);
    };
  
    const openNotification = (msg: String, placement: NotificationPlacement) => {
      api.info({
        message: `Notification`,
        description: msg,
        placement,
      });
    };
  
    const columns = [
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
        fixed: true,
        render: (text: any, row: any, index: any) => (
          <a
            onClick={() => {
              if (row.canScan) {
                push("/returns/" + row?.id + "/receipts");
              } else {
                openNotification(
                  "Receipt Scanning on This Return Category is not supported yet",
                  "bottomRight"
                );
              }
            }}
            style={{color: row.canScan ? "green" : "red"}}
          >
            {text}
          </a>
        ),
      },
      {
        title: "Return Type",
        key: "return_session_type",
        dataIndex: "return_session_type",
        fixed: true,
        render: (text: any, row: any, index: any) => (
          <span style={{color: row.canScan ? "green" : "red"}}>
            {row.returnSessionType}
          </span>
        ),
      },
      {
        title: "Created By",
        key: "createdby",
        dataIndex: "createdby",
        fixed: true,
        render: (text: any, row: any, index: any) => (
          <Tag color="green">{row.author.fullName}</Tag>
        ),
      },
      {
        title: "Total Scanned Receipts",
        dataIndex: "totalReceipts",
      },
      {
        title: "Locked",
        dataIndex: "locked",
        render: (text: any, row: any, index: any) => (
          <span>
            {row.locked ? (
              <>
                <Tag color="red">Locked</Tag>
              </>
            ) : (
              <>
                <Tag color="green">Not Locked</Tag>
              </>
            )}
          </span>
        ),
      },
      {
        title: "Created Date",
        dataIndex: "created",
        key: "created",
        render: (text: any, row: any, index: any) => (
          <span>
            <ReactTimeAgo date={row.created} locale="en-US" tick={true} />
          </span>
        ),
      },
      {
        title: "Actions",
        align: "right" as "right",
        dataIndex: "action",
        style: {display: "flex", justifyContent: "flex-end"},
        render: (text: any, row: any, index: any) => (
          <div style={{display: "flex", justifyContent: "flex-end"}}>
            <Dropdown
              trigger={["click"]}
              overlay={
                <Menu>
                  <Menu.Item icon={<ExportOutlined style={{color: "green"}} />}>
                    <Tooltip title="Export Data Compatible with TRA Excel Format (Minimum Data)">
                      <a
                        rel="noopener noreferrer"
                        onClick={() => confirmDownload(row?.id)}
                      >
                        Export Excel
                      </a>
                    </Tooltip>
                  </Menu.Item>
  
                  <Menu.Item icon={<ExportOutlined style={{color: "green"}} />}>
                    <Tooltip title="Export Verified Data Compatible with TRA Excel Format (Minimum Data)">
                      <a
                        rel="noopener noreferrer"
                        onClick={() => confirmDownloadVerified(row?.id)}
                      >
                        Export Excel (Verified)
                      </a>
                    </Tooltip>
                  </Menu.Item>
  
                  <Menu.Item icon={<ExportOutlined style={{color: "green"}} />}>
                    <Tooltip title="Export All Receipt Data (Plus All synced from TRA)">
                      <a
                        rel="noopener noreferrer"
                        onClick={() => confirmDownloadAll(row?.id)}
                      >
                        Export Full Data Excel
                      </a>
                    </Tooltip>
                  </Menu.Item>
                </Menu>
              }
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <MoreOutlined
                  style={{
                    color: "orange",
                    fontSize: 28,
                    fontWeight: "bold",
                  }}
                />
              </a>
            </Dropdown>
          </div>
        ),
      },
    ];
  
    useEffect(() => {
      getReturns(1, "", 20);
    }, [props.randKey]);
  
    return (
      <>
        <Context.Provider value={{name: "Efeeder"}}>
          {contextHolder}
          <Row style={{marginTop: 10}}>
            {isMobile ? (
              <>
                <Col span={24} style={{marginTop: 10}}>
                  <Form<CompanyReturnsSearchFormData>
                    layout="vertical"
                    form={searchForm}
                    onFinish={(values) => {
                      setSearchKey(values.key);
                      getReturns(0, values.key, limit);
                    }}
                  >
                    <Form.Item name="key">
                      <Input
                        size="large"
                        placeholder="Search ..."
                        prefix={<SearchOutlined />}
                        autoComplete="off"
                        allowClear
                      />
                    </Form.Item>
                  </Form>
                </Col>
              </>
            ) : (
              <>
                <Col span={20}>
                  <Form<CompanyReturnsSearchFormData>
                    layout="vertical"
                    form={searchForm}
                    onFinish={(values) => {
                      getReturns(0, values.key, limit);
                    }}
                  >
                    <Form.Item name="key">
                      <Input
                        size="large"
                        placeholder="Search ..."
                        prefix={<SearchOutlined />}
                        autoComplete="off"
                        allowClear
                      />
                    </Form.Item>
                  </Form>
                </Col>
              </>
            )}
          </Row>
  
          <Table
            columns={columns}
            dataSource={returns}
            pagination={{
              onChange: (page, pageSize) => {
                setLimit(pageSize);
                getReturns(page, searchKey, pageSize);
              },
              total: total,
              pageSize: limit,
              position: ["bottomCenter"],
              showQuickJumper: false,
            }}
            scroll={{x: true}}
            loading={loading}
          />
        </Context.Provider>
      </>
    );
  };
  