/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  Space,
  Divider,
} from "@pankod/refine";
import "./styles.css";

import {ConfigProvider, useLogin} from "@pankod/refine";
import {useNavigation} from "@pankod/refine";

const {Text, Title} = Typography;

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

export const Login: React.FC = () => {
  const [form] = Form.useForm<ILoginForm>();
  const {mutate: login} = useLogin<ILoginForm>();
  const {push} = useNavigation();

  const CardTitle = (
    <>
      <Title level={3} className="title">
        E-feeder
      </Title>
      <Title level={5} className="title">
        Sign in your account
      </Title>
    </>
  );

  const validateUsername = (_: any, value: string) => {
    if (value.trim().indexOf(" ") < 0) {
      const validEmail = String(value.trim())
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      if (validEmail) {
        return Promise.resolve();
      } else {
        return Promise.reject("Please enter a valid email address");
      }
    }
    return Promise.reject("Username cannot contain spaces");
  };

  return (
    <ConfigProvider componentSize="small">
      <AntdLayout className="layout" style={{background: "#f0f2f5"}}>
        <Row
          justify="center"
          align="middle"
          style={{
            minHeight: "100vh",
            overflowY: "auto",
          }}
        >
          <Row style={{display: "flex", justifyContent: "center"}}>
            <Col xs={16}>
              <Space
                direction="vertical"
                style={{
                  display: "flex",
                  marginLeft: 45,
                  width: "75%",
                  justifyContent: "center",
                }}
              >
                <span style={{fontSize: 54, fontWeight: "bolder"}}>
                  Easy, Simple TRA eFiling,
                  <span style={{color: "red"}}>VFD</span>
                  Receipt Informations Extractor Helper
                </span>
                <span style={{fontSize: 30}}>
                  Store, and collaborate on EFD Receipts Scanning on your
                  Computer
                </span>

                <Divider
                  style={{backgroundColor: "green", padding: 1}}
                ></Divider>

                <span style={{fontSize: 30}}>
                  Become <span style={{color: "green"}}>Efeeder</span> Agent,
                  and start Earning Money, by Connecting<b> TAX PAYERS</b> to{" "}
                  <span style={{color: "green"}}>Efeeder</span>.
                </span>
                <span>
                  <Button
                    size="large"
                    style={{height: 75, fontSize: 18}}
                    onClick={() => push("/agent/register")}
                  >
                    Register as Agent
                  </Button>
                </span>
              </Space>
            </Col>

            <Col xs={8}>
              <div className="container">
                <div className="imageContainer">
                  <img
                    src="/images/efeeder.png"
                    alt="Olbongo Logo"
                    style={{width: "100px"}}
                  />
                </div>
                <Card title={CardTitle} headStyle={{borderBottom: 0}}>
                  <Form<ILoginForm>
                    layout="vertical"
                    form={form}
                    onFinish={(values) => {
                      login(values);
                    }}
                    requiredMark={false}
                  >
                    <Form.Item
                      name="username"
                      label="Email"
                      rules={[
                        {
                          validator: validateUsername,
                          validateTrigger: "onBlur",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[{required: true}]}
                      style={{marginBottom: "12px"}}
                    >
                      <Input type="password" placeholder="" size="large" />
                    </Form.Item>
                    <div style={{marginBottom: "12px"}}>
                      <Form.Item
                        name="remember"
                        valuePropName="checked"
                        noStyle
                      >
                        <Checkbox
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Remember me
                        </Checkbox>
                      </Form.Item>

                      <a
                        style={{
                          float: "right",
                          fontSize: "12px",
                        }}
                        onClick={() => push("/forgot_password")}
                      >
                        Forgot password?
                      </a>
                    </div>
                    <Button type="primary" size="large" htmlType="submit" block>
                      Sign in
                    </Button>
                  </Form>
                  <div style={{marginTop: 8}}>
                    <Text style={{fontSize: 12}}>
                      Don’t have an account?{" "}
                      <a
                        onClick={() => push("/register")}
                        style={{fontWeight: "bold"}}
                      >
                        Sign up
                      </a>
                    </Text>
                  </div>

                  <div style={{marginTop: 8}}>
                    <Text style={{fontSize: 12}}>
                      Read Privacy Policy{" "}
                      <a
                        onClick={() => push("/privacy")}
                        style={{fontWeight: "bold"}}
                      >
                        Read
                      </a>
                    </Text>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Row>
      </AntdLayout>
    </ConfigProvider>
  );
};
