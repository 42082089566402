/* eslint-disable jsx-a11y/anchor-is-valid */
import {ArrowRightOutlined, HomeOutlined} from "@ant-design/icons";
import {
  IResourceComponentsProps,
  Authenticated,
  LayoutWrapper,
  Breadcrumb,
  useNavigation,
} from "@pankod/refine";
import {gqlDataProvider} from "api";
import {ControlHeader} from "components/layout/header/control_header";
import {CompanyReturnsComponent} from "control/components/company_returns_component";
import {CompanyData} from "interfaces";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

const CompaniesReturns: React.FC<IResourceComponentsProps> = () => {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<CompanyData>();
  const {push} = useNavigation();
  const params: any = useParams();

  const getCompany = async () => {
    setLoading(true);
    const {data} = await gqlDataProvider.custom!<CompanyData | any>({
      url: "",
      method: "get",
      metaData: {
        operation: "company",
        variables: {
          id: {
            value: params.id,
            type: "Int",
            required: true,
          },
        },
        fields: ["id", "name"],
      },
    });

    setCompany(data);
    setLoading(false);
  };

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <>
      <div style={{paddingRight: 10, paddingLeft: 10}}>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => push("/control/analytics")}>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => push("/control/companies")}>
            <a>Companies</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {company?.name}
            <ArrowRightOutlined />
            VAT Returns
          </Breadcrumb.Item>
        </Breadcrumb>
        <CompanyReturnsComponent />
      </div>
    </>
  );
};

export const CompaniesReturnsPage = () => {
  return (
    <Authenticated>
      <LayoutWrapper
        Sider={() => <></>}
        Header={() => (
          <>
            <ControlHeader />
          </>
        )}
      >
        <CompaniesReturns />
      </LayoutWrapper>
    </Authenticated>
  );
};
