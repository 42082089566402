import { Button, Form, Input } from "@pankod/refine";
import { Col, Row } from "antd";

interface expireExtensionProps {
  form?: any;
  onFinish?: any;
}

export const ExpireExtensionFormComponent: React.FC<expireExtensionProps> = (
  props: expireExtensionProps
) => {
  return (
    <>
      <Form
        layout="vertical"
        form={props.form}
        onFinish={(values) => {
          props.onFinish(values);
        }}
        requiredMark={false}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="days"
              label="Number of Days"
              rules={[
                {
                  required: true,
                  type: "date",
                  message: "Please input Number of Days",
                },
              ]}
            >
              <Input placeholder="Days" />
            </Form.Item>
          </Col>
        </Row>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
