/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, useGetIdentity, useNavigation } from "@pankod/refine";
import { Card, Col, Row, Skeleton, Space, Tag, Typography } from "antd";
import { gqlDataProvider } from "api";
import { AgentAnalyticsData } from "interfaces";
import { useEffect, useState } from "react";

const { Title, Text } = Typography;

interface SessionProps {
  randKey?: any;
}

export const AgentDashboardComponent: React.FC<SessionProps> = (
  props: SessionProps
) => {
  const [loading, setLoading] = useState(true);
  const [analytics, setAnalytics] = useState<AgentAnalyticsData>();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;
  const { push } = useNavigation();
  const { data: user } = useGetIdentity();

  const getAnalytics = async () => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "efeederAgentAnalytic",
        variables: {},
        fields: ["analytics"],
      },
    });
    setAnalytics(data.analytics);
    setLoading(false);
  };

  useEffect(() => {
    getAnalytics();
  }, [props?.randKey]);

  return (
    <>
      <Row align="top">
        <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tag style={{ padding: 10 }}>
            <span style={{ marginRight: 30, fontSize: 22, margin: 30 }}>
              Efeeder Agency Code:
              <span style={{ fontWeight: "bolder", marginLeft: 10 }}>
                {user?.companyuser[0].company.code}
                {user?.companyuser[0].company.code ? (
                  <></>
                ) : (
                  <>
                    <Button>Get Code</Button>
                  </>
                )}
              </span>
            </span>
          </Tag>
        </Col>
      </Row>
      <Row align="top">
        <Col span={isMobile ? 12 : 4}>
          <Skeleton loading={loading} active>
            <Card
              hoverable={true}
              style={{ marginRight: 10 }}
              onClick={() => push("/agent/customers")}
            >
              <Space
                direction="vertical"
                align="center"
                style={{ width: "100%", justifyContent: "center" }}
              >
                <Title level={2}>{analytics?.total_customers}</Title>
                <Text>Customers</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>
        <Col span={isMobile ? 12 : 4}>
          <Skeleton loading={loading} active>
            <Card
              hoverable={true}
              style={{ marginRight: 10 }}
              onClick={() => push("/agent/users")}
            >
              <Space
                direction="vertical"
                align="center"
                style={{ width: "100%", justifyContent: "center" }}
              >
                <Title level={2}>{analytics?.total_users}</Title>
                <Text>Members/User</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>
        <Col span={isMobile ? 12 : 4}>
          <Skeleton loading={loading} active>
            <Card
              hoverable={true}
              style={{ marginRight: 10 }}
              onClick={() => push("/agent/commissions")}
            >
              <Space
                direction="vertical"
                align="center"
                style={{ width: "100%", justifyContent: "center" }}
              >
                <Title level={2}>{analytics?.total_commissions}</Title>
                <Text>Commission</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>
      </Row>
    </>
  );
};
