import { Button, Form, Input } from "@pankod/refine";
const { TextArea } = Input;

interface composeMessageSpecificProps {
  form?: any;
  onFinish?: any;
}

export const ComposeMessageSpecificFormComponent: React.FC<composeMessageSpecificProps> = (
  props: composeMessageSpecificProps
) => {
  return (
    <>
      <Form
        layout="vertical"
        form={props.form}
        onFinish={(values) => {
          props.onFinish(values.content, values.phones);
        }}
        requiredMark={false}
      >
        <Form.Item
          name="content"
          label="Message Content"
            rules={[{ required: true, message: "Please input message content" }]}
        >
          <TextArea rows={4} placeholder="Enter Message Content ..." />
        </Form.Item>


        <Form.Item
          name="phones"
          label="Phone Numbers(comma separated)"
            rules={[{ required: true, message: "Please input Phone Number" }]}
        >
          <TextArea rows={1} placeholder="Enter Phone Number ..." />
        </Form.Item>

        
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Send
          </Button>
        </div>
      </Form>
    </>
  );
};
