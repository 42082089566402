import { IResourceComponentsProps } from "@pankod/refine";
import { UserAnalytics } from "./user_analytics";

export const Analytics: React.FC<IResourceComponentsProps> = () => {
  return (
    <>
      <UserAnalytics />
    </>
  );
};
