/* eslint-disable react-hooks/exhaustive-deps */
import {Grid, useGetIdentity, useNavigation} from "@pankod/refine";
import {Badge, Card, Col, Row, Skeleton, Space, Tag, Typography} from "antd";
import {gqlDataProvider} from "api";
import {AnalyticsData} from "interfaces";
import {useEffect, useState} from "react";
import {ReturnsComponent} from "./returns";

const {Title, Text} = Typography;

interface SessionProps {
  randKey?: any;
}

export const SessionsComponent: React.FC<SessionProps> = (
  props: SessionProps
) => {
  const [loading, setLoading] = useState(true);
  const [analytics, setAnalytics] = useState<AnalyticsData>();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;
  const {push} = useNavigation();
  const {data: user} = useGetIdentity();

  const getAnalytics = async () => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "efeederAnalytic",
        variables: {
          companyId: {
            value: localStorage.getItem("companyid"),
            type: "Int",
            required: true,
          },
        },
        fields: ["analytics"],
      },
    });
    setAnalytics(data.analytics);
    setLoading(false);
  };

  useEffect(() => {
    getAnalytics();
  }, [props?.randKey]);

  return (
    <>
      <Row align="top">
        <Col span={24} style={{display: "flex", justifyContent: "flex-end"}}>
          <Tag style={{padding: 10}}>
            <span style={{marginRight: 30, fontSize: 22, margin: 30}}>
              Efeeder Code:
              <span style={{fontWeight: "bolder", marginLeft: 10}}>
                {user?.profile?.efeederCode}
              </span>
            </span>
          </Tag>
        </Col>
      </Row>
      <Row align="top">
        <Col span={isMobile ? 12 : 4}>
          <Skeleton loading={loading} active>
            <Card
              hoverable={true}
              style={{marginRight: 10}}
              onClick={() => push("/returns")}
            >
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{analytics?.tota_returns}</Title>
                <Text>VAT Returns</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>
        <Col span={isMobile ? 12 : 4}>
          <Skeleton loading={loading} active>
            <Card
              hoverable={true}
              style={{marginRight: 10}}
              onClick={() => push("/receipts")}
            >
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Badge
                  count={analytics?.total_synced_receipts + " Synced"}
                  color={"green"}
                  offset={[50, -5]}
                >
                  <Title level={2}>{analytics?.total_receipts}</Title>
                </Badge>
                <Text>Scanned Receipts</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>
        <Col span={isMobile ? 12 : 4}>
          <Skeleton loading={loading} active>
            <Card
              hoverable={true}
              style={{marginRight: 10}}
              onClick={() => push("/users")}
            >
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Badge
                  count={analytics?.maximum_users + " Max Users"}
                  color={"red"}
                  offset={[50, -5]}
                >
                  <Title level={2}>{analytics?.total_member}</Title>
                </Badge>
                <Text>Members/User</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>
        {/* <Col span={isMobile ? 12 : 4}>
          <Skeleton loading={loading} active>
            <Card
              hoverable={true}
              style={{marginRight: 10}}
              onClick={() => push("/projects")}
            >
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{analytics?.total_projects}</Title>
                <Text>Projects</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col> */}

        {/* <Col span={isMobile ? 12 : 4}>
          <Skeleton loading={loading} active>
            <Card hoverable={true} style={{marginRight: 10}}>
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{analytics?.account_receipts}</Title>
                <Text>Account Receipts Remained</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col> */}

        <Col span={isMobile ? 12 : 4}>
          <Skeleton loading={loading} active>
            <Card
              hoverable={true}
              style={{marginRight: 10}}
              onClick={() => push("/quotations")}
            >
              <Space
                direction="vertical"
                align="center"
                style={{width: "100%", justifyContent: "center"}}
              >
                <Title level={2}>{analytics?.total_quotations}</Title>
                <Text>Quotations</Text>
              </Space>
            </Card>
          </Skeleton>
        </Col>
      </Row>

      <ReturnsComponent height={"50vh"} />
    </>
  );
};
