/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  SearchOutlined,
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Table,
  useGetIdentity,
  useNavigation,
} from "@pankod/refine";
import { Col, Grid, message, Row, Tag, Tooltip } from "antd";
import { gqlDataProvider } from "api";
import { PaymentPackageData } from "interfaces";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { currencyFormatter } from "utils/currency_formatter";
import { CreatePaymentPackageFormComponent } from "./forms/creating_payment_package_form";
import { EditPaymentPackageFormComponent } from "./forms/edit_payment_package_form";

interface PaymentPackagesSearchFormData {
  key: string;
}

interface PaymentPackagesProps {
  height?: any;
  canAdd?: boolean;
}

export const PaymentPackagesComponent: React.FC<PaymentPackagesProps> = (
  props: PaymentPackagesProps
) => {
  const [packages, setPackages] = useState<PaymentPackageData[]>([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchForm] = Form.useForm<PaymentPackagesSearchFormData>();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [packageToEdit, setPackageToEdit] = useState<PaymentPackageData>();
  const { data: user } = useGetIdentity();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showEditModal = (pack: PaymentPackageData) => {
    setPackageToEdit(pack);
    setIsEditModalVisible(true);
  };

  const handleEditOk = () => {
    setPackageToEdit(undefined);
    setIsEditModalVisible(false);
  };

  const handleEditCancel = () => {
    setPackageToEdit(undefined);
    setIsEditModalVisible(false);
  };

  const confirmDelete = async (id: number) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "deletePaymentPackage",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
      setPackages(packages.filter((pack) => pack.id !== id));
    } else {
      message.error(data.message);
    }
  };

  const cancelDelete = () => {
    message.info("Canceled");
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <span>
          <p>{row.name}</p>
        </span>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      render: (text: any, row: any, index: any) => <span>{row.code}</span>,
    },
    {
      title: "Package Price",
      dataIndex: "price",
      render: (text: any, row: any, index: any) => (
        <span>{currencyFormatter(parseFloat(row.price))}</span>
      ),
    },
    {
      title: "Max Users",
      dataIndex: "maxUsers",
    },
    {
      title: "Max Receipts",
      dataIndex: "maxReceipts",
    },
    {
      title: "Custom Package",
      dataIndex: "isCustom",
      render: (text: any, row: any, inde: any) => (
        <span>{row.isCustom ? "Yes" : "No"}</span>
      ),
    },
    {
      title: "Created",
      dataIndex: "created",
      render: (text: any, row: any, index: any) => (
        <ReactTimeAgo date={new Date(text)}></ReactTimeAgo>
      ),
    },
    {
      title: "Status",
      dataIndex: "message",
      render: (text: any, row: any, index: any) => (
        <Tag color={row.isActive ? "green" : "red"}>
          {row.isActive ? "Active" : "Blocked"}
        </Tag>
      ),
    },
    {
      title: "Action(s)",
      align: "right" as "right",
      dataIndex: "action",
      render: (text: any, row: any, index: any) => (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tooltip title={"Edit " + row.name} color="green">
            <Button
              icon={<EditOutlined />}
              disabled={!row?.canEdit}
              onClick={() => showEditModal(row)}
            ></Button>
          </Tooltip>
          <Popconfirm
            title={"Are you sure to delete this Package"}
            onConfirm={() => confirmDelete(row?.id)}
            onCancel={() => cancelDelete}
            okText="Yes"
            cancelText="No"
            disabled={!row?.canDelete}
          >
            <Button
              icon={<DeleteOutlined />}
              disabled={!row?.canDelete}
            ></Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const getPackages = async (
    start: number,
    key: string = "",
    limit: number = 20
  ) => {
    setLoading(true);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "packages",
        variables: {
          start: { value: start, type: "Int", required: true },
          limit: { value: limit, type: "Int", required: false },
          key: { value: key, type: "String", required: false },
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: [
              "id",
              "name",
              "code",
              "maxUsers",
              "maxReceipts",
              "isCustom",
              "price",
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
            ],
          },
        ],
      },
    });
    setTotal(data.total);
    setPackages([...data.results]);
    setLoading(false);
  };

  const onFinish = async (pack: any) => {
    setPackages([pack, ...packages]);
    handleCancel();
  };

  const onFinishUpdate = async (pack: any) => {
    setPackages(packages.map((p) => (p.id === pack.id ? pack : p)));
    handleEditCancel();
  };

  useEffect(() => {
    getPackages(1, "", 20);
  }, []);

  return (
    <>
      <Row style={{ marginTop: 10 }}>
        <Col span={isMobile ? 24 : 20}>
          <Form<PaymentPackagesSearchFormData>
            layout="vertical"
            form={searchForm}
            onFinish={(values) => {
              setSearchKey(values.key);
              getPackages(0, values.key, limit);
            }}
          >
            <Form.Item name="key">
              <Input
                size="large"
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                autoComplete="off"
                allowClear
              />
            </Form.Item>
          </Form>
        </Col>
        <Col
          span={isMobile ? 24 : 4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            size="large"
            icon={<PlusOutlined />}
            onClick={showModal}
            disabled={!user?.isSuperuser}
          >
            Add Package
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={packages}
        pagination={{
          onChange: (page, pageSize) => {
            setLimit(pageSize);
            getPackages(page, searchKey, pageSize);
          },
          total: total,
          pageSize: limit,
          position: ["bottomCenter", "topCenter"],
          showQuickJumper: false,
        }}
        scroll={{ x: true }}
        loading={loading}
      />

      <Modal
        title="Creating Payment Package"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <CreatePaymentPackageFormComponent onFinish={onFinish} />
      </Modal>

      <Modal
        title="Editting Payment Package"
        visible={isEditModalVisible}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
        footer={[]}
      >
        <EditPaymentPackageFormComponent
          onFinish={onFinishUpdate}
          pack={packageToEdit}
        />
      </Modal>
    </>
  );
};
