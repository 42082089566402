/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    SearchOutlined,
    PlusCircleOutlined,
    DeleteOutlined,
    ExportOutlined,
    EditOutlined,
    MoreOutlined,
    FolderViewOutlined,
    LockOutlined,
  } from "@ant-design/icons";
  import {
    Button,
    Dropdown,
    Form,
    Grid,
    Input,
    Menu,
    Modal,
    Popconfirm,
    Table,
    Tag,
    useNavigation,
  } from "@pankod/refine";
  import {Col, message, notification, Row, Tooltip} from "antd";
  import {gqlDataProvider} from "api";
  import {CompanyOfficeData} from "interfaces";
  import {useEffect, useState} from "react";
  import ReactTimeAgo from "react-time-ago";
  import { CreateOfficeFormComponent } from "./forms/create_office_form";
  import type {NotificationPlacement} from "antd/lib/notification";
  import React from "react";
  import {EditVATReturnFormComponent} from "./forms/edit_vat_return__form";
import { EditOfficeFormComponent } from "./forms/edit_office_form";
  
  const Context = React.createContext({name: "Default"});
  
  interface ReturnsSearchFormData {
    key: string;
  }
  
  interface ReturnsProps {
    height?: any;
    randKey?: any;
  }
  
  export interface CreateVATReturnForm {
    name: string;
    session_type: string;
    start_date: string;
    end_date: string;
  }
  
  export const BranchesComponent: React.FC<ReturnsProps> = (
    props: ReturnsProps
  ) => {
    const [api, contextHolder] = notification.useNotification();
    const [office, setOffice] = useState<CompanyOfficeData>();
    const [offices, setOffices] = useState<CompanyOfficeData[]>([]);
    const [limit, setLimit] = useState(20);
    const [searchKey, setSearchKey] = useState("");
    const [total, setTotal] = useState(0);
    const [hasNext, setHasNext] = useState(false);
    const [loading, setLoading] = useState(true);
    const {push} = useNavigation();
    const [searchForm] = Form.useForm<ReturnsSearchFormData>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [form] = Form.useForm<CreateVATReturnForm>();
    const breakpoint = Grid.useBreakpoint();
    const isMobile = !breakpoint.lg;
  
    const showEditOfficeMoal = (office: CompanyOfficeData) => {
      setOffice(office);
      setIsEditModalVisible(true);
    };
  
    const showCreateOfficeModal = () => {
      setIsModalVisible(true);
    };
  
    const handleEditOk = () => {
      setIsEditModalVisible(false);
    };
  
    const handleEditCancel = () => {
      setIsEditModalVisible(false);
    };
  
    const handleOk = () => {
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
  
    const onFinish = async (values: any) => {
      const {data} = await gqlDataProvider.custom!({
        url: "",
        method: "post",
        metaData: {
          operation: "createCompanyOffice",
          variables: {
            companyId: {
              value: localStorage.getItem("companyid"),
              type: "Int",
              required: true,
            },
            name: {value: values.name, type: "String", required: true},
          },
          fields: [
            "success",
            "message",
            {
              office: [
                "id",
                "name",
                "totalStaffs",
                "totalReceipts",
                "created",
                "updated",
                "isActive",
                "canEdit",
                "canDelete",
                "canManage",
                {
                  company: ["id"],
                },
              ],
            },
          ],
        },
      });
      if (data.success) {
        message.success(data.message);
        setIsModalVisible(false);
        setOffices([data.office, ...offices]);
        form.resetFields();
      } else {
        message.error(data.message);
      }
    };
  
    const onEditFinish = async (values: any) => {
      const {data} = await gqlDataProvider.custom!({
        url: "",
        method: "post",
        metaData: {
          operation: "updateCompanyOffice",
          variables: {
            officeId: {
              value: office?.id,
              type: "Int",
              required: true,
            },
            name: {value: values.name, type: "String", required: true},
          },
          fields: [
            "success",
            "message",
            {
              office: [
                "id",
                "name",
                "totalStaffs",
                "totalReceipts",
                "created",
                "updated",
                "isActive",
                "canEdit",
                "canDelete",
                "canManage",
                {
                  company: ["id"],
                },
              ],
            },
          ],
        },
      });
      if (data.success) {
        message.success(data.message);
        setIsEditModalVisible(false);
        setOffices(
          offices.map((off: CompanyOfficeData) => {
            if (off.id.toString() === office?.id.toString()) {
              return data.office;
            }
            return off;
          })
        );
        form.resetFields();
      } else {
        message.error(data.message);
      }
    };
  
    const confirmDelete = async (id: number) => {
      const {data} = await gqlDataProvider.custom!({
        url: "",
        method: "post",
        metaData: {
          operation: "deleteCompanyOffice",
          variables: {
            officeId: {
              value: id,
              type: "Int",
              required: true,
            },
          },
          fields: ["success", "message"],
        },
      });
  
      if (data.success) {
        message.success(data.message);
        setOffices(offices.filter((office) => office.id !== id));
      } else {
        message.error(data.message);
      }
    };

  
    const getOffices = async (
      start: number,
      key: string = "",
      limit: number = 20
    ) => {
      setLoading(true);
      const {data} = await gqlDataProvider.custom!({
        url: "",
        method: "get",
        metaData: {
          operation: "companyOffices",
          variables: {
            start: {value: start, type: "Int", required: true},
            limit: {value: limit, type: "Int", required: true},
            companyId: {
              value: localStorage.getItem("companyid"),
              type: "Int",
              required: true,
            },
          },
          fields: [
            "total",
            "page",
            "pages",
            "hasNext",
            "hasPrev",
            {
              results: [
                "id",
                "name",
                "totalStaffs",
                "totalReceipts",
                "created",
                "updated",
                "isActive",
                "canEdit",
                "canDelete",
                "canManage",
                {
                  company: ["id"],
                },
              ],
            },
          ],
        },
      });
      if (data.total !== total) {
        setTotal(data.total);
      }
      if (data.hasNext !== hasNext) {
        setHasNext(data.hasNext);
      }
      setOffices([...data.results]);
      setLoading(false);
    };
  
    const openNotification = (msg: String, placement: NotificationPlacement) => {
      api.info({
        message: `Notification`,
        description: msg,
        placement,
      });
    };
  
    const columns = [
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
        fixed: true,
      },
      {
        title: "Total Staffs",
        key: "totalStaffs",
        dataIndex: "totalStaffs",
        fixed: true,
      },
      {
        title: "Total Receipts",
        key: "totalReceipts",
        dataIndex: "totalReceipts",
        fixed: true,
      },
      {
        title: "Status",
        key: "isActive",
        dataIndex: "isActive",
        fixed: true,
        render: (text: any, row: any, index: any) => (
            <span>
                {(row.isActive)?<>
                    <Tag color="green">Active</Tag>
                </>:<>
                    <Tag color="red">Blocked</Tag>
                </>}
            </span>
          ),
      },
      {
        title: "Created Date",
        dataIndex: "created",
        key: "created",
        render: (text: any, row: any, index: any) => (
          <span>
            <ReactTimeAgo date={row.created} locale="en-US" tick={true} />
          </span>
        ),
      },
      {
        title: "Actions",
        align: "right" as "right",
        dataIndex: "action",
        style: {display: "flex", justifyContent: "flex-end"},
        render: (text: any, row: any, index: any) => (
          <div style={{display: "flex", justifyContent: "flex-end"}}>
            <Dropdown
              trigger={["click"]}
              overlay={
                <Menu>
                  {row.isActive ? (
                    <>
                      <Menu.Item
                        icon={<LockOutlined style={{color: "green"}} />}
                        disabled={!row.canManage}
                      >
                        <a
                          rel="noopener noreferrer"
                          onClick={() => {}}
                        >
                          Block
                        </a>
                      </Menu.Item>
                    </>
                  ) : (
                    <>
                      <Menu.Item
                        icon={<LockOutlined style={{color: "green"}} />}
                        disabled={!row.canManage}
                      >
                        <a
                          rel="noopener noreferrer"
                          onClick={() => {}}
                        >
                            Unblock
                        </a>
                      </Menu.Item>
                    </>
                  )}
                  <Menu.Item icon={<EditOutlined style={{color: "green"}} />}>
                    <a
                      rel="noopener noreferrer"
                      onClick={() => showEditOfficeMoal(row)}
                    >
                      Edit
                    </a>
                  </Menu.Item>
                  <Menu.Item icon={<DeleteOutlined style={{color: "green"}} />}>
                    <Tooltip title="Delete Office">
                      <Popconfirm
                        title={
                          "Are you sure to delete this " +
                          row?.name +
                          " Office?"
                        }
                        onConfirm={() => confirmDelete(row?.id)}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                        disabled={!row?.canDelete}
                      >
                        <a
                          target="_blank"
                          href={""}
                          rel="noopener noreferrer"
                          download
                        >
                          Delete
                        </a>
                      </Popconfirm>
                    </Tooltip>
                  </Menu.Item>
                </Menu>
              }
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <MoreOutlined
                  style={{
                    color: "orange",
                    fontSize: 28,
                    fontWeight: "bold",
                  }}
                />
              </a>
            </Dropdown>
          </div>
        ),
      },
    ];
  
    useEffect(() => {
      getOffices(1, "", 20);
    }, [props.randKey]);
  
    return (
      <>
        <Context.Provider value={{name: "Efeeder"}}>
          {contextHolder}
          <Row style={{marginTop: 10}}>
            {isMobile ? (
              <>
                <Col
                  span={24}
                  style={{display: "flex", justifyContent: "flex-end"}}
                >
                  <Button
                    size="large"
                    icon={<PlusCircleOutlined />}
                    onClick={showCreateOfficeModal}
                  >
                    Create Office/Branch
                  </Button>
                </Col>
                <Col span={24} style={{marginTop: 10}}>
                  <Form<ReturnsSearchFormData>
                    layout="vertical"
                    form={searchForm}
                    onFinish={(values) => {
                      setSearchKey(values.key);
                      getOffices(0, values.key, limit);
                    }}
                  >
                    <Form.Item name="key">
                      <Input
                        size="large"
                        placeholder="Search ..."
                        prefix={<SearchOutlined />}
                        autoComplete="off"
                        allowClear
                      />
                    </Form.Item>
                  </Form>
                </Col>
              </>
            ) : (
              <>
                <Col span={20}>
                  <Form<ReturnsSearchFormData>
                    layout="vertical"
                    form={searchForm}
                    onFinish={(values) => {
                      getOffices(0, values.key, limit);
                    }}
                  >
                    <Form.Item name="key">
                      <Input
                        size="large"
                        placeholder="Search ..."
                        prefix={<SearchOutlined />}
                        autoComplete="off"
                        allowClear
                      />
                    </Form.Item>
                  </Form>
                </Col>
                <Col
                  span={4}
                  style={{display: "flex", justifyContent: "flex-end"}}
                >
                  <Button
                    size="large"
                    icon={<PlusCircleOutlined />}
                    onClick={showCreateOfficeModal}
                  >
                    Create Office
                  </Button>
                </Col>
              </>
            )}
          </Row>
  
          <Table
            columns={columns}
            dataSource={offices}
            pagination={{
              onChange: (page, pageSize) => {
                setLimit(pageSize);
                getOffices(page, searchKey, pageSize);
              },
              total: total,
              pageSize: limit,
              position: ["bottomCenter"],
              showQuickJumper: false,
            }}
            scroll={{x: true}}
            loading={loading}
          />
  
          <Modal
            title="Create Office/Branch"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[]}
            width={isMobile ? "100vw" : "40vw"}
          >
            <CreateOfficeFormComponent onFinish={onFinish} form={form} />
          </Modal>
  
          <Modal
            title="Edit Office"
            visible={isEditModalVisible}
            onOk={() => handleEditOk()}
            onCancel={() => handleEditCancel()}
            footer={[]}
            width={isMobile ? "100vw" : "40vw"}
          >
            <EditOfficeFormComponent
              randKey={Math.random()}
              onFinish={onEditFinish}
              form={form}
              office={office}
            />
          </Modal>
        </Context.Provider>
      </>
    );
  };
  