/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Button,
  useGetIdentity,
  Space,
  Authenticated,
  Modal,
  Form,
  message,
} from "@pankod/refine";

import { useNavigation } from "@pankod/refine";
import { gqlDataProvider } from "api";
import { CreateCompanyFormComponent } from "./components/forms/create_company_form";

const { Text, Title } = Typography;

interface CompanyForm {
  name: string;
  shortName: string;
  tin: string;
}

export const NoCompanyConnectedPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [goHome, setGoHome] = useState(false);
  const { push } = useNavigation();
  const { data: user } = useGetIdentity();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm<CompanyForm>();

  const showCreateCompanyModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const CardTitle = (
    <>
      <Title level={3} className="title">
        E-feeder
      </Title>
      <Title level={5} className="title">
        No Company/Bussiness Connected
      </Title>
    </>
  );


  const checkActivation = async (companyId: number) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "company",
        variables: {
          id: {
            value: companyId,
            type: "Int",
            required: true,
          },
        },
        fields: [
          "id",
          "name",
          "shortName",
          "tin",
          "vrn",
          "isActive",
          "created",
          "updated",
          "canEdit",
          "canDelete",
          "canManage"
        ],
      },
    });
    let userObject = JSON.parse(localStorage.getItem("user")?? "");
    userObject.companyuser[0] = data
    localStorage.setItem("user", JSON.stringify(userObject));
  };


  const onFinish = async (values: any) => {
    setLoading(true);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "createCompany",
        variables: {
          name: {
            value: values.name,
            type: "String",
            required: true,
          },
          tin: {
            value: values.tin,
            type: "String",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          {
            company: [
              "id",
              "name",
              "shortName",
              "tin",
              "vrn",
              "isActive",
              "created",
              "updated",
              "canEdit",
              "canDelete",
              "canManage",
            ],
          },
          {
            user: [
              "id",
              "firstName",
              "lastName",
              "email",
              {
                profile: [
                  "id",
                  "middleName",
                  "fullName",
                  "pic"
                ]
              }
            ]
          }
        ],
      },
    });

    setLoading(false);
    let userObject = JSON.parse(localStorage.getItem("user") ?? "");
    userObject.companyuser[0] = data.company;
    localStorage.setItem("user", JSON.stringify(userObject));
    if (data.success) {
      localStorage.setItem("companyIsActive", data.company.isActive);
      localStorage.setItem("companyid", data.company.id);
      message.success(
        "Congratulation, Your Bussiness/Company was Successfully Connected, you can Proceed"
      );
      message.success(data.message);
      setIsModalVisible(false);
      form.resetFields();
      setGoHome(true);
      checkActivation(data.company.id);
    } else {
      message.error(data.message);
    }
  };

  return (
    <Authenticated>
      <AntdLayout className="layout" style={{ background: "#f0f2f5" }}>
        <Row
          justify="center"
          align="middle"
          style={{
            height: "100vh",
          }}
        >
          <Col xs={24}>
            <div className="container">
              <div className="imageContainer">
                <img
                  src="/images/efeeder.png"
                  alt="Olbongo Logo"
                  style={{ width: "100px" }}
                />
              </div>
              <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
                <Title level={5}>
                  Welcome:{" "}
                  <span style={{ color: "orange" }}>
                    {user?.profile.fullName}
                  </span>
                </Title>
              </Card>
              <Card>
                <Text>For more clarification, please contact us</Text>
                <Space direction="vertical">
                  <Text type="danger">Phone Numbers</Text>
                  <Text type="success">0765242198</Text>
                  <Text type="success">0655520116</Text>
                  <Text type="success">0717512739</Text>

                  <Text type="danger">Email Address</Text>
                  <Text type="success">support@ericogroup.co.tz</Text>
                </Space>
              </Card>

              <Card>
                {goHome ? (
                  <Button block={true} onClick={() => push("/home")}>
                    Open to Continue
                  </Button>
                ) : (
                  <Button
                    block={true}
                    onClick={showCreateCompanyModal}
                    loading={loading}
                  >
                    Connect
                  </Button>
                )}
              </Card>

              {user?.isSuperuser || user?.isStaff ? (
                <Card>
                  <Button
                    block={true}
                    onClick={() => push("/control/analytics")}
                  >
                    Go to Control Panel
                  </Button>
                </Card>
              ) : (
                <></>
              )}
            </div>
          </Col>
        </Row>

        <Modal
          title="Create/Adding Company/Business"
          width={"40vw"}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[]}
        >
          <CreateCompanyFormComponent form={form} onFinish={onFinish} />
        </Modal>
      </AntdLayout>
    </Authenticated>
  );
};
