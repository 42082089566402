import { Button, Form, Input } from "@pankod/refine";
import { Col, DatePicker, Row } from "antd";
import { InputNumber } from 'antd';
interface createCompanyBillingProps {
  form?: any;
  onFinish?: any;
}

export const ExpireCompanyFormComponent: React.FC<createCompanyBillingProps> = (
  props: createCompanyBillingProps
) => {
  return (
    <>
      <Form
        layout="vertical"
        form={props.form}
        onFinish={(values) => {
          props.onFinish(values);
        }}
        requiredMark={false}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="date"
              label="Expire Date"
              rules={[{ required: true, type: "date", message: "Please input Expire date" }]}
            >
              <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
