/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Button,
  useGetIdentity,
  Space,
  message,
  Authenticated,
} from "@pankod/refine";

import { useNavigation } from "@pankod/refine";
import { gqlDataProvider } from "api";

const { Text, Title } = Typography;

export const ClientActivationPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [goHome, setGoHome] = useState(false);
  const { push } = useNavigation();
  const { data: user } = useGetIdentity();

  const CardTitle = (
    <>
      <Title level={3} className="title">
        E-feeder
      </Title>
      <Title level={5} className="title">
        Waiting Activation
      </Title>
    </>
  );


  const checkActivation = async () => {
    setLoading(true);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "company",
        variables: {
          id: {
            value: localStorage.getItem("companyid"),
            type: "Int",
            required: true,
          },
        },
        fields: [
          "id",
          "name",
          "shortName",
          "tin",
          "vrn",
          "isActive",
          "created",
          "updated",
          "canEdit",
          "canDelete",
          "canManage"
        ],
      },
    });
    setLoading(false);
    let userObject = JSON.parse(localStorage.getItem("user")?? "");
    userObject.companyuser[0] = data
    localStorage.setItem("user", JSON.stringify(userObject));
    if(data.isActive){
      localStorage.setItem("companyIsActive", "true");
      message.success("Congratulation, Your Account was Successfully Activated");
      setGoHome(true);
      push("/home");
    }else{
      message.info("Sorry, Your Account is still in Activation process");
    }
  };

  return (
    <Authenticated>
      <AntdLayout className="layout" style={{ background: "#f0f2f5" }}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={24}>
          <div className="container">
            <div className="imageContainer">
              <img
                src="/images/efeeder.png"
                alt="E-feeder Logo"
                style={{ width: "100px" }}
              />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Title level={5}>
                Welcome:{" "}
                <span style={{ color: "orange" }}>
                  {user?.profile.fullName}
                </span>
              </Title>
              <Title level={5}>
                Company: {localStorage.getItem("companyName")}
              </Title>
              <Title level={5}>
                TIN: {user?.companyuser[0]?.company?.tin}
              </Title>
            </Card>
            <Card>
              <Text>For more clarification, please contact us</Text>
              <Space direction="vertical">
                <Text type="danger">Phone Numbers</Text>
                <Text type="success">0765242198</Text>
                <Text type="success">0655520116</Text>
                <Text type="success">0717512739</Text>
                
                <Text type="danger">Email Address</Text>
                <Text type="success">support@ericogroup.co.tz</Text>
                
              </Space>
            </Card>

            <Card>
              {(goHome)?<Button block={true} onClick={()=>push("/home")} loading={loading}>
                    Succesfuly Activated (click to continue)
                </Button>:<Button block={true} onClick={checkActivation} loading={loading}>
                    Check Activation
                </Button>}
            </Card>

            {(user?.isSuperuser || user?.isStaff)?<Card>
                <Button block={true} onClick={()=>push("/control/analytics")}>
                    Go to Control Panel
                </Button>
            </Card>:<></>}
          </div>
        </Col>
      </Row>
    </AntdLayout>
    </Authenticated>
  );
};
