/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  SearchOutlined,
  DeleteOutlined,
  CalendarOutlined,
  MoneyCollectOutlined,
  FolderViewOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Table,
  useNavigation,
} from "@pankod/refine";
import {Col, Grid, message, Row, Tag, Tooltip} from "antd";
import {gqlDataProvider} from "api";
import {CompanyData} from "interfaces";
import {useEffect, useState} from "react";
import ReactTimeAgo from "react-time-ago";
import {CreateCompanyBillingFormComponent} from "./forms/creating_company_billing";
import {ExpireCompanyFormComponent} from "./forms/expire_company_form";
import {ExpireExtensionFormComponent} from "./forms/expire_extension_form";

interface CompaniesSearchFormData {
  key: string;
}

interface CompanyBillingForm {
  amount: number;
  years: number;
}

interface ExtensionExpireDataForm {
  days: number;
}

interface ExpireCompanyForm {
  company_id: number;
  date: Date;
}

interface CompaniesProps {
  height?: any;
  canAdd?: boolean;
}

export const CompaniesComponent: React.FC<CompaniesProps> = (
  props: CompaniesProps
) => {
  const [companies, setCompanies] = useState<CompanyData[]>([]);
  const [company, setCompany] = useState<CompanyData>();
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchForm] = Form.useForm<CompaniesSearchFormData>();
  const [form] = Form.useForm<CompanyBillingForm>();
  const [expireForm] = Form.useForm<ExpireCompanyForm>();
  const [extensionForm] = Form.useForm<ExtensionExpireDataForm>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isExpireModalVisible, setIsExpireModalVisible] = useState(false);
  const [isExtensionModalVisible, setIsExtensionModalVisible] = useState(false);
  const [totalUsersUpdate, setTotalUserUpdate] = useState(0);
  const breakpoint = Grid.useBreakpoint();
  const {push} = useNavigation();
  const isMobile = !breakpoint.lg;

  const showCreateCompanyBillingModal = (company: CompanyData) => {
    setCompany(company);
    setIsModalVisible(true);
  };

  const showExpireDateModal = (company: CompanyData) => {
    setCompany(company);
    setIsExpireModalVisible(true);
  };

  const showExtensionModel = () => {
    setIsExtensionModalVisible(true);
  };

  const handleExtensionOk = () => {
    setIsExtensionModalVisible(false);
  };

  const handleExtensionCancel = () => {
    setIsExtensionModalVisible(false);
  };

  const handleExpireOk = () => {
    setIsExpireModalVisible(false);
  };

  const handleExpireCancel = () => {
    setIsExpireModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getCompany = async (company: CompanyData) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "company",
        variables: {
          id: {
            value: company.id,
            type: "Int",
            required: true,
          },
        },
        fields: [
          "id",
          "name",
          "tin",
          "vrn",
          "shortName",
          "contact",
          "trial",
          "totalReceipts",
          "totalUsers",
          "expired",
          "expire",
          "created",
          "updated",
          "isActive",
          "canEdit",
          "canDelete",
          "canManage",
        ],
      },
    });

    var newCompanies = companies.map((company) => {
      if (company.id === data.company?.id) {
        return {...company, ...data.company};
      }
      return company;
    });
    setCompanies(newCompanies);
  };

  const onExtensionFinish = async (values: any) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "extendExpireDates",
        variables: {
          days: {
            value: values.days,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
      extensionForm.resetFields();
      setIsExtensionModalVisible(false);
      for (let index = 0; index < companies.length; index++) {
        getCompany(companies[index]);
      }
    } else {
      message.error(data.message);
    }
  };

  const onExpireFinish = async (values: any) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "extendTrial",
        variables: {
          companyId: {
            value: company?.id,
            type: "Int",
            required: true,
          },
          date: {
            value: values.date.format("YYYY-MM-DD"),
            type: "Date",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          {
            company: [
              "id",
              "name",
              "tin",
              "vrn",
              "shortName",
              "contact",
              "trial",
              "totalReceipts",
              "totalUsers",
              "expired",
              "expire",
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
            ],
          },
        ],
      },
    });

    if (data.success) {
      message.success(data.message);
      expireForm.resetFields();
      setIsExpireModalVisible(false);
      var newCompanies = companies.map((company) => {
        if (company.id === data.company?.id) {
          return {...company, ...data.company};
        }
        return company;
      });
      setCompanies(newCompanies);
      setCompany(undefined);
    } else {
      message.error(data.message);
    }
  };

  const updateMaximumUsersLimit = async (company_id: any, value: any) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "updateCompanyUserMaximumLimit",
        variables: {
          companyId: {
            value: company_id,
            type: "Int",
            required: true,
          },
          maximumLimit: {
            value: value,
            type: "Int",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          {
            company: [
              "id",
              "name",
              "tin",
              "vrn",
              "shortName",
              "contact",
              "trial",
              "totalReceipts",
              "totalUsers",
              "expired",
              "expire",
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
            ],
          },
        ],
      },
    })
      .catch((error) => {
        message.error("Error in updating Company User Maximum Limit");
        return {data: null};
      })
      .then((data) => {
        return data;
      });

    message.destroy();

    if (data) {
      if (data.success) {
        message.success(data.message);
        setCompanies(
          companies.map((company) => {
            if (company.id === data.company.id) {
              return data.company;
            }
            return company;
          })
        );
      } else {
        message.error(data.message);
      }
    }
  };

  const onFinish = async (values: any) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "createCompanyBilling",
        variables: {
          packageId: {
            value: values.packageId,
            type: "Int",
            required: true,
          },
          companyId: {
            value: company?.id,
            type: "Int",
            required: true,
          },
          amount: {
            value: values.amount,
            type: "Float",
            required: true,
          },
          duration: {
            value: values.duration,
            type: "Int",
            required: true,
          },
          durationType: {
            value: values.durationType,
            type: "String",
            required: true,
          },
          totalUsers: {
            value: values.totalUsers,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    })
      .catch((error) => {
        console.log(error.message);
        message.error(error.message);
        return {data: null};
      })
      .then((data) => {
        return data;
      });

    if (data) {
      if (data.success) {
        message.success(data.message);
        setIsModalVisible(false);
        form.resetFields();
        setCompanies(
          companies.map((company) => {
            if (company.id === data.company?.id) {
              return data.company;
            }
            return company;
          })
        );
        setCompany(undefined);
      } else {
        message.error(data.message);
      }
    }
  };

  const confirmDelete = async (id: number) => {
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "deleteCompany",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
      setCompanies(companies.filter((company) => company.id !== id));
    } else {
      message.error(data.message);
    }
  };

  const cancelDelete = () => {
    message.info("Canceled");
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "name",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <a onClick={() => message.info("coming soon")}>{text}</a>
      ),
    },
    {
      title: "TIN",
      dataIndex: "tin",
    },
    {
      title: "Contacts",
      dataIndex: "contact",
      render: (text: any, row: any, index: any) => (
        <>
          <Tag color={"gold"}>{row.contact?.email}</Tag>
          {row.contact?.phone ? (
            <Tag color={"green"}>{row.contact?.phone}</Tag>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: "Max Users",
      dataIndex: "totalUsers",
      render: (text: any, row: any, index: any) => (
        <>
          <Popconfirm
            title={
              <>
                <span>Updating Total Maximum Users for {row.name}</span>
                <div style={{height: 20}}></div>
                <h4>Maximum Total Users Limit </h4>
                <span>
                  <Form initialValues={{maximumUsersLimit: totalUsersUpdate}}>
                    <Form.Item name="maximumUsersLimit">
                      <InputNumber
                        size="large"
                        min={1}
                        style={{width: "100%"}}
                        placeholder="Maximum Users Limit"
                        onChange={(value: number) => {
                          setTotalUserUpdate(value);
                        }}
                      />
                    </Form.Item>
                  </Form>
                </span>
              </>
            }
            onConfirm={() => {
              updateMaximumUsersLimit(row.id, totalUsersUpdate);
            }}
            onCancel={() => {}}
            okText="Yes"
            okButtonProps={{disabled: totalUsersUpdate > 0 ? false : true}}
            cancelText="No"
            onVisibleChange={(visible, e) => {
              if (visible) {
                setTotalUserUpdate(row.totalUsers);
              } else {
                setTotalUserUpdate(0);
              }
            }}
          >
            <a>{row.totalUsers}</a>
          </Popconfirm>
        </>
      ),
    },
    {
      title: "Expire",
      dataIndex: "expire",
      render: (text: any, row: any, index: any) => (
        <>
          <span>
            <ReactTimeAgo date={new Date(text)} />

            {row.trial ? (
              <a onClick={() => showExpireDateModal(row)}>
                <Tag color={"green"} style={{marginLeft: 3}}>
                  Trial
                </Tag>
              </a>
            ) : (
              <></>
            )}
          </span>
        </>
      ),
    },
    {
      title: "Receipts",
      dataIndex: "totalReceipts",
    },
    {
      title: "Created",
      dataIndex: "created",
      render: (text: any, row: any, index: any) => (
        <ReactTimeAgo date={new Date(text)}></ReactTimeAgo>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (text: any, row: any, index: any) => (
        <a>
          {row?.isActive ? (
            <span style={{color: "green"}}>Active</span>
          ) : (
            <span style={{color: "red"}}>Inactive</span>
          )}
        </a>
      ),
    },
    {
      title: "Action(s)",
      align: "right" as "right",
      dataIndex: "action",
      render: (text: any, row: any, index: any) => (
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Tooltip
            title={"View " + row?.name + " VAT Returns Sessions?"}
            color={"green"}
          >
            <Button
              icon={<FolderViewOutlined />}
              disabled={!row?.canManage}
              onClick={() => push("/control/company/" + row.id + "/returns")}
            ></Button>
          </Tooltip>
          <Popconfirm
            title={"Are you sure to delete this " + row?.number + " Company?"}
            onConfirm={() => confirmDelete(row?.id)}
            onCancel={() => cancelDelete}
            okText="Yes"
            cancelText="No"
            disabled={!row?.canDelete}
          >
            <Tooltip title={"Delete " + row?.name + "?"} color={"red"}>
              <Button
                icon={<DeleteOutlined />}
                disabled={!row?.canDelete}
              ></Button>
            </Tooltip>
          </Popconfirm>
          <Tooltip title={"Money/Bills Collection"} color={"green"}>
            <Button
              icon={<MoneyCollectOutlined />}
              onClick={() => showCreateCompanyBillingModal(row)}
              style={{marginLeft: 10}}
            ></Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const getCompanies = async (
    start: number,
    key: string = "",
    limit: number = 20
  ) => {
    setLoading(true);
    const {data} = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "companies",
        variables: {
          start: {value: start, type: "Int", required: true},
          limit: {value: limit, type: "Int", required: true},
          key: {value: key, type: "String", required: false},
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: [
              "id",
              "name",
              "tin",
              "vrn",
              "shortName",
              "contact",
              "trial",
              "totalReceipts",
              "totalUsers",
              "expired",
              "expire",
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
            ],
          },
        ],
      },
    });
    setTotal(data.total);
    setCompanies([...data.results]);
    setLoading(false);
  };

  useEffect(() => {
    getCompanies(1, "", 20);
  }, []);

  return (
    <>
      <Row style={{marginTop: 10}}>
        {isMobile ? (
          <Col
            span={isMobile ? 24 : 4}
            style={{display: "flex", justifyContent: "flex-end"}}
          >
            <Button icon={<CalendarOutlined />} onClick={showExtensionModel}>
              Extend All Expire
            </Button>
          </Col>
        ) : (
          <></>
        )}
        <Col span={isMobile ? 24 : 20}>
          <Form<CompaniesSearchFormData>
            layout="vertical"
            form={searchForm}
            onFinish={(values) => {
              companies.length = 0;
              setCompanies([]);
              setSearchKey(values.key);
              getCompanies(1, values.key, limit);
            }}
          >
            <Form.Item name="key">
              <Input
                size="large"
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                autoComplete="off"
                allowClear
              />
            </Form.Item>
          </Form>
        </Col>
        {isMobile ? (
          <></>
        ) : (
          <Col
            span={isMobile ? 24 : 4}
            style={{display: "flex", justifyContent: "flex-end"}}
          >
            <Button icon={<CalendarOutlined />} onClick={showExtensionModel}>
              Extend All Expire
            </Button>
          </Col>
        )}
      </Row>

      <Table
        columns={columns}
        dataSource={companies}
        pagination={{
          onChange: (page, pageSize) => {
            setLimit(pageSize);
            getCompanies(page, searchKey, pageSize);
          },
          total: total,
          pageSize: limit,
          position: ["bottomCenter"],
          showQuickJumper: false,
        }}
        scroll={{x: true}}
        loading={loading}
      />

      <Modal
        title="Create/Adding Company/Business"
        width={isMobile ? "100vw" : "30vw"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <CreateCompanyBillingFormComponent form={form} onFinish={onFinish} />
      </Modal>

      <Modal
        title="Extending Expire Date"
        width={isMobile ? "100vw" : "25vw"}
        visible={isExpireModalVisible}
        onOk={handleExpireOk}
        onCancel={handleExpireCancel}
        footer={[]}
      >
        <ExpireCompanyFormComponent
          form={expireForm}
          onFinish={onExpireFinish}
        />
      </Modal>

      <Modal
        title="Extension of Expire Date"
        width={isMobile ? "100vw" : "25vw"}
        visible={isExtensionModalVisible}
        onOk={handleExtensionOk}
        onCancel={handleExtensionCancel}
        footer={[]}
      >
        <ExpireExtensionFormComponent
          form={extensionForm}
          onFinish={onExtensionFinish}
        />
      </Modal>
    </>
  );
};
