/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  SearchOutlined,
  DeleteOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Table,
  Tooltip,
} from "@pankod/refine";
import { Col, Grid, message, Row } from "antd";
import { gqlDataProvider } from "api";
import { PaymentData } from "interfaces";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { RecreatePaymentQuotationFormComponent } from "./forms/recreate_payment_quotation_form";

interface PaymentsSearchFormData {
  key: string;
}

interface PaymentsProps {
  height?: any;
  canAdd?: boolean;
}

export const PaymentsComponent: React.FC<PaymentsProps> = (
  props: PaymentsProps
) => {
  const [payments, setPayments] = useState<PaymentData[]>([]);
  const [payment, setPayment] = useState<PaymentData>();
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchForm] = Form.useForm<PaymentsSearchFormData>();
  const [recreate, setRecreate] = useState(false);
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;

  const showRecreateModal = (payment: PaymentData) => {
    setPayment(payment);
    setRecreate(true);
  };

  const confirmDelete = async (id: number) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "deleteCompanyBilling",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
      setPayments(payments.filter((payment) => payment.id !== id));
    } else {
      message.error(data.message);
    }
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "name",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <a onClick={() => message.info("coming soon")}>{row.company.name}</a>
      ),
    },
    {
      title: "Author",
      dataIndex: "author",
      render: (text: any, row: any, index: any) => (
        <span>{row?.author?.profile.fullName}</span>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Created",
      dataIndex: "created",
      render: (text: any, row: any, index: any) => (
        <ReactTimeAgo date={new Date(text)}></ReactTimeAgo>
      ),
    },
    {
      title: "Action(s)",
      align: "right" as "right",
      dataIndex: "action",
      render: (text: any, row: any, index: any) => (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                <Menu.Item icon={<PlusOutlined />}>
                  <a onClick={() => showRecreateModal(row)}>
                    Recreate-Quotation
                  </a>
                </Menu.Item>
                <Menu.Item icon={<DeleteOutlined />}>
                  <Tooltip title="Delete Return Session">
                    <Popconfirm
                      title={
                        "Are you sure to delete this " + row?.name + " Payment?"
                      }
                      onConfirm={() => confirmDelete(row?.id)}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                      disabled={!row?.canDelete}
                    >
                      <a
                        target="_blank"
                        href={""}
                        rel="noopener noreferrer"
                        download
                      >
                        Delete
                      </a>
                    </Popconfirm>
                  </Tooltip>
                </Menu.Item>
              </Menu>
            }
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <MoreOutlined
                style={{
                  color: "orange",
                  fontSize: 28,
                  fontWeight: "bold",
                }}
              />
            </a>
          </Dropdown>
        </div>
      ),
    },
  ];

  const getPayments = async (
    start: number,
    key: string = "",
    limit: number = 20
  ) => {
    setLoading(true);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "companiesBillings",
        variables: {
          start: { value: start, type: "Int", required: true },
          limit: { value: limit, type: "Int", required: false },
          key: { value: key, type: "String", required: false },
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: [
              "id",
              {
                company: ["id", "name"],
              },
              {
                author: [
                  "id",
                  "firstName",
                  "lastName",
                  "email",
                  {
                    profile: ["id", "middleName", "fullName", "pic"],
                  },
                ],
              },
              "amount",
              "startDate",
              "endDate",
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
            ],
          },
        ],
      },
    });
    setTotal(data.total);
    setPayments([...data.results]);
    setLoading(false);
  };

  useEffect(() => {
    getPayments(1, "", 20);
  }, []);

  return (
    <>
      <Row style={{ marginTop: 10 }}>
        <Col span={isMobile ? 24 : 20}>
          <Form<PaymentsSearchFormData>
            layout="vertical"
            form={searchForm}
            onFinish={(values) => {
              setSearchKey(values.key);
              getPayments(0, values.key, limit);
            }}
          >
            <Form.Item name="key">
              <Input
                size="large"
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                autoComplete="off"
                allowClear
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={payments}
        pagination={{
          onChange: (page, pageSize) => {
            setLimit(pageSize);
            getPayments(page, searchKey, pageSize);
          },
          total: total,
          pageSize: limit,
          position: ["bottomCenter"],
          showQuickJumper: false,
        }}
        scroll={{ x: true }}
        loading={loading}
      />

      <Modal
        title="Re-create Payment Quotation"
        width={"40vw"}
        visible={recreate}
        onOk={() => setRecreate(false)}
        onCancel={() => setRecreate(false)}
        footer={[]}
      >
        <RecreatePaymentQuotationFormComponent payment={payment} />
      </Modal>
    </>
  );
};
