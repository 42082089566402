/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  SearchOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  SyncOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Drawer,
  Form,
  Input,
  Modal,
  Popconfirm,
  Table,
} from "@pankod/refine";
import { Col, Grid, message, Row, Tag, Tooltip } from "antd";
import { gqlDataProvider } from "api";
import { ReceiptData } from "interfaces";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { AddingReceiptsFormComponent } from "./forms/adding_receipt_form";
import { ReceiptInfoComponent } from "./receipt_info_component";

interface ReceiptsSearchFormData {
  key: string;
}

interface ReceiptsProps {
  height?: any;
  canAdd?: boolean;
}

export const ReceiptsComponent: React.FC<ReceiptsProps> = (
  props: ReceiptsProps
) => {
  const [receipts, setReceipts] = useState<ReceiptData[]>([]);
  const [receipt, setReceipt] = useState<ReceiptData|any>();
  const [limit, setLimit] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [total, setTotal] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchForm] = Form.useForm<ReceiptsSearchFormData>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [receiptInfoModal, setReceiptInfoModal] = useState(false);
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;


  const showReceiptInfo = (receipt: ReceiptData) => {
    setReceipt(receipt);
    setReceiptInfoModal(true);
  };

  const showCreateVATReturnModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (receipt: any) => {
    setReceipts([receipt, ...receipts]);
  };

  const confirmDelete = async (id: number) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "deleteReceipt",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
      setReceipts(receipts.filter((receipt) => receipt.id !== id));
    } else {
      message.error(data.message);
    }
  };

  const syncTraReceipt = async (id: number) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "syncTraReceipt",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          "data",
          {
            receipt: [
              "id",
              "number",
              "description",
              "totalInclOfTax",
              "supplierName",
              "customerName",
              "tin",
              "vrn",
              "synced",
              {
                office: [
                  "id",
                  "name",
                ]
              },
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
              {
                session: ["id"],
              },
            ],
          },
        ],
      },
    });
    if (data.success) {
      message.success(data.message);
      setReceipts(
        receipts.map((receipt) => {
          if (receipt.id === id) {
            return data.receipt;
          }
          return receipt;
        })
      );
    } else {
      message.error(data.message);
    }
  };

  const cancelDelete = () => {
    message.info("Canceled");
  };

  const columns = [
    {
      title: "Receipt Number",
      dataIndex: "number",
      fixed: true,
      render: (text: any, row: any, index: any) => <a onClick={()=>showReceiptInfo(row)}>{text}</a>,
    },
    {
      title: "Supplier  Name",
      dataIndex: "supplierName",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <span>
          <Tooltip title={text}>
            {text && text.length > 75 ? text.substring(0, 75) + "..." : text}
          </Tooltip>
        </span>
      ),
    },
    {
      title: "TIN/VRN",
      dataIndex: "tin",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <span>
          {text}/{row.vrn}
        </span>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <span>
          <span style={{ display: "block" }}>
            <Tooltip title={text}>
              {text && text.length > 75 ? text.substring(0, 75) + "..." : text}
            </Tooltip>
          </span>
          <Tag color="green">Scanned By: {row.author.fullName}</Tag>
          {row?.office && <Tag color="orange">{row?.office?.name}</Tag>}
        </span>
      ),
    },

    {
      title: (
        <>
          <span style={{ float: "right" }}>Amount</span>
        </>
      ),
      dataIndex: "totalInclOfTax",
      fixed: true,
      render: (text: any, row: any, index: any) => {
        if(row.totalInclOfTax){
          return <span style={{ float: "right" }}>{row.totalInclOfTax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>;
        }
        return <span style={{float: "right"}}>0.0</span>;
      },
    },
    {
      title: "Synced",
      dataIndex: "synced",
      fixed: true,
      render: (text: any, row: any, index: any) => {
        if (row.synced) {
          return <Tag color={"green"}>Synced</Tag>;
        }
        return <Tag color={"red"}>Not Synced</Tag>;
      },
    },
    {
      title: "Scanned At",
      dataIndex: "created",
      render: (text: any, row: any, index: any) => (
        <ReactTimeAgo date={new Date(text)} />
      ),
    },
    {
      title: "",
      dataIndex: "action",
      render: (text: any, row: any, index: any) => (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tooltip title="Delete Receipt" color={"red"}>
            <Popconfirm
              title={"Are you sure to delete this " + row?.number + " Receipt?"}
              onConfirm={() => confirmDelete(row?.id)}
              onCancel={() => cancelDelete}
              okText="Yes"
              cancelText="No"
              disabled={!row?.canDelete}
            >
              <Button
                icon={<DeleteOutlined />}
                disabled={!row?.canDelete}
              ></Button>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Sync TRA Receipt Data">
            <Button
              icon={<SyncOutlined />}
              disabled={!row?.canManage}
              onClick={() => syncTraReceipt(row?.id)}
            ></Button>
          </Tooltip>
          <Tooltip title="Click to View Receipt Informations" color="green">
            <Button
              icon={<InfoCircleFilled />}
              disabled={!row?.canManage}
              onClick={() => showReceiptInfo(row)}
            ></Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const getReceipts = async (
    start: number,
    key: string = "",
    limit: number = 20
  ) => {
    setLoading(true);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "receipts",
        variables: {
          start: { value: start, type: "Int", required: true },
          limit: { value: limit, type: "Int", required: false },
          companyId: {
            value: localStorage.getItem("companyid"),
            type: "Int",
            required: true,
          },
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: [
              "id",
              "number",
              "description",
              "totalInclOfTax",
              "supplierName",
              "customerName",
              "tin",
              "vrn",
              "synced",
              {
                author: ["id", "fullName"],
              },
              {
                office: [
                  "id",
                  "name",
                ]
              },
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
              {
                session: ["id"],
              },
            ],
          },
        ],
      },
    });
    if (data.total !== total) {
      setTotal(data.total);
    }
    if (data.hasNext !== hasNext) {
      setHasNext(data.hasNext);
    }
    setReceipts([...data.results]);
    setLoading(false);
  };

  useEffect(() => {
    getReceipts(1, "", 20);
  }, []);

  return (
    <>
      <Row style={{ marginTop: 10 }}>
        <Col span={isMobile ? 24 : 20}>
          <Form<ReceiptsSearchFormData>
            layout="vertical"
            form={searchForm}
            onFinish={(values) => {
              setSearchKey(values.key);
              getReceipts(0, values.key);
            }}
          >
            <Form.Item name="key">
              <Input
                size="large"
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                autoComplete="off"
                allowClear
              />
            </Form.Item>
          </Form>
        </Col>
        {props.canAdd ? (
          <Col span={4} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={showCreateVATReturnModal}
            >
              Add VAT Return
            </Button>
          </Col>
        ) : (
          <></>
        )}
      </Row>

      <Table
        columns={columns}
        dataSource={receipts}
        pagination={{
          onChange: (page, pageSize) => {
            setLimit(pageSize);
            getReceipts(page, searchKey, pageSize);
          },
          total: total,
          pageSize: limit,
          position: ["bottomCenter"],
          showQuickJumper: false,
        }}
        scroll={{ x: true }}
        loading={loading}
      />

      <Modal
        title="Add Receipts"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        width={"30vw"}
      >
        <AddingReceiptsFormComponent onFinish={onFinish} />
      </Modal>


      <Drawer
        title={"Receipt Informations #" + receipt?.number}
        placement="right"
        width={"40vw"}
        onClose={() => {
          setReceiptInfoModal(false);
          setReceipt(null);
        }}
        visible={receiptInfoModal}
      >
        <ReceiptInfoComponent id={receipt?.id} receipt={receipt} />
      </Drawer>
    </>
  );
};
