/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  message,
  Alert,
} from "@pankod/refine";
import "./styles.css";

import { useNavigation } from "@pankod/refine";
import { gqlDataProvider } from "api";

const { Text, Title } = Typography;

export interface ForgotPasswordForm {
  email: string;
}

export const ForgotPassword: React.FC = () => {
  const [form] = Form.useForm<ForgotPasswordForm>();
  const [recovered, setRecovered] = useState(false);
  const [successMessage, setSuccessMassage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { push } = useNavigation();

  const CardTitle = (
    <>
      <Title level={3} className="title">
        E-feeder
      </Title>
      <Title level={5} className="title">
        Recover your account
      </Title>
    </>
  );

  const recover = async (values: any) => {
    setErrorMessage("");
    setSuccessMassage("");
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "requestRecoveryToken",
        variables: {
          email: { value: values.email, type: "String", required: true },
        },
        fields: ["success", "message"],
      },
    });
    if (data.success) {
      setRecovered(true);
      setSuccessMassage(data.message);
      message.success(data.message);
      form.resetFields();
    } else {
      setErrorMessage(data.message);
      message.error(data.message);
    }
  };

  return (
    <AntdLayout className="layout" style={{ background: "#f0f2f5" }}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <a onClick={() => push("/login")}>
                <img
                  src="./images/efeeder.png"
                  alt="E-feeder"
                  style={{ width: "100px" }}
                />
              </a>
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              {!recovered && errorMessage.length > 0 ? (
                <>
                  <Alert
                    message="Error"
                    description={errorMessage}
                    type="error"
                  />
                </>
              ) : (
                <></>
              )}
              {!recovered ? (
                <>
                  <Form<ForgotPasswordForm>
                    layout="vertical"
                    form={form}
                    onFinish={(values) => recover(values)}
                    requiredMark={false}
                  >
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[{ required: true, type: "email" }]}
                    >
                      <Input size="large" placeholder="Email" />
                    </Form.Item>
                    <Button type="primary" size="large" htmlType="submit" block>
                      Recover
                    </Button>
                  </Form>
                  <div style={{ marginTop: 8 }}>
                    <Text style={{ fontSize: 12 }}>
                      Have an account?{" "}
                      <a
                        onClick={() => push("/login")}
                        style={{ fontWeight: "bold" }}
                      >
                        Sign in
                      </a>
                    </Text>
                  </div>
                </>
              ) : (
                <>
                  <Alert
                    message="Info"
                    description={successMessage}
                    type="info"
                  />
                  <div style={{ marginTop: 20 }}>
                    <Text style={{ fontSize: 12 }}>
                      Have an account?{" "}
                      <a
                        onClick={() => push("/login")}
                        style={{ fontWeight: "bold" }}
                      >
                        Sign in
                      </a>
                    </Text>
                  </div>
                </>
              )}
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
