/* eslint-disable jsx-a11y/anchor-is-valid */
import { HomeOutlined } from "@ant-design/icons";
import {
  Authenticated,
  Breadcrumb,
  LayoutWrapper,
  useNavigation,
} from "@pankod/refine";
import { ControlHeader } from "components/layout/header/control_header";
import { PaymentPackagesComponent } from "control/components/payment_packages_component";

const PaymentPackages: React.FC = () => {
  const { push } = useNavigation();
  return (
    <div style={{ marginTop: 10, marginRight: 10, marginLeft: 10 }}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a onClick={() => push("/control/analytics")}>
            <HomeOutlined />
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Payment Packages</Breadcrumb.Item>
      </Breadcrumb>
      <PaymentPackagesComponent height={80} />
    </div>
  );
};

export const PaymentPackagesPage = () => {
  return (
    <Authenticated>
      <LayoutWrapper
        Sider={() => <></>}
        Header={() => (
          <>
            <ControlHeader />
          </>
        )}
      >
        <PaymentPackages />
      </LayoutWrapper>
    </Authenticated>
  );
};
