import { Button, Form, Input } from "@pankod/refine";
import { gqlDataProvider } from "api";
import { PaymentData, PaymentPackageData } from "interfaces";
import { useEffect, useState } from "react";
import { InputNumber, message, Select } from "antd";

const { Option } = Select;

interface paymentQuotationFormData {
  paymentId: number;
  packageId: number;
  totalScanner: number;
}

interface paymentQuotationProps {
  form?: any;
  onFinish?: any;
  payment?: PaymentData;
}

export const RecreatePaymentQuotationFormComponent: React.FC<
  paymentQuotationProps
> = (props: paymentQuotationProps) => {
  const [packages, setPackages] = useState<PaymentPackageData[]>([]);
  const [form] = Form.useForm<paymentQuotationFormData>();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onFinish = async (values: paymentQuotationFormData) => {
    setSubmitting(true);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "recreatePaymentQuotation",
        variables: {
          paymentId: {
            value: values.paymentId,
            type: "Int",
            required: true,
          },
          packageId: {
            value: values.packageId,
            type: "Int",
            required: true,
          },
          totalScanner: {
            value: values.totalScanner,
            type: "Int",
            required: false,
          },
        },
        fields: ["success", "message"],
      },
    });
    if(data.success){
        message.success(data.message);
        form.resetFields();
    }else{
        message.error(data.message);
    }
    setSubmitting(false);
  };

  const getPackages = async (
    start: number = 1,
    key: string = "",
    limit: number = 20
  ) => {
    setLoading(true);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "packages",
        variables: {
          start: { value: start, type: "Int", required: true },
          limit: { value: limit, type: "Int", required: false },
          key: { value: key, type: "String", required: false },
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: [
              "id",
              "name",
              "created",
              "updated",
              "isActive",
              "canEdit",
              "canDelete",
              "canManage",
            ],
          },
        ],
      },
    });
    setPackages(data.results);
    setLoading(false);
  };

  useEffect(() => {
    getPackages(1, "", 20);
  }, []);

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => {
          values.paymentId = props.payment ? props.payment?.id : 0;
          values.totalScanner = values.totalScanner ? values.totalScanner : 0;
          onFinish(values);
        }}
        requiredMark={false}
      >
        <Form.Item
          name="packageId"
          label="Payment Package"
          rules={[
            { required: true, message: "Please Select Payment Package ..." },
          ]}
        >
          <Select defaultValue="" onChange={() => {}} loading={loading}>
            <Option value="">Please Choose Package ...</Option>
            {packages.map((item) => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="totalScanner"
          label="Total Scanner"
          rules={[
            { required: false, message: "Please Input Total Scanners ..." },
          ]}
        >
          <InputNumber
            min={0}
            defaultValue={0}
            onChange={() => {}}
            style={{ width: "50%" }}
          />
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={submitting}
            disabled={submitting}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
