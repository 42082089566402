import { Button, Form, useGetIdentity, useNavigation } from "@pankod/refine";
import { InputNumber, Input, message, Checkbox, Spin } from "antd";
import { gqlDataProvider } from "api";
import { PaymentPackageData, UserData } from "interfaces";
import { useEffect, useState } from "react";

interface clientQuotationProps {
  code?: any;
  onFinish?: any;
}

interface ClientQuotationForm {
  email: string;
  phone: string;
  name: string;
  code: string;
  price: number;
  maxReceipts: number;
  maxUsers: number;
  isCustom: boolean;
}

export const ClientQuotationFormComponent: React.FC<clientQuotationProps> = (
  props: clientQuotationProps
) => {
  const { push } = useNavigation();
  const [loading, setLoading] = useState(true);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [customer, setCustomer] = useState<UserData>();
  const [pack, setPack] = useState<PaymentPackageData>();
  const [form] = Form.useForm<ClientQuotationForm>();
  const [scanner, setScanner] = useState(false);
  const [totalScanner, setTotalScanner] = useState(0);
  const [created, setCreated] = useState(false);
  const [creating, setCreating] = useState(false);

  const getUser = async () => {
    setLoadingCustomer(true);
    const { data } = await gqlDataProvider.custom!<PaymentPackageData | any>({
      url: "",
      method: "get",
      metaData: {
        operation: "viewer",
        variables: {},
        fields: [
          "id",
          "email",
          {
            profile: ["id", "phone"],
          },
        ],
      },
    });
    setCustomer(data);
    form.setFieldsValue({
      email: customer?.email,
      phone: customer?.profile.phone,
    });
    setLoadingCustomer(false);
  };

  const getPackage = async (code: any) => {
    setLoading(true);
    const { data } = await gqlDataProvider.custom!<PaymentPackageData | any>({
      url: "",
      method: "get",
      metaData: {
        operation: "packageByCode",
        variables: {
          code: {
            value: code,
            type: "String",
            required: true,
          },
        },
        fields: [
          "id",
          "name",
          "code",
          "maxUsers",
          "maxReceipts",
          "isCustom",
          "price",
          "created",
          "updated",
          "isActive",
          "canEdit",
          "canDelete",
          "canManage",
        ],
      },
    });
    if (data) {
      setLoading(false);
      setPack(data);
    }
  };

  const onFinish = async (values: any) => {
    setCreated(false);
    setCreating(true);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "clientCreateQuotation",
        variables: {
          companyId: {
            value: parseInt(localStorage.getItem("companyid") ?? "0"),
            type: "Int",
            required: true,
          },
          totalScanner: {
            value: values.totalScanner,
            type: "Int",
            required: false,
          },
          code: {
            value: props.code,
            type: "String",
            required: true,
          },
          email: {
            value: values.email,
            type: "String",
            required: true,
          },
          phone: {
            value: values.phone,
            type: "String",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          {
            quotation: ["id"],
          },
        ],
      },
    });
    setCreating(false);
    if (data.success) {
      setCreated(true);
      message.success(data.message);
      setTimeout(() => {
        push("/quotations");
      }, 5000);
    } else {
      message.error(data.message);
    }
  };

  useEffect(() => {
    getPackage(props.code);
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.code]);

  if (loading) {
    return (
      <div>
        <Spin spinning={loading}></Spin>
      </div>
    );
  }

  if (created) {
    return (
      <div>
        <p>
          Quotation Successfully Created, you will be redirected to your
          Quotations in 5 seconds
        </p>
      </div>
    );
  }

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => {
          onFinish(values);
        }}
        requiredMark={false}
      >
        <Form.Item
          name="isCustom"
          valuePropName="checked"
          wrapperCol={{ offset: 0, span: 16 }}
        >
          <Checkbox
            onChange={() => {
              setScanner(!scanner);
            }}
          >
            Need QR-Code Scanner(s)
          </Checkbox>
        </Form.Item>

        {scanner && (
          <Form.Item
            name="totalScanners"
            label="Total Scanners"
            rules={[
              {
                required: scanner,
                type: "number",
                message: "Please input Total Scanners",
              },
            ]}
          >
            <InputNumber
              min={1}
              onChange={() => {}}
              style={{ width: "100%" }}
            />
          </Form.Item>
        )}

        {pack?.isCustom && (
          <>
            <Form.Item
              name="totalUsers"
              label="Total Users"
              rules={[
                {
                  required: true,
                  type: "number",
                  message: "Please input Total Users",
                },
              ]}
            >
              <InputNumber
                min={5}
                onChange={() => {}}
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item
              name="totalReceipts"
              label="Total Receipts (Annually)"
              rules={[
                {
                  required: true,
                  type: "number",
                  message: "Please input Total Receipts",
                },
              ]}
            >
              <InputNumber
                min={600}
                onChange={() => {}}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </>
        )}

        <Form.Item
          name="email"
          label={
            <>
              <span>Email Address</span>
              <Spin spinning={loadingCustomer}></Spin>
            </>
          }
          rules={[
            { required: true, message: "Please input Email Address ..." },
          ]}
          initialValue={customer?.email}
        >
          <Input type={"email"} value={customer?.email} />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Mobile Phone Number"
          rules={[
            { required: true, message: "Please input Mobile Phone Number ..." },
          ]}
        >
          <Input type={"text"} />
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
