/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ControlOutlined,
  MenuOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Drawer, Layout, Menu } from "antd";
import {
  Space,
  Button,
  Icons,
  Dropdown,
  Avatar,
  Typography,
  useGetIdentity,
  useLogout,
  useNavigation,
  Grid,
  message,
  Card,
  Col,
  Modal,
  Row,
  Tag,
} from "@pankod/refine";
import { ServiceExpireComponent } from "pages/components/service_expire";
import { menuItemColor, menuItemDiv } from "../sider/styles";
import { useState } from "react";
import configs from "configs";

const { LogoutOutlined, UserOutlined } = Icons;
const { Text } = Typography;

export const ControlHeader: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { data: user } = useGetIdentity();
  const [visible, setVisible] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const { mutate: logout } = useLogout();
  const { push } = useNavigation();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isHowToPayModalVisible, setIsHowToPayModalVisible] = useState(false);

  const handleHowToPayOk = () => {
    setIsHowToPayModalVisible(false);
  };

  const handleHowToPayCancel = () => {
    setIsHowToPayModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const menu = (
    <Menu>
      {user?.isStaff || user?.isSuperuser ? (
        <Menu.Item
          key={"controlpanel"}
          onClick={() => push("/control/analytics")}
          icon={<ControlOutlined />}
        >
          Control Panel
        </Menu.Item>
      ) : (
        <></>
      )}
      <Menu.Item
        key={"profile"}
        onClick={() => setProfileOpen(true)}
        icon={<UserOutlined />}
      >
        Profile
      </Menu.Item>
      <Menu.Item
        key={"logout"}
        onClick={() => logout()}
        icon={<LogoutOutlined />}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  const appLogoTitle = (
    <div style={{ display: "flex", margin: "8px 4px", padding: "4px" }}>
      <Space align="center">
        <span style={{ marginRight: 10 }}>
          <a onClick={() => setVisible(!visible)} style={{ color: "gray" }}>
            <MenuOutlined style={{ fontSize: 24, marginTop: 24 }} />
          </a>
        </span>
        <a onClick={() => push("/home")}>
          <Avatar src={configs.logo} size={"large"} alt={"Olbongo Logo"} />
          {!isMobile ? (
            <Text ellipsis strong style={{ fontSize: 24, paddingLeft: 5 }}>
              {configs.system_name}
            </Text>
          ) : (
            <></>
          )}
        </a>
      </Space>
    </div>
  );

  return (
    <>
      <Layout.Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: !isMobile ? "0px 24px" : "0px 3px 0px 24px",
          height: "64px",
          backgroundColor: "#FFF",
          zIndex: 999,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
        }}
      >
        {appLogoTitle}
        {isMobile ? <></> : <ServiceExpireComponent />}
        <Space style={{ marginLeft: "8px" }}>
          {user?.profile.fullName && !isMobile && (
            <Text ellipsis strong style={{ lineHeight: 2 }}>
              <span style={{ display: "block" }}>{user.profile.fullName}</span>
              <span
                style={{
                  display: "block",
                  fontSize: "12px",
                  float: "right",
                  marginBottom: "3px",
                }}
              >
                <Tag color="green">
                  {JSON.parse(localStorage.user).companyuser[0].company.name}
                </Tag>
              </span>
            </Text>
          )}
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button type="link">
              {user?.profile.pic && (
                <Avatar src={user?.profile.pic} alt={user?.profile.fullName} />
              )}
            </Button>
          </Dropdown>
        </Space>
      </Layout.Header>
      <Drawer
        title={
          <div style={{ display: "flex", margin: "8px 4px", padding: "4px" }}>
            <Space align="center">
              <span style={{ marginRight: 10 }}>
                <a
                  onClick={() => setVisible(!visible)}
                  style={{ color: "gray" }}
                >
                  <MenuOutlined style={{ fontSize: 24, marginTop: 24 }} />
                </a>
                <Text
                  ellipsis
                  strong
                  style={{
                    fontSize: 24,
                    paddingLeft: 5,
                    marginBottom: 5,
                    marginLeft: 10,
                  }}
                >
                  {configs.system_name}
                </Text>
              </span>
            </Space>
          </div>
        }
        placement="left"
        size={"default"}
        width={300}
        closable={false}
        onClose={() => setVisible(!visible)}
        visible={visible}
      >
        <Menu selectedKeys={[]} mode="inline">
          <Menu.Item
            style={menuItemColor}
            key={"dashboard"}
            icon={<RightOutlined />}
            onClick={() => push("/control/analytics")}
          >
            <div style={menuItemDiv}>
              {"Dashboard"}
              {collapsed && <RightOutlined />}
            </div>
          </Menu.Item>

          <Menu.Item
            style={menuItemColor}
            key={"payments"}
            icon={<RightOutlined />}
            onClick={() => push("/control/payments")}
          >
            <div style={menuItemDiv}>
              {"Payments"}
              {collapsed && <RightOutlined />}
            </div>
          </Menu.Item>

          <Menu.Item
            style={menuItemColor}
            key={"contacts"}
            icon={<RightOutlined />}
            onClick={() => push("/control/contacts")}
          >
            <div style={menuItemDiv}>
              {"Contacts"}
              {collapsed && <RightOutlined />}
            </div>
          </Menu.Item>

          <Menu.Item
            style={menuItemColor}
            key={"companies"}
            icon={<RightOutlined />}
            onClick={() => push("/control/companies")}
          >
            <div style={menuItemDiv}>
              {"Companies"}
              {collapsed && <RightOutlined />}
            </div>
          </Menu.Item>

          <Menu.Item
            style={menuItemColor}
            key={"agents"}
            icon={<RightOutlined />}
            onClick={() => push("/control/agents")}
          >
            <div style={menuItemDiv}>
              {"Agents"}
              {collapsed && <RightOutlined />}
            </div>
          </Menu.Item>

          {(user?.isStaff || user?.isSuperuser) && (
            <Menu.Item
              style={menuItemColor}
              key={"agents_commissions"}
              icon={<RightOutlined />}
              onClick={() => push("/control/agents/commissions")}
            >
              <div style={menuItemDiv}>
                {"Agents Commissions"}
                {collapsed && <RightOutlined />}
              </div>
            </Menu.Item>
          )}

          <Menu.Item
            style={menuItemColor}
            key={"users-members"}
            icon={<RightOutlined />}
            onClick={() => push("/control/users")}
          >
            <div style={menuItemDiv}>
              {"Users/Members"}
              {collapsed && <RightOutlined />}
            </div>
          </Menu.Item>
        </Menu>
      </Drawer>

      <Modal
        title="Support Contacts"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Card>
          <Space direction="vertical">
            <Text type="danger">Phone Numbers</Text>
            <Text type="success">0655520115</Text>
            <Text type="success">0765242198</Text>
            <Text type="success">0717512739</Text>

            <Text type="danger">Email Address</Text>
            <Text type="success">support@ericogroup.co.tz</Text>
          </Space>
        </Card>
      </Modal>

      <Modal
        title="How to Make Payments"
        visible={isHowToPayModalVisible}
        onOk={handleHowToPayOk}
        onCancel={handleHowToPayCancel}
        footer={[]}
        width={"50%"}
      >
        <Card>
          <Space direction="vertical">
            <Row>
              <Col span={24}>
                <Space direction="vertical">
                  <p>For Payment please contact us</p>

                  <Space direction="vertical">
                    <Text type="danger">Phone Numbers</Text>
                    <Text type="success">0655520115</Text>
                    <Text type="success">0765242198</Text>

                    <Text type="danger">Email Address</Text>
                    <Text type="success">support@ericogroup.co.tz</Text>
                  </Space>
                </Space>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button onClick={() => push("/upgrading")}>Upgrade Now</Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Modal>

      <Modal
        title="Account Profile"
        visible={profileOpen}
        onOk={() => setProfileOpen(false)}
        onCancel={() => setProfileOpen(false)}
        footer={[]}
        width={"50%"}
      >
        <p>Under construction</p>
      </Modal>
    </>
  );
};
