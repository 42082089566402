/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input } from "@pankod/refine";
import { useEffect } from "react";


interface createOfficeProps {
  form?: any;
  onFinish?: any;
}

export const CreateOfficeFormComponent: React.FC<createOfficeProps> = (
  props: createOfficeProps
) => {


  useEffect(() => {
  }, []);

  
  return (
    <>
      <Form
        layout="vertical"
        form={props.form}
        onFinish={(values) => {
          props.onFinish(values);
        }}
        requiredMark={false}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, type: "string" }]}
        >
          <Input size="large" placeholder="Enter Name ..." />
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
