/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  SearchOutlined,
  DeleteOutlined,
  KeyOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Grid,
  Input,
  Modal,
  Popconfirm,
  Table,
  Tooltip,
} from "@pankod/refine";
import { Col, message, Row, Tag } from "antd";
import { gqlDataProvider } from "api";
import { UserData } from "interfaces";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { UserComposeMessageFormComponent } from "./forms/user_compose_message_form";

interface MembersSearchFormData {
  key: string;
}

interface ComposeMessageForm {
  content: string;
  phones: string;
}

interface CompaniesProps {
  height?: any;
  canAdd?: boolean;
}

export const UsersComponent: React.FC<CompaniesProps> = (
  props: CompaniesProps
) => {
  const [members, setMembers] = useState<UserData[]>([]);
  const [member, setMember] = useState<UserData>();
  const [start, setStart] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [hasNext, setHasNext] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchForm] = Form.useForm<MembersSearchFormData>();
  const [cForm] = Form.useForm<ComposeMessageForm>();
  const [composeModal, setComposeModal] = useState(false);
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;

  const showComposeModal = (member: UserData) => {
    setMember(member);
    setComposeModal(true);
  };

  const syncEfeederCode = async (id: number) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "createEfeederClientCode",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: [
          "success",
          "message",
          "efeederCode",
          {
            user: [
              "id",
              "firstName",
              "lastName",
              "email",
              "dateJoined",
              "isActive",
              "isStaff",
              "isSuperuser",
              "canEdit",
              "canDelete",
              "canManage",
              {
                profile: [
                  "id",
                  "middleName",
                  "fullName",
                  "pic",
                  "efeederCode",
                  "phone",
                ],
              },
              {
                companyuser: [
                  "id",
                  {
                    company: ["id", "name"],
                  },
                ],
              },
            ],
          },
        ],
      },
    });

    if (data.success) {
      message.success(data.message);
      setMembers(
        members.map((member) => {
          if (member.id === id) {
            console.log(data.user.profile.efeederCode);
            return data.user;
          }
          return member;
        })
      );
    } else {
      message.error(data.message);
    }
  };

  const cancelEfeederCodeSync = () => {
    message.info("Canceled");
  };

  const userComposeMessage = async (values: ComposeMessageForm) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "composeMessageContacts",
        variables: {
          content: {
            value: values.content,
            type: "String",
            required: true,
          },
          phones: {
            value: values.phones,
            type: "String",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
      cForm.resetFields();
      setComposeModal(false);
    } else {
      message.error(data.message);
    }
  };

  const resetCredentials = async (id: number) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "recreateUserCredentials",
        variables: {
          userId: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
    } else {
      message.error(data.message);
    }
  };

  const confirmDelete = async (id: number) => {
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "post",
      metaData: {
        operation: "deleteCompany",
        variables: {
          id: {
            value: id,
            type: "Int",
            required: true,
          },
        },
        fields: ["success", "message"],
      },
    });

    if (data.success) {
      message.success(data.message);
      setMembers(members.filter((member) => member.id !== id));
    } else {
      message.error(data.message);
    }
  };

  const cancelDelete = () => {
    message.info("Canceled");
  };

  const columns = [
    {
      title: "Full Name",
      dataIndex: "full_name",
      fixed: true,
      render: (text: any, row: any, index: any) => (
        <span>
          <a>{row.profile.fullName}</a>
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      render: (text: any, row: any, index: any) => {
        return <span>{row.profile.phone}</span>;
      },
    },
    {
      title: "Efeeder Code",
      dataIndex: "efeederCode",
      render: (text: any, row: any, index: any) => {
        let code = row.profile.efeederCode ?? "";
        return (
          <Tag color={"green"}>
            {code.length === 0 || code === undefined ? (
              <Popconfirm
                title={"Re-Creating Efeeder Client Code?"}
                onConfirm={() => syncEfeederCode(row?.id)}
                onCancel={() => cancelEfeederCodeSync}
                okText="Yes"
                cancelText="No"
                disabled={!row?.canManage}
              >
                <Button size="small">Sync</Button>
              </Popconfirm>
            ) : (
              row.profile.efeederCode
            )}
          </Tag>
        );
      },
    },
    {
      title: "Company/Bussiness",
      dataIndex: "company",
      render: (text: any, row: any, index: any) => (
        <span>{row?.companyuser[0]?.company.name}</span>
      ),
    },
    {
      title: "Date Joined",
      dataIndex: "dateJoined",
      render: (text: any, row: any, index: any) => (
        <>
          <span>
            <ReactTimeAgo date={new Date(row.dateJoined)} />
          </span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (text: any, row: any, index: any) => (
        <a>
          {row?.isActive ? (
            <span style={{ color: "green" }}>Active</span>
          ) : (
            <span style={{ color: "red" }}>Inactive</span>
          )}
        </a>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      render: (text: any, row: any, index: any) => (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Popconfirm
            title={"Ressetting this member Credentials?"}
            onConfirm={() => resetCredentials(row?.id)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
            disabled={!row?.canManage}
          >
            <Button icon={<KeyOutlined />} disabled={!row?.canDelete}></Button>
          </Popconfirm>
          <Popconfirm
            title={"Are you sure to delete this member?"}
            onConfirm={() => confirmDelete(row?.id)}
            onCancel={() => cancelDelete}
            okText="Yes"
            cancelText="No"
            disabled={!row?.canDelete}
          >
            <Button
              icon={<DeleteOutlined />}
              disabled={!row?.canDelete}
            ></Button>
          </Popconfirm>

          <Tooltip title="Compose a Message & Dispatch">
            <Button
              icon={<MessageOutlined />}
              disabled={!row?.canManage}
              onClick={() => showComposeModal(row)}
            ></Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const getMembers = async (
    start: number,
    key: string = "",
    limit: number = 20
  ) => {
    setLoading(true);
    const { data } = await gqlDataProvider.custom!({
      url: "",
      method: "get",
      metaData: {
        operation: "users",
        variables: {
          start: { value: start, type: "Int", required: true },
          limit: { value: limit, type: "Int", required: false },
          key: { value: key, type: "String", required: false },
        },
        fields: [
          "total",
          "page",
          "pages",
          "hasNext",
          "hasPrev",
          {
            results: [
              "id",
              "firstName",
              "lastName",
              "email",
              "dateJoined",
              "isActive",
              "isStaff",
              "isSuperuser",
              "canEdit",
              "canDelete",
              "canManage",
              {
                profile: [
                  "id",
                  "middleName",
                  "fullName",
                  "pic",
                  "efeederCode",
                  "phone",
                ],
              },
              {
                companyuser: [
                  "id",
                  {
                    company: ["id", "name"],
                  },
                ],
              },
            ],
          },
        ],
      },
    });
    setStart(data.page + 1);
    setTotal(data.total);
    setHasNext(data.hasNext);
    setMembers([...data.results]);
    setLoading(false);
  };

  useEffect(() => {
    getMembers(1, "", 20);
  }, []);

  return (
    <>
      <Row style={{ marginTop: 10 }}>
        <Col span={isMobile ? 24 : 20}>
          <Form<MembersSearchFormData>
            layout="vertical"
            form={searchForm}
            onFinish={(values) => {
              setStart(1);
              members.length = 0;
              getMembers(1, values.key, limit);
            }}
          >
            <Form.Item name="key">
              <Input
                size="large"
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                autoComplete="off"
                allowClear
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={members}
        loading={loading}
        pagination={{
          onChange: (page, pageSize) => {
            setStart(page);
            setLimit(pageSize);
            getMembers(page, searchKey, pageSize);
          },
          total: total,
          pageSize: limit,
          position: ["bottomCenter", "topCenter"],
          showQuickJumper: false,
        }}
        scroll={{ x: true }}
      />

      <Modal
        title="Message Compose"
        visible={composeModal}
        onOk={() => setComposeModal(false)}
        onCancel={() => setComposeModal(false)}
        footer={[]}
      >
        <UserComposeMessageFormComponent
          user={member}
          form={cForm}
          onFinish={userComposeMessage}
        />
      </Modal>
    </>
  );
};
